<template>
  <div class="indicator semi-bold" :class="classColor">{{ text }}</div>
</template>

<script>
import { INDICATORS } from '../../model/const/indicators'

export default {
  props: {
    indicatorId: {
      type: Number,
      required: true
    }
  },
  computed: {
    text () {
      return this.currentIndicator.name
    },

    classColor () {
      return `color--${this.currentIndicator.color}`
    },

    currentIndicator () {
      return INDICATORS[this.indicatorId - 1]
    }
  }
}
</script>

<style lang="scss">
.indicator {
  display: flex;
  align-items: center;
  gap: var(--xxs);

  &:before {
    content: '';
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background-color: currentColor;
  }
}
</style>
