export function getPageCount (response) {
  return +response.headers['x-pagination-page-count'] || 0
}

export function createPageList (pageCount) {
  return [
    ...Array(Number(pageCount))
      .keys()
  ].map(i => ++i)
}
