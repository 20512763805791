// import { REPORTS_TYPE_DATA } from '@/const/analytics'
import { formatNum } from '@/utils/format/number'
import { getResolutionNameBy } from '../utils'
import { createColReclamationLinks } from '@/const/table/columns'

const thCountReclamation = {
  title: 'Кол-во внешних рекламаций',
  name: 'count'
}

const chartDatasetCountReclamation = {
  label: thCountReclamation.title,
  byKey: thCountReclamation.name
}

const thDecision = {
  title: 'Решение по рекламации',
  name: 'decision'
}

const thReclamationReason = {
  title: 'Первопричина рекламации',
  name: 'reason_reclamation_outer'
}

const thReclamationLink = {
  title: 'Рекламация',
  name: 'reclamation_id'
}

export const REPORTS = [
  {
    id: 1,
    name: 'По первопричине',
    value: 'reason',
    table: {
      columns: [
        thReclamationReason,
        thCountReclamation,
        thDecision,
        thReclamationLink
      ]
    },
    chart: {
      labels: 'reason_reclamation_outer',
      datasets: [chartDatasetCountReclamation]
    },
    excel: {
      getMapped
    }
  },
  {
    id: 2,
    name: 'По ответственным за несоответствие',
    value: 'responsible',
    table: {
      columns: [
        {
          title: 'Ответственный за несоответствие',
          name: 'responsible'
        },
        thCountReclamation,
        thReclamationReason,
        thReclamationLink
      ]
    },
    chart: {
      labels: 'responsible',
      datasets: [chartDatasetCountReclamation]
    },
    excel: {
      getMapped
    }
  },
  {
    id: 3,
    name: 'По решению',
    value: 'decision',
    table: {
      columns: [
        thDecision,
        thCountReclamation,
        thReclamationLink
      ]
    },
    chart: {
      labels: 'decision',
      datasets: [chartDatasetCountReclamation]
    },
    excel: {
      getMapped
    }
  },
  {
    id: 4,
    name: 'По потери металла',
    value: 'metal-losses',
    table: {
      columns: [
        {
          title: 'Марка стали',
          name: 'parameter',
          ...createColReclamationLinks()
        },
        {
          title: 'Вес, кг',
          name: 'weight',
          handlerFormat: formatNum
        }
      ],
      isTreeTable: true,
      treeKeys: {
        category: 'd_wires',
        categoryItem: 'outer_reclamations'
      }
    },
    excel: {
      getMapped
    }
  },
  {
    id: 5,
    name: 'Статистика рекламаций',
    value: 'general',
    table: {
      columns: [
        {
          title: 'Год',
          name: 'year'
        },
        {
          title: 'Период',
          name: 'period'
        },
        {
          title: 'Поступило рекламаций',
          name: 'reclamation_new',
          handlerFormat: formatNum
        },
        {
          title: 'Закрыто рекламаций',
          name: 'reclamation_finish',
          handlerFormat: formatNum
        }
      ]
    },
    chart: {
      labels: 'period',
      datasets: [
        {
          label: 'Поступило рекламаций',
          byKey: 'reclamation_new'
        },
        {
          label: 'Закрыто рекламаций',
          byKey: 'reclamation_finish'
        }
      ]
    },
    excel: {
      getMapped
    }
  }
]

function getMapped (data) {
  let count = 0
  let rows = null
  const mappedRows = []

  for (let i = 0; i < data.length; i++) {
    count = data[i]?.rows?.length
    rows = [{}]

    for (const key in data[i]) {
      if (key === 'rows') continue

      if (key === thDecision.name) {
        rows[0][key] = getResolutionNameBy(data[i][key])
      } else {
        rows[0][key] = data[i][key]
      }
    }

    if (!count) continue

    for (let k = 0; k < count; k++) {
      if (!rows[k]) {
        rows.push({})
      }

      for (const key in data[i].rows[k]) {
        if (key === thDecision.name) {
          rows[k][key] = getResolutionNameBy(data[i].rows[k][key])
        } else {
          rows[k][key] = data[i].rows[k][key]
        }
      }
    }

    mappedRows.push(...rows)
  }

  return mappedRows
}
