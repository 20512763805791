/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/profile': {
    width: 16,
    height: 18,
    viewBox: '0 0 16 18',
    data: '<path pid="0" d="M8 10c2.484 0 4.5-2.019 4.5-5S10.484 0 8 0 3.5 2.019 3.5 5s2.016 5 4.5 5zm4 .8h-1.722c-.694.382-1.466.6-2.278.6a4.707 4.707 0 01-2.278-.6H4c-2.21 0-4 2.149-4 4.8v.6c0 .994.672 1.8 1.5 1.8h13c.828 0 1.5-.806 1.5-1.8v-.6c0-2.651-1.79-4.8-4-4.8z" _fill="currentColor"/>'
  }
})
