<template>
  <div class="d-grid d-grid--columns-3 gap-s">
    <AppInfoTextBlock
      :label-text="ELabels.sender"
      :text="waybill.sender"
    />

    <InfoText
      :label="ELabels.delivery"
      :text="waybill.delivery"
    />

    <WaybillRelationOrders :waybill="waybill" />

    <AppInfoTextBlock
      :label-text="ELabels.recipient"
      :text="waybill.recipient"
    />

    <InfoText
      :label="ELabels.before"
      :text="waybill.before"
    />

    <InfoText
      :label="ELabels.comment"
      :text="waybill.comment"
    />

    <InfoText
      :label="ELabels.TIN"
      :text="waybill.TIN"
    />

    <AppInfoTextBlock
      :label-text="ELabels.address"
      :text="waybill.address"
    />

    <InfoText
      :label="ELabels.payment_method"
      :text="waybill.payment_method"
    />

    <AppInfoTextBlock
      :label-text="ELabels.payer"
      :text="waybill.payer"
    />

    <InfoText
      :label="ELabels.phone"
      :text="waybill.phone"
    />

    <AppInfoTextBlock
      :label-text="ELabels.account_double"
      :text="waybill.account_double"
    />
  </div>
</template>

<script>
import AppInfoTextBlock from '@/components/ui/AppInfoTextBlock.vue'
import InfoText from '@/components/simple/text/InfoText.vue'
import { ELabels } from '../../model/types'
import { Waybill } from '../../model/classes/Waybill'
import WaybillRelationOrders from '@/components/production/waybill/WaybillRelationOrders.vue'

export default {
  components: {
    InfoText,
    AppInfoTextBlock,
    WaybillRelationOrders
  },
  props: {
    waybill: {
      type: [Waybill, String],
      required: true
    }
  },
  computed: {
    ELabels: () => ELabels
  }
}
</script>
