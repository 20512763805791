export default [
  {
    path: '/media-files',
    name: 'MediaFiles',
    meta: {
      layout: 'main',
      availableName: 'isMediaFiles',
      auth: true
    },
    component: () => import('@/views/MediaFiles.vue')
  }
]
