<template>
  <div>
    <VButton
      v-if="!reclamation.hasReclamationOrder()"
      text="Запуск заказа"
      size="sm"
      color="primary-sm"
      icon="create"
      @click.native="OPEN_MODAL('form')"
    />

    <ModalForm
      v-if="isModal === 'form'"
      :reclamation="reclamation"
      @create-success="OPEN_MODAL('go-to-order')"
      @reject="CLOSE_MODAL"
    />

    <SuccessModal
      v-if="isModal === 'go-to-order'"
      :reclamation="reclamation"
      @close="CLOSE_MODAL"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton.vue'
import ModalForm from './ModalForm.vue'
import SuccessModal from './SuccessModal.vue'

import { reclamationExternalModel } from '@/entities/reclamation-external'
import { mapMutations, mapState } from 'vuex'

export default {
  components: {
    SuccessModal,
    ModalForm,
    VButton
  },

  props: {
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal,
      required: true
    }
  },

  computed: {
    ...mapState('reclamationExternal/launchIntoProduction', {
      isModal: 'isModal'
    })
  },

  methods: {
    ...mapMutations('reclamationExternal/launchIntoProduction', {
      OPEN_MODAL: 'OPEN_MODAL',
      CLOSE_MODAL: 'CLOSE_MODAL'
    })
  }
}
</script>
