import getValuesArray from '@/utils/array/getValuesArray'

export function prepareFormDataIdArray (data, keyStringArray, { suffix = '_ids', sliceEnd = 0 } = {}) {
  const prepareFormData = {
    ...data
  }

  for (const key of keyStringArray) {
    if (key in prepareFormData) {
      delete prepareFormData[key]

      const slicedKey = sliceEnd ? key.slice(0, sliceEnd) : key
      const newKey = slicedKey + suffix
      prepareFormData[newKey] = getValuesArray(data[key])
    }
  }

  return prepareFormData
}
