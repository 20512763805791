/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/admin-chain': {
    width: 22,
    height: 22,
    viewBox: '0 0 22 22',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.745 20.245a9.5 9.5 0 100-19 9.5 9.5 0 000 19zm0 1c5.8 0 10.5-4.7 10.5-10.5 0-5.799-4.7-10.5-10.5-10.5-5.799 0-10.5 4.701-10.5 10.5 0 5.8 4.701 10.5 10.5 10.5z" _fill="currentColor"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M7.016 6.28a.5.5 0 00-.707.708L8.87 9.55a.5.5 0 10.707-.707L7.016 6.28z" _fill="currentColor"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M8.308 10.365l2.787 2.791 2.03-2.032-2.787-2.79-2.03 2.031zm-.947-.467a.661.661 0 000 .935l3.268 3.271a.66.66 0 00.933 0l2.51-2.512a.661.661 0 000-.935l-3.268-3.271a.66.66 0 00-.933 0L7.36 9.898zM13.316 15.86a.16.16 0 000 .227l1.4 1.402-.707.708-1.4-1.402a1.162 1.162 0 010-1.643l2.51-2.512a1.159 1.159 0 011.64 0l1.4 1.402-.707.708-1.4-1.402a.16.16 0 00-.227 0l-2.51 2.512zM5.608 8.143a.16.16 0 01-.227 0L3.98 6.74l-.707.707 1.4 1.402a1.159 1.159 0 001.64 0l2.51-2.512a1.162 1.162 0 000-1.642l-1.4-1.402L6.716 4l1.4 1.402a.16.16 0 010 .227l-2.51 2.513z" _fill="currentColor"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M12.621 11.885a.5.5 0 10-.707.707l2.562 2.562a.5.5 0 00.707-.707l-2.562-2.562z" _fill="currentColor"/>'
  }
})
