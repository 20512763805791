import { refreshArr } from '@/utils/array/reactive'
import ProductionCalendarApi from '@/api/calendar/ProductionCalendarApi'
import { setAlertSuccess } from '@/utils/store/alert'

const FIRST_YEAR = 2023
const yearList = Array.from({ length: 50 }, (_, i) => ({
  id: i + 1,
  value: FIRST_YEAR + i
}))

const state = () => ({
  currentDate: new Date(),
  initModel: [],
  weekends: [],
  yearList
})

const getters = {
  currentYear: state => state.currentDate.getFullYear(),
  weekendsCount: state => state.weekends.length
}

const mutations = {
  SET_INIT_MODEL (state, weekends) {
    refreshArr(state.initModel, weekends)
  },
  SYNC (state) {
    refreshArr(state.weekends, state.initModel)
  },
  ADD_WEEKEND (state, date) {
    state.weekends.push(date)
  },
  REMOVE_WEEKEND (state, date) {
    const idx = state.weekends.findIndex(item => item === date)
    if (idx === -1) return
    state.weekends.splice(idx, 1)
  }
}

const actions = {
  async loadCalendarBy ({ dispatch }, year) {
    const { data } = await ProductionCalendarApi.get(year)
    dispatch('setAndSyncModel', data)
  },

  async updateCalendar ({ state, dispatch }) {
    const { data } = await ProductionCalendarApi.updateWeekends(state.weekends)
    dispatch('setAndSyncModel', data)
    await setAlertSuccess('Производственный календарь обновлен')
  },

  async toDefaultCalendar ({ state, dispatch }, year) {
    const { data } = await ProductionCalendarApi.toDefault(year)
    dispatch('setAndSyncModel', data)
    await setAlertSuccess(`Производственный календарь за ${year} год установлен по умолчанию`)
  },

  setAndSyncModel ({ commit }, weekends) {
    commit('SET_INIT_MODEL', weekends)
    commit('SYNC')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
