<script>
import Vue from 'vue'
import Validatable from './Validatable.vue'
import Maskable from './Maskable.vue'
import InputProps from './InputProps.vue'
import { mixinDefineProp } from '@/mixins/form/mixinDefineProp'

export default Vue.extend({
  mixins: [InputProps, Validatable, Maskable, mixinDefineProp]
})
</script>
