import { vuexHandbook } from '@/store/composition/handbook'
import { MATERIAL_URL, MaterialApi } from '@/api/handbook/warehouse/Material/MaterialApi'

const state = () => ({
  ...vuexHandbook.createState(),
  api: MaterialApi,
  url: MATERIAL_URL
})

const getters = {
  ...vuexHandbook.createGetters(),

  getList: state => {
    return state.list.map(item => ({
      ...item,
      data: [{ name: 'Артикул', value: item.vendor_code || 'отсутствует' }]
    }))
  }
}

const mutations = {
  ...vuexHandbook.createMutations()
}

const actions = {
  ...vuexHandbook.createActions()
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
