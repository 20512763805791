import { spliceBy } from '@/utils/array/reactive'
import router from '@/router'

const state = () => ({
  counter: 1,
  alertList: []
})

const mutations = {
  ADD_ALERT: (state, { id, type, message }) => {
    state.alertList.push({
      id,
      type,
      message
    })
  },
  DELETE_ALERT: (state, id) => spliceBy(id, state.alertList),
  INCREMENT_COUNTER: state => {
    state.counter++
  }
}

const actions = {
  setAlertWarning ({ dispatch }, { message, timeout }) {
    return dispatch('setAlert', { type: 'warning', message, timeout })
  },
  async setAlertError ({ dispatch }, message) {
    if (String(message) === '401') {
        return router.push({ name: 'Auth' })
    } else return dispatch('setAlert', { type: 'error', message })
  },
  setAlertSuccess ({ dispatch }, message) {
    return dispatch('setAlert', { type: 'success', message })
  },

  setAlert ({ state, commit }, { type = 'info', message, timeout = 3000 }) {
    const id = state.counter
    commit('INCREMENT_COUNTER')

    commit('ADD_ALERT', { id, type, message })

    setTimeout(() => {
      commit('DELETE_ALERT', id)
    }, timeout)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
