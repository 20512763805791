import { setAlertError } from '@/utils/store/alert'

export function createBaseController (api, errors) {
  return {
    getAll: async (params) => {
      try {
        return await api.getAll(params)
      } catch (e) {
        await setAlertError(e, errors.getAll)
      }
    },
    getById: async (id, params) => {
      try {
        return await api.getById(id, params)
      } catch (e) {
        await setAlertError(e, errors.getById)
      }
    },
    create: async (data, params) => {
      try {
        return await api.create(data, params)
      } catch (e) {
        await setAlertError(e, errors.create)
      }
    },
    update: async (id, data, params) => {
      try {
        return await api.update(id, data, params)
      } catch (e) {
        await setAlertError(e, errors.update)
      }
    },
    destroy: async (id) => {
      try {
        return await api.destroy(id)
      } catch (e) {
        await setAlertError(e, errors.destroy)
      }
    }
  }
}
