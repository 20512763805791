import { pages } from './pages'
import {
  reclamationExternalModel
} from '@/entities/reclamation-external'

export const sidebar = [
  {
    label: 'Рекламации',
    routeName: pages.list,
    routeParams: {
      status: reclamationExternalModel.STATUSES[0].value
    },
    iconFullPath: 'nav/header/reclamation'
  },
  {
    label: 'Журнал изменений',
    routeName: pages.changelog,
    icon: 'changelog'
  },
  {
    label: 'Отчеты',
    routeName: pages.reports,
    icon: 'analytics'
  }
]
