/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/settings': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M13.333 10A3.34 3.34 0 0110 13.333 3.34 3.34 0 016.667 10 3.34 3.34 0 0110 6.667 3.34 3.34 0 0113.333 10zM20 8.58a.475.475 0 00-.378-.468l-2.382-.365a7.17 7.17 0 00-.534-1.276c.442-.612.924-1.185 1.367-1.784a.492.492 0 00.013-.585c-.547-.769-1.458-1.576-2.148-2.214a.514.514 0 00-.326-.13.443.443 0 00-.313.117l-1.848 1.393a7.644 7.644 0 00-1.172-.482L11.914.391C11.888.169 11.68 0 11.445 0h-2.89a.477.477 0 00-.47.365c-.207.78-.286 1.627-.377 2.421-.403.13-.807.3-1.185.495L4.727 1.888a.58.58 0 00-.339-.13c-.443 0-2.2 1.9-2.513 2.33a.505.505 0 00-.117.3.48.48 0 00.13.313c.482.585.95 1.171 1.393 1.796-.208.391-.377.782-.508 1.198L.352 8.06C.156 8.099 0 8.333 0 8.529v2.89c0 .222.156.43.378.469l2.382.352c.13.442.313.872.534 1.289-.442.612-.924 1.184-1.367 1.784a.507.507 0 00-.013.599c.547.755 1.458 1.562 2.149 2.187a.466.466 0 00.325.143.5.5 0 00.326-.117l1.835-1.393c.378.195.769.351 1.172.482l.365 2.395c.026.222.234.391.469.391h2.89a.477.477 0 00.469-.365c.208-.78.287-1.627.378-2.422.403-.13.807-.299 1.185-.494l1.796 1.406a.663.663 0 00.339.117c.443 0 2.2-1.914 2.513-2.33a.435.435 0 00.117-.3.514.514 0 00-.13-.325c-.482-.587-.95-1.16-1.393-1.797.208-.378.364-.769.508-1.185l2.408-.365c.209-.039.365-.273.365-.469v-2.89z" _fill="currentColor"/>'
  }
})
