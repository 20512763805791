/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'input-copy': {
    width: 23,
    height: 20,
    viewBox: '0 0 23 20',
    data: '<rect pid="0" x="9.775" y=".5" width="12.103" height="16.241" rx=".5" _stroke="currentColor"/><rect pid="1" x="7.018" y="3.259" width="12.103" height="16.241" rx=".5" _stroke="currentColor"/><path pid="2" d="M13.414 11.035H1m0 0l3.793-4.138M1 11.035l3.793 4.137" _stroke="currentColor" stroke-linecap="round"/>'
  }
})
