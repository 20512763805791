export default [
  {
    path: '/analytics',
    name: 'Analytics',
    meta: {
      layout: 'main',
      availableName: 'isAnalytics',
      auth: true
    },
    component: () => import('@/views/Analytics/AnalyticsView.vue')
  }
]
