import CategoriesApi from '@/api/catalog/CategoriesApi'
import { showAlertError } from '@/utils/store/alert'
import CatalogSidebarNavItem from '@/classes/nav/CatalogSidebarNavItem'
import { refreshArr } from '@/utils/array/reactive'
// import BaseModel from '@/classes/BaseModel'
import { findById } from '@/utils/array/find'

const state = () => ({
  categoriesTree: {
    children: [],
    description: '',
    filter_settings: [],
    id: 1,
    name: 'Каталог',
    parent_id: null,
    products_count: 0,
    products_count_category: 0
  },
  currentCategory: null,
  root: null,
  categoriesHandbook: []
})

const getters = {
  categoriesAll: state => state.categoriesTree.children
}

const mutations = {
  setCategoriesTree: (state, data) => {
    state.categoriesTree = data
  },
  createRoot: state => {
    state.root = new CatalogSidebarNavItem({
      label: state.categoriesTree.name,
      count: state.categoriesTree.products_count
    })
  },
  updateRootCount: state => {
    state.root.count = state.categoriesTree.products_count
  },

  setCategoriesHandbook: state => {
    const list = []

    state.categoriesTree.children.forEach(push2handbook)

    refreshArr(state.categoriesHandbook, list)

    function push2handbook (category) {
      list.push(category)

      category.children.forEach(push2handbook)
    }
  },
  updateFilterSettings: (state, { id, data }) => {
    if (id === 1) {
      return refreshArr(state.categoriesTree.filter_settings, data)
    }

    const category = findById(state.categoriesTree.children, id)
    refreshArr(category.filter_settings, data)
  }
}

const actions = {
  async getAllCategories ({ dispatch, commit }) {
    try {
      commit('catalog/SET_IS_LOADING', true, { root: true })
      const response = await CategoriesApi.getAll()
      dispatch('refreshTreeAndHandbook', response.data)
    } catch (e) {
      showAlertError(e)
    } finally {
      commit('catalog/SET_IS_LOADING', true, { root: true })
    }
  },

  async updateAndSetMassCategory ({ state, dispatch }, categories) {
    const main = state.categoriesTree

    const data = {
      id: main.id,
      name: main.name,
      sort: main.sort,
      description: main.description,
      children_categories: categories
    }

    const response = await CategoriesApi.updateMass(data)
    dispatch('refreshTreeAndHandbook', response.data)
  },

  async destroyCategory (_, id) {
    await CategoriesApi.destroy(id)
  },

  refreshTreeAndHandbook ({ commit }, data) {
    commit('setCategoriesTree', data)
    commit('setCategoriesHandbook')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
