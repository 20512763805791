/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/restore': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.2 2.223l3.638 3.083.816-.963a.717.717 0 011.093.927l-.816.963 3.638 3.083a.717.717 0 01-.927 1.093l-8.369-7.093a.716.716 0 11.927-1.093zm6.31 3.693l-.17.2 2.875 2.437a1.717 1.717 0 11-2.22 2.619L9.627 4.079a1.716 1.716 0 01-.588-1.557c.194-1.334 1.78-1.934 2.808-1.062l2.876 2.437.17-.2v-.001a1.716 1.716 0 012.618 2.22zm-4.195 5.422a3.5 3.5 0 00-3.5-3.5H3.522l1.646-1.646-.707-.707-2.853 2.853 2.853 2.854.707-.707-1.646-1.647h6.293a2.5 2.5 0 012.5 2.5h1zm-4.65 1.853h8.123a1 1 0 01.991 1.135l-1.043 7.647a1 1 0 01-.99.865H9.708a1 1 0 01-.991-.865l-1.043-7.647a1 1 0 01.991-1.135zm-1.982 1.27a2 2 0 011.982-2.27h8.123a2 2 0 011.982 2.27l-1.043 7.647a2 2 0 01-1.981 1.73H9.708a2 2 0 01-1.982-1.73l-1.043-7.647zm6.044.407a.5.5 0 01.5.5v5.294a.5.5 0 01-1 0v-5.294a.5.5 0 01.5-.5zm-2.15.45a.5.5 0 10-.995.1l.53 5.294a.5.5 0 00.995-.1l-.53-5.294zm4.847-.448a.5.5 0 00-.547.448l-.53 5.294a.5.5 0 00.995.1l.53-5.294a.5.5 0 00-.448-.548z" _fill="#BCC5D8"/>'
  }
})
