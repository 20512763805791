<template>
  <div class="empty-layout">
    <AlertList />
    <router-view/>
  </div>
</template>

<script>
import AlertList from '@/components/Alert/AlertList'
export default {
  name: 'EmptyLayout',
  components: { AlertList }
}
</script>
