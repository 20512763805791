import { instance } from '@/api'
import { throwResponseError } from '@/utils/error/throwResponseError'

export const CATALOG_URL = 'catalog'

export const CatalogApi = {
  getAllArticles: async () => {
    try {
      return await instance.get(`${CATALOG_URL}/articles`)
    } catch (e) {
      throwResponseError(e, 'Не удалось загрузить список всех артикулов')
    }
  }
}
