<template>
  <TheAppLoader v-if="appLoading"/>

  <div v-else class="wrap">
    <TheHeader />

    <TheSidebar :class="isLargeSidebar && 'sidebar--lg'" />

    <main
      class="page"
      :class="mainClasses"
      translate="no"
      @scroll="handleScroll"
    >
      <router-view/>
    </main>

    <!-- Button Scroll -->
    <VButton
      v-if="visibleButtonScroll"
      block="scroll-up"
      icon="scroll-up"
      @click.native="scrollUp"
    />

    <AlertList />
  </div>
</template>

<script>
import AlertList from '@/components/Alert/AlertList'
import TheAppLoader from '@/components/TheAppLoader'
import TheHeader from '@/components/TheHeader'
import TheSidebar from '@/components/TheSidebar'
import VButton from '@/components/simple/button/VButton'

import gsap from 'gsap'
import 'swiper/swiper-bundle.min.css'
import { mapActions } from 'vuex'
import { scrollUp } from '@/utils/dom'

export default {
  name: 'MainLayout',
  components: {
    AlertList,
    TheAppLoader,
    TheHeader,
    TheSidebar,
    VButton
  },
  data () {
    return {
      isMenuMounted: false,
      appMenuAnimation: null,
      appLoading: true,
      visibleButtonScroll: false
    }
  },

  computed: {
    mainClasses () {
      return {
        'page--bg-lavander-dark': this.$route.name === 'Catalog',
        'catalog-page': this.isCatalogPages,
        'main--pl-lg': this.isLargeSidebar
      }
    },
    isLargeSidebar () {
      return this.isLoadCalendarPages
    },
    isLoadCalendarPages () {
      return this.$route.meta.availableName === 'isLoadCalendar'
    },
    isCatalogPages () {
      return this.$route.meta.availableName === 'isCatalog'
    }
  },

  watch: {
    async '$route.meta.availableName' () {
      await this.setSidebarMenu()
    },

    appMenuActive (newValue) {
      if (newValue) {
        this.appMenuAnimation.play()
        this.appPageAnimation.play()
      } else {
        this.appMenuAnimation.reverse()
        gsap.delayedCall(0.5, () => {
          this.appPageAnimation.reverse()
        })
      }
    }
  },

  async created () {
    await this.initRequests()
    await this.setHeaderMenu()
    await this.setSidebarMenu()
    this.appLoading = false
  },

  beforeDestroy () {
    this.$store.commit('menu/CLEAR_STATE')
  },

  methods: {
    ...mapActions({
      initRequests: 'initRequests',

      setHeaderMenu: 'menu/setHeaderMenu',
      setSidebarMenu: 'menu/setSidebarMenu'
    }),

    initAnimation () {
      if (window.matchMedia('(max-width: 992px)').matches) {
        if (this.isMenuMounted && this.appMenuAnimation === null) {
          this.setMenuAnimation()
        }
        if (this.isPageMounted) {
          this.setPageAnimation()
        }
      }
    },
    setMenuAnimation () {
      this.appMenuAnimation = gsap.timeline()
        .to('.app-menu__button-icon', {
          duration: 0.1,
          scaleX: -1
        }, 0)
        .from('.app-menu', {
          duration: 0.3,
          width: 70
        }, 0)
        .from('.app-menu__item-title', {
          delay: -0.3,
          duration: 0.2,
          stagger: 0.1,
          width: 0,
          opacity: 0,
          marginLeft: 0
        }, 1)
        .pause()
    },
    setPageAnimation () {
      this.appPageAnimation = gsap.to('.page', {
        duration: 0.5,
        x: 150,
        paddingLeft: 70
      }).pause()
    },

    handleScroll () {
      this.visibleButtonScroll = document.querySelector('main').scrollTop > 300
    },
    scrollUp
  }
}
</script>
