import { createBaseApi, createBaseCRUDApi } from '@/api/baseApi'
import { hide, postSorted } from '@/api/request'
import { throwResponseError } from '@/utils/error/throwResponseError'

export function createHandbookApi (url) {
  return {
    ...createBaseApi(url),
    postSorted: (idsArray) => postSorted(url, idsArray)
  }
}

export function createNewHandbookApi (url, errors) {
  return {
    ...createBaseCRUDApi(url, errors),

    postSorted: async (idsArray) => {
      try {
        return await postSorted(url, idsArray)
      } catch (e) {
        throwResponseError(e, errors.postSorted)
      }
    },

    hide: async (id, hidden = true) => {
      try {
        return await hide(url, id, hidden)
      } catch (e) {
        throwResponseError(e, errors.hide)
      }
    }
  }
}
