/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/admin-handbook': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<path pid="0" d="M13 5.823l4-.53M13 9l4-.53M13 12.176l4-.53M7 5.823l-4-.53M7 9l-4-.53M7 12.176l-4-.53" _stroke="currentColor" stroke-linecap="round"/><path pid="1" d="M10.024 2.261a.5.5 0 01.434-.496l8.477-1.12a.5.5 0 01.565.496v14.598a.5.5 0 01-.434.495l-8.477 1.12a.5.5 0 01-.565-.495V2.261zM9.976 2.261a.5.5 0 00-.434-.496L1.065.645a.5.5 0 00-.565.496v14.598a.5.5 0 00.434.495l8.477 1.12a.5.5 0 00.565-.495V2.261z" _stroke="currentColor"/>'
  }
})
