export const mixinIsFilter = {
  data: () => ({
    isFilter: false
  }),
  methods: {
    toggleFilter () {
      this.isFilter = !this.isFilter
    }
  }
}
