/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clear': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M12.2 3.807a.664.664 0 00-.94 0L8 7.06 4.74 3.8a.664.664 0 10-.94.94L7.06 8 3.8 11.26a.664.664 0 10.94.94L8 8.94l3.26 3.26a.664.664 0 10.94-.94L8.94 8l3.26-3.26a.668.668 0 000-.933z" _fill="currentColor"/>'
  }
})
