export default class BaseModel {
  _id
  name
  constructor ({ id, name }) {
    this._id = id
    this.name = name
  }

  get id () {
    return this._id
  }
}
