import { instance, configMultipart } from '@/api'
import { throwResponseError } from '@/utils/error/throwResponseError'

const getAll = (url, params = {}) => {
  return instance.get(`/${url}`, { params })
}

const getById = (url, id, params = {}) => {
  return instance.get(`/${url}/${id}`, { params })
}

const create = (url, data = {}, params = {}) => {
  return instance.post(`/${url}`, data, { params })
}

const update = (url, id, data = {}, params = {}) => {
  return instance.put(`/${url}/${id}`, data, { params })
}

const destroy = (url, id) => {
  return instance.delete(`/${url}/${id}`)
}

// Файлы
const postFiles = (url, id, data) => {
  return instance.post(`/${url}/${id}/add-file`, data, configMultipart)
}
const deleteFile = id => instance.delete(`/media/delete-file/${id}`)
const deleteFiles = idsArray => {
  return Promise.all(idsArray.map(id => deleteFile(id)))
}
async function sortFiles (ids) {
  try {
    return await instance.put('/media/sort', ids)
  } catch (e) {
    throwResponseError(e, 'Не удалось сохранить сортировку файлов')
  }
}
const importFiles = (url, data) => {
  return instance.post(`/${url}/import/xlsx`, data, configMultipart)
}

// print
const getPrint = (type, params = {}) => {
  return instance.get(`/report/${type}`, { params })
}

// sorted (используется в справочниках)
const postSorted = (url, idsArray) => {
  return instance.post(`/${url}/sort`, idsArray)
}

// для скрытия записи из списков
function hide (url, id, hidden = true) {
  return instance.patch(`/${url}/${id}/hidden`, { hidden })
}

export {
  getAll,
  getById,
  create,
  update,
  destroy,

  getPrint,

  postFiles,
  deleteFile,
  deleteFiles,
  sortFiles,
  importFiles,

  postSorted,
  hide
}
