// фиксирует количество знаков после точки, и удаляет нули
function fixedNumberCopy (num, afterDot = 4) {
  if (num > 0 && num < 0.0001) {
    return 0.0001
  }

  const str = num.toFixed(afterDot)

  let count = 0

  for (let i = 1; i < str.length; i++) {
    const char = str[str.length - i]

    if (char !== '0') {
      if (char === '.') {
        count += 1
      }
      return +str.substr(0, str.length - count)
    }

    count += 1
  }
}

export {
  fixedNumberCopy
}
