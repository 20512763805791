<template>
  <thead class="custom-table__head table__head">
    <tr>
      <th
        v-for="th of head"
        :key="th.title"
        class='custom-table__th table-border-bottom-bold'
      >
        {{ th.title }}
      </th>
    </tr>
  </thead>
</template>

<script>
export default {
  props: {
    head: {
      type: Array,
      required: true
    }
  }
}
</script>
