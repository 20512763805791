import { getAll } from '@/api/request'
import { setAlertError } from '@/utils/store/alert'
import material from '@/store/modules/warehouse/material'

const state = () => ({
  url: 'warehouse/positions',
  materials: [],
  material: {},
  countMaterialByArticle: null,
  params: {
    'per-page': 10000,
    sort: '-id'
  }
})

const getters = {
  materials: state => state.materials,
  countMaterialByArticle: state => state.countMaterialByArticle,

  getMaterialInStockBy: state => article => {
    article = article.trim()

    const material = state.materials.find(item => item.article === article)

    if (!material) return 0
    return +material.amount - +material.amount_reserved
  }
}

const mutations = {
  SET_MATERIALS (state, materials) {
    state.materials = materials.map(item => ({
      ...item,
      article: item?.article.trim()
    }))
  },

  SET_ARTICLE (state, article) {
    article = article.trim()
    const m = state.materials.find(m => m.article === article)
    if (m) {
      state.countMaterialByArticle = parseInt(m.amount) - parseInt(m.amount_reserved)
    } else {
      state.countMaterialByArticle = 0
    }
  },

  COUNT_CALC_BY_ARTICLE (state, data) {
    const m = state.materials.find(m => m.article === data.article)
    if (m) {
      state.countMaterialByArticle = state.countMaterialByArticle + parseInt(data.value)
    }
  }
}

const actions = {
  async getMaterials ({ state, commit }) {
    try {
      const response = await getAll(state.url, state.params)
      commit('SET_MATERIALS', response.data)
    } catch (e) {
      await setAlertError(e, 'Не удалось загрузить список материалов')
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    material
  }
}
