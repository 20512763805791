/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification-question': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10.324 4.545c.607 0 1.123.107 1.546.319.433.213.76.498.98.856.221.358.332.749.332 1.174 0 .387-.065.721-.193 1.001a2.216 2.216 0 01-.47.667 7.154 7.154 0 01-.718.58c-.377.261-.658.503-.842.725-.184.222-.276.517-.276.884v.493H9.04v-.565c0-.435.064-.802.193-1.102.138-.309.299-.551.483-.725.185-.184.424-.382.718-.595.313-.222.539-.415.677-.58.147-.164.221-.377.221-.638 0-.319-.106-.565-.318-.739-.202-.184-.483-.276-.842-.276-.653 0-1.238.319-1.753.957l-1.146-.942c.8-.996 1.818-1.494 3.051-1.494zm-.442 8.106c.313 0 .58.116.801.348.221.222.331.498.331.826 0 .319-.11.595-.331.827a1.066 1.066 0 01-.801.348 1.05 1.05 0 01-.787-.348 1.184 1.184 0 01-.317-.827c0-.328.105-.604.317-.826.212-.232.474-.348.787-.348z" _fill="currentColor"/><path pid="1" _stroke="currentColor" d="M19.091 10A9.091 9.091 0 0110 19.091 9.091 9.091 0 01.909 10a9.091 9.091 0 0118.182 0z" stroke-width=".9"/>'
  }
})
