import { vuexHandbook } from '@/store/composition/handbook'
import { ConsignmentStageApi, CONSIGNMENT_STAGE_URL } from '@/api/handbook/consignment/ConsignmentStage/ConsignmentStageApi'
import store from '@/store'

const state = () => ({
  ...vuexHandbook.createState(),
  api: ConsignmentStageApi,
  url: CONSIGNMENT_STAGE_URL
})

const getters = {
  ...vuexHandbook.createGetters(),

  getList: state => {
    return state.list.map(item => {
      let text = ''
      if (item.types) {
        item.types.forEach(status => {
          const current = store.state.records.properties.statuses_types.find(item => item.code === status)
          text = text + current.name + ', '
        })
        text = text.slice(0, -2)
      }
      return {
        ...item,
        data: [{ name: 'Активность для партий', value: text || 'для всех типов' }]
      }
    })
  }
}

const mutations = {
  ...vuexHandbook.createMutations()
}

const actions = {
  ...vuexHandbook.createActions()
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
