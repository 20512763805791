import { createBaseCRUDApi } from '@/api/baseApi'
import { createBaseResponseErrors } from '@/utils/error/responseErrors'
import { instance } from '@/api'
import { CATALOG_URL } from '@/api/catalog/index'
import { throwResponseError } from '@/utils/error/throwResponseError'

const CATALOG_CATEGORIES_URL = `${CATALOG_URL}/categories`

const CATALOG_ERRORS = {
  ...createBaseResponseErrors('раздел', 'разделов')
}

export default {
  ...createBaseCRUDApi(CATALOG_CATEGORIES_URL, CATALOG_ERRORS),

  updateMass: async (data) => {
    try {
      return await instance.post(`/${CATALOG_CATEGORIES_URL}/mass`, data)
    } catch (e) {
      throwResponseError(e, 'Не удалось сохранить разделы')
    }
  }
}
