/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'checkbox': {
    width: 13,
    height: 11,
    viewBox: '0 0 13 11',
    data: '<path pid="0" d="M2 4.15L5.75 8 11 1" _stroke="currentColor" stroke-width="3"/>'
  }
})
