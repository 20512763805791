const state = () => ({
  permissionList: [],
  role: '',
  roleId: null
})

const getters = {
  isAdmin: state => state.roleId === 1,
  isDirector: state => state.roleId === 2,
  isSalesManager: state => state.roleId === 3,
  isProcessEngineer: state => state.roleId === 4,
  isProductionManager: state => state.roleId === 5,
  isOTK: state => state.roleId === 6,
  isWarehouseManager: state => state.roleId === 7,
  isWarehouseOperator: state => state.roleId === 8,
  isBrigadier: state => state.roleId === 9,
  isExecutor: state => state.roleId === 10,
  isProductionMaster: state => state.roleId === 11,

  // ПРОИЗВОДСТВО
  permissionPrintDocumentProduction: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('print report')
  },

    // Заказы
  permissionCreateOrder: (state, getters) => {
    return getters.isAdmin
  },
  permissionUpdateOrder: state => {
    return state.permissionList.includes('update order')
  },
  permissionUpdateOrderByRole: state => {
    switch (state.role) {
      case 'admin':
      case 'director':
      case 'process-engineer': return true
      default: return false
    }
  },
  permissionReadOrderChangelog: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('show history-order')
  },
  permissionUpdateOrderReasonForDelayByRole: state => {
    switch (state.role) {
      case 'admin':
      case 'director':
      case 'process-engineer':
      case 'production-manager': return true
      default: return false
    }
  },
  permissionUpdateOrderMarkersByRole: state => {
    switch (state.role) {
      case 'admin':
      case 'director':
      case 'production-manager':
      case 'warehouse-manager':
      case 'warehouse-operator': return true
      default: return false
    }
  },
  permissionDestroyOrder: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('destroy order')
  },

    // Транспортная накладная
  permissionReadWaybill: (state, getters) => {
    return getters.isAdmin || getters.isDirector || getters.isSalesManager || getters.isProcessEngineer || getters.isProductionManager || getters.isOTK || getters.isWarehouseManager || getters.isWarehouseOperator
  },
  permissionCreateWaybill: (state, getters) => {
    return getters.isAdmin
  },
  permissionPrintWaybill: (state, getters) => {
    return getters.isAdmin || getters.isDirector || getters.isProcessEngineer || getters.isProductionManager || getters.isOTK || getters.isWarehouseManager || getters.isWarehouseOperator
  },

    // Позиции
  permissionCreatePosition: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('store position')
  },
  permissionUpdatePosition: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('update position')
  },
  permissionDestroyPosition: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('destroy position')
  },
  permissionCopyPosition: state => {
    switch (state.role) {
      case 'admin':
      case 'process-engineer':
      case 'director': return true
      default: return false
    }
  },

    // Партии
  permissionCreateConsignment: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('store consignment')
  },
  permissionCreateConsignmentWithoutWending: (state, getters) => {
    return getters.isAdmin || getters.isDirector || getters.isProductionManager || getters.isProcessEngineer
  },
  permissionUpdateConsignment: state => {
    switch (state.role) {
      case 'admin':
      case 'production-master':
      case 'brigadier': return true
    }
    return state.permissionList.includes('update consignment')
  },
  permissionUpdateConsignmentExecutor: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('update consignment-executor')
  },
  permissionUpdateConsignmentStageNext: (state, getters) => {
    return getters.isAdmin || getters.isProcessEngineer
      || state.permissionList.includes('update consignment-next-status')
  },
  permissionUpdateConsignmentStageBack: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('update consignment-previous-status')
  },
  permissionUpdateConsignmentWeight: state => {
    switch (state.role) {
      case 'admin':
      case 'production-master':
      case 'otk':
      case 'process-engineer':
      case 'production-manager':
      case 'director':
      case 'executor':
      case 'brigadier': return true
    }
    return state.permissionList.includes('update consignment-dirty-weight')
  },
  permissionUpdateConsignmentReclamation: state => {
    switch (state.role) {
      case 'admin':
      case 'otk':
      case 'process-engineer':
      case 'warehouse-manager':
      case 'director': return true
      default: return false
    }
  },
  permissionUpdateConsignmentUtil: state => {
    switch (state.role) {
      case 'admin':
      case 'process-engineer':
      case 'director': return true
      default: return false
    }
  },
  permissionDestroyConsignment: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('destroy consignment')
  },
  permissionPrintDocsOffConsignment: state => {
    switch (state.role) {
      case 'admin':
      case 'otk':
      case 'process-engineer':
      case 'director': return true
      default: return false
    }
  },
  permissionExportXslConsignment: state => {
    switch (state.role) {
      case 'admin':
      case 'otk':
      case 'warehouse-manager':
      case 'brigadier':
      case 'production-manager':
      case 'production-master':
      case 'director': return true
      default: return false
    }
  },

    // Комментарии
  permissionCreateComment: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('store comment')
  },

  // ШАБЛОНЫ
  permissionCreatePositionTemplate: (state, getters) => {
    return getters.isAdmin || getters.isProcessEngineer
  },
      // Позиции
  permissionCreateTemplatePosition: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('store template')
  },
  permissionUpdateTemplatePosition: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('update template')
  },
  permissionDestroyTemplatePosition: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('destroy template')
  },

      // Этапы
  permissionCreateTemplateStageLine: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('store template-status')
  },
  permissionUpdateTemplateStageLine: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('update template-status')
  },
  permissionDestroyTemplateStageLine: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('destroy template-status')
  },

  // ЖУРНАЛ ИЗМЕНЕНИЙ
  permissionReadChangelog: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('show history-general')
  },

  // КАТАЛОГ

  permissionReadCatalog: (state, getters) => {
    return getters.isAdmin
      || getters.isDirector
      || getters.isSalesManager
      || getters.isProcessEngineer
      || getters.isProductionManager
  },
  permissionUpdateCatalogTitlePage: (_, getters) => getters.isAdmin,
  permissionCreateCatalogCategory: (state, getters) => getters.isAdmin,
  permissionUpdateCatalogCategory: (state, getters) => getters.isAdmin,
  permissionDestroyCatalogCategory: (state, getters) => getters.isAdmin,
  permissionCreateProduct: (state, getters) => getters.isAdmin,
  permissionUpdateProduct: (state, getters) => getters.isAdmin || getters.isProcessEngineer,
  permissionDestroyProduct: (state, getters) => getters.isAdmin,

  permissionReadProductFilesAdmin: (state, getters) => {
    return getters.isAdmin || getters.isDirector
  },
  permissionReadProductPositionFields: (state, getters) => {
    return getters.isAdmin || getters.isDirector || getters.isProcessEngineer
  },
  permissionUpdateProductPositionFields: (state, getters) => {
    return getters.isAdmin || getters.isProcessEngineer
  },

  // ПАНЕЛЬ АДМИНИСТРАТОРА

    // Справочник
  permissionCreateHandbook: (state, getters) => {
    return getters.isAdmin
      || state.permissionList.includes('store property')
  },

  // РЕКЛАМАЦИИ

  permissionReadReclamation: (_, getters) => {
    return !getters.isExecutor && !getters.isBrigadier && !getters.isProductionMaster
  },
  permissionCreateReclamation: (_, getters) => getters.isAdmin,
  permissionDestroyReclamation: (_, getters) => {
    return getters.isAdmin || getters.isDirector || getters.isProcessEngineer
  },
  permissionUpdateReclamationGeneralInformation: (_, getters) => getters.isAdmin,
  permissionUpdateReclamationIndicator: (_, getters) => {
    return getters.isAdmin || getters.isDirector || getters.isProcessEngineer
  },
  permissionUpdateReclamationSpringReceived: (_, getters) => {
    return getters.isAdmin || getters.isDirector || getters.isProcessEngineer || getters.isWarehouseManager || getters.isWarehouseOperator
  },
  permissionUpdateReclamationPosition: (_, getters) => {
    return getters.isAdmin || getters.isDirector || getters.isProcessEngineer
  },
  permissionUpdateReclamationInvestigation: (_, getters) => {
    return getters.isAdmin || getters.isDirector || getters.isProcessEngineer
  },
  permissionUpdateReclamationResolution: (_, getters) => {
    return getters.isAdmin || getters.isDirector || getters.isProcessEngineer
  }
}

const mutations = {
  SET_PERMISSION_STATE (state, user) {
    state.role = user?.roles[0]?.name || ''
    state.roleId = user?.roles[0]?.id || null
    state.permissionList = user?.permissions
        .map(item => item.name || '')
      || []
  },
  CLEAR_PERMISSION_STATE (state) {
    state.permissionList = []
    state.role = ''
    state.roleId = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
