/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/copy': {
    width: 15,
    height: 19,
    viewBox: '0 0 15 19',
    data: '<rect pid="0" x=".5" y=".75" width="8.625" height="11.25" rx=".5" _stroke="#BCC5D8"/><path pid="1" d="M9.624 6.374H13a1 1 0 011 1v9.375a1 1 0 01-1 1H6.25a1 1 0 01-1-1V12.06" _stroke="#BCC5D8"/>'
  }
})
