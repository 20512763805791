export function getResponseErrorMessage (error) {
  const data = error?.response?.data
  if (error.response.status === 401) return error.response.status
  if (data?.error) return data.error
  if (!data?.errors) return ''

  return getMessageList(data.errors)
}

function getMessageList (errors) {
  const messageList = []
  const errorValuesList = Object.values(errors)

  for (const values of errorValuesList) {
    messageList.push(...values)
  }

  return messageList.join(' ')
}
