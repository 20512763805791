import { create, destroy, getAll, getById, update } from '@/api/request'
import { throwResponseError } from '@/utils/error/throwResponseError'

export function createBaseApi (url) {
  return {
    getAll: (params) => getAll(url, params),
    getById: (id, params) => getById(url, id, params),
    create: (data, params) => create(url, data, params),
    update: (id, data, params) => update(url, id, data, params),
    destroy: (id) => destroy(url, id)
  }
}

export function createBaseCRUDApi (url, errors) {
  return {
    getAll: async (params) => {
      try {
        return await getAll(url, params)
      } catch (e) {
        throwResponseError(e, errors.getAll)
      }
    },

    getById: async (id, params) => {
      try {
        return await getById(url, id, params)
      } catch (e) {
        throwResponseError(e, errors.getById)
      }
    },

    create: async (data, params) => {
      try {
        return await create(url, data, params)
      } catch (e) {
        throwResponseError(e, errors.create)
      }
    },

    update: async (id, data, params) => {
      try {
        return await update(url, id, data, params)
      } catch (e) {
        throwResponseError(e, errors.update)
      }
    },

    destroy: async (id) => {
      try {
        return await destroy(url, id)
      } catch (e) {
        throwResponseError(e, errors.destroy)
      }
    }
  }
}
