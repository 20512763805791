import { vuexHandbook } from '@/store/composition/handbook'
import { DelayReasonApi } from '@/api/handbook/position/DelayReason/DelayReasonApi'

const state = () => ({
  ...vuexHandbook.createState(),
  api: DelayReasonApi
})

const getters = {
  ...vuexHandbook.createGetters()
}

const mutations = {
  ...vuexHandbook.createMutations()
}

const actions = {
  ...vuexHandbook.createActions()
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
