/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user': {
    width: 15,
    height: 15,
    viewBox: '0 0 15 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.078 6a3.01 3.01 0 002.125-.879 2.997 2.997 0 000-4.242 3.01 3.01 0 00-4.25 0 2.997 2.997 0 000 4.242A3.01 3.01 0 007.077 6zM.063 15a6.988 6.988 0 012.055-4.95A7.015 7.015 0 017.078 8a7.027 7.027 0 014.96 2.05A6.997 6.997 0 0114.091 15H.063z" _fill="#0E1D3A"/>'
  }
})
