export default {
  namespaced: true,
  state: () => ({
    isModal: ''
  }),
  mutations: {
    OPEN_MODAL (state, name) {
      state.isModal = name
    },

    CLOSE_MODAL (state) {
      state.isModal = ''
    }
  }
}
