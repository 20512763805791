/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/create': {
    width: 13,
    height: 13,
    viewBox: '0 0 13 13',
    data: '<path pid="0" d="M6.625 1v11.25M12.25 6.625H1" _stroke="currentColor" stroke-linecap="round"/>'
  }
})
