<template>
  <div
    class="input"
    :style="{ width }"
  >
    <label
      v-if="label"
      class="input__title"
      :class="{ 'disabled' : disabled }"
    >
      {{ label }}
      <span v-if="required">*</span>
      <VTooltip
        v-if="isTooltip"
        :popup-text="tooltipText"
        show-tip
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="10" cy="10" r="9.5" stroke="#6699FF"/>
            <path d="M10.7581 7.20788C10.5886 7.20788 10.4268 7.18071 10.2727 7.12636C10.1186 7.06748 9.98511 6.98822 9.87211 6.88859C9.75912 6.79348 9.66923 6.67799 9.60247 6.54212C9.54083 6.40625 9.51002 6.26359 9.51002 6.11413C9.51002 5.96467 9.54083 5.82201 9.60247 5.68614C9.66923 5.55027 9.75912 5.43025 9.87211 5.32609C9.98511 5.22192 10.1186 5.14266 10.2727 5.08832C10.4268 5.02944 10.5886 5 10.7581 5C10.9276 5 11.0894 5.02944 11.2435 5.08832C11.3975 5.14266 11.5336 5.22192 11.6518 5.32609C11.7699 5.43025 11.8598 5.55027 11.9214 5.68614C11.9882 5.82201 12.0216 5.96467 12.0216 6.11413C12.0216 6.26359 11.9882 6.40625 11.9214 6.54212C11.8598 6.67799 11.7699 6.79348 11.6518 6.88859C11.5336 6.98822 11.3975 7.06748 11.2435 7.12636C11.0894 7.18071 10.9276 7.20788 10.7581 7.20788ZM9.68721 15C9.18387 15 8.78582 14.8687 8.49307 14.606C8.16436 14.3161 8 13.8927 8 13.3356C8 13.0729 8.04109 12.7695 8.12327 12.4253L9.19414 7.96196H11.4669L10.3344 12.6902C10.2933 12.808 10.2727 12.9574 10.2727 13.1386C10.2727 13.3288 10.3215 13.4624 10.4191 13.5394C10.5218 13.6164 10.6862 13.6549 10.9122 13.6549C11.0663 13.6549 11.2152 13.6255 11.359 13.5666C11.508 13.5032 11.6441 13.4171 11.7673 13.3084C12.019 13.0865 12.209 12.7921 12.3374 12.4253H13C12.7175 13.1273 12.3631 13.6866 11.9368 14.1033C11.5773 14.4429 11.1715 14.6875 10.7196 14.837C10.3909 14.9457 10.0467 15 9.68721 15Z" fill="#79A6FF"/>
        </svg>
      </VTooltip>
    </label>

    <!-- TODO :track-by="itemText" => id -->
    <Multiselect
      v-model="inputValue"
      :class="[{'multiselect__multi': multiple}, {'input_error': hasValidationError}, {'multiselect__show-numbers': showNumberSelected}]"
      :placeholder="placeholder"
      :options="shownOptions"
      :label="itemText"
      :track-by="itemText"
      :tabindex="0"
      :maxHeight="300"
      :searchable="searchable || !multiple"
      :disabled="disabled"
      :close-on-select="!multiple"
      :allow-empty="allowEmpty"
      :multiple="multiple"
      :loading="loading"
      :showLabels="false"
      :resetAfter="false"
      :internal-search="false"
      :custom-validation="customValidation"
      @select="$emit('select')"
      @open="$emit('open')"
      @close="$emit('close')"
      @search-change="searchHandler"
    >
      <template v-slot:singleLabel="{ option }">
        <slot name="singleLabel" :option="option">
          {{ option[itemText] }}
        </slot>
      </template>

      <template v-slot:tag="{ option, remove }">
        <slot name="tag" :option="option" :remove="remove" />
      </template>

      <template v-slot:option="{ option }">
        <slot v-if="!showNumberSelected" name="option" :option="option"/>
        <slot v-else name="option" :option="option">
          <span class="multiselect__option--number">{{ getNumberSelected(option[itemText]) }}</span>
          <span>{{ option[itemText] }}</span>
        </slot>
      </template>

      <template v-slot:noResult>Ничего не найдено</template>
      <template v-slot:noOptions>Список пуст</template>
    </Multiselect>

    <slot v-if="hasValidationError" />
  </div>
</template>

<script>
import VTooltip from '@/components/ui/VTooltip.vue'
import Multiselect from 'vue-multiselect'
import Base from './Vinput/Base/Base.vue'
import Vue from 'vue'

export default {
  name: 'VSelect',
  inject: {
    formData: {
      from: 'formData',
      default: null
    }
  },
  extends: Base,
  components: {
    Multiselect,
    VTooltip
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    placeholder: {
      type: String,
      default: 'Выбрать'
    },
    itemText: {
      type: String,
      default: 'name'
    },
    options: {
      type: Array,
      default: () => []
    },
    allowEmpty: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    isTooltip: {
      type: Boolean,
      default: false
    },
    tooltipText: {
      type: String,
      default: ''
    },
    showNumberSelected: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    shownOptions: [],
    timeoutSearch: null
  }),
  computed: {
    inputValue: {
      get () {
        if (this.formData && this.name) {
          return this.formData[this.name]
        } else {
          return this.value
        }
      },
      set (val) {
        if (this.formData && this.name) {
          if (this.multiple && !val.length) {
            val = null
          }
          Vue.set(this.formData, this.name, val)
        }
        this.$emit('change', val)
      }
    },
    rules () {
      const validators = {}

      if (this.required) {
        if (!this.inputValue) {
          validators.required = () => false
        }

        if (this.inputValue && this.inputValue instanceof Object) {
          validators.required = Object.keys(this.inputValue).length
        }
        if (this.inputValue && this.inputValue instanceof Array) {
          validators.required = () => !!this.inputValue?.length
        }
      }

      if (this.customValidation) {
        validators.customValidation = value => this.customValidation(value)
      }

      return validators
    }
  },
  watch: {
    options () {
      this.syncOptions()
    }
  },
  mounted () {
    this.syncOptions()
  },
  methods: {
    searchHandler (value) {
      const normalizedSearch = value.toLowerCase().trim()

      if (!normalizedSearch) {
        return this.syncOptions()
      }

      const toRegExp = normalizedSearch.replace(/[.,]/, '[.,]')
      const regexp = new RegExp(toRegExp)

      this.shownOptions = this.options
        .filter(option => this.filterOption(option, regexp))
        .sort((a, b) => this.sortOption(a, b, normalizedSearch))
    },

    filterOption (option, regexp) {
      const normalizedLabel = this.getNormalizedOptionLabel(option)
      return regexp.test(normalizedLabel)
    },

    sortOption (a, b, search) {
      const normalizedA = this.getNormalizedOptionLabel(a)
      const normalizedB = this.getNormalizedOptionLabel(b)

      const startA = normalizedA.startsWith(search)
      const startB = normalizedB.startsWith(search)

      if (startA && !startB) return -1
      if (!startA && startB) return 1
      return 0
    },

    getNormalizedOptionLabel (option) {
      return option[this.itemText].toLowerCase()
    },

    syncOptions () {
      this.shownOptions = this.options
    },

    getNumberSelected (optionName) {
      const key = this.inputValue?.findIndex(item => item[this.itemText] === optionName)
      return (key !== -1 && key !== undefined) ? +key + 1 : ''
    }
  }
}
</script>

<style>
.input__title {
  line-height: 30px;
  margin-bottom: 10px;
}
</style>
