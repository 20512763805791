export class Waybill {
  constructor (data) {
    this.id = data.id
    this.created_at = data.created_at
    this.updated_at = data.updated_at
    // 'Отправитель'
    this.sender = data.sender
    // 'Получатель'
    this.recipient = data.recipient
    // 'ИНН/Серия,№'
    this.TIN = data.tin
    // 'Плательщик'
    this.payer = data.payer
    // 'Доставка'
    this.delivery = data.delivery
    // 'До'
    this.before = data.before
    // 'Адрес'
    this.address = data.address
    // 'Телефон получателя'
    this.phone = data.phone
    // 'Совместная отгрузка с заказом №'
    this.order_account_number = data.order_account_number
    // 'Комментарий к отгрузке'
    this.comment = data.comment
    // 'Способ оплаты'
    this.payment_method = data.payment_method
    // 'Дубль счета'
    this.account_double = data.account_double
    // 'Связанные заказы (объекты)'
    this.relation_orders = data.relation_orders
  }
}
