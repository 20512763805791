
import { createBaseCRUDApi } from '@/api/baseApi'
import { CATALOG_URL } from '@/api/catalog/index'
import { createBaseResponseErrors } from '@/utils/error/responseErrors'

const FILTER_URL = `${CATALOG_URL}/filter-values`

const errors = {
  ...createBaseResponseErrors('фильтр', 'фильтры')
}

export default {
  ...createBaseCRUDApi(FILTER_URL, errors)
}
