import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import modules from '@/store/modules'

import UserController from '@/controllers/user/UserController'
import { fullName } from '@/utils/string'
import { setAlertError } from '@/utils/store/alert'
import { reclamationPages } from '@/views/Reclamation'
const TOKEN_KEY = 'jwt-token'
const USER = 'currentUser'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.getItem(USER)) || null,
    token: localStorage.getItem(TOKEN_KEY),
    appLoading: true,
    pages: {
      reclamationPages
    },

    // для отображения спинера
    isSendingRequestPage: false,
    isSendingRequestModal: false,
    isLoadingDnD: false
  },

  getters: {
    currentUser: state => state.user,
    currentUserId: state => state.user?.id || null,
    currentUserRole: state => {
      return state.user?.roles?.at(0)?.name || null
    },
    currentUserFullName: state => {
      return fullName(state.user?.surname, state.user?.name)
    },

    token (state) {
      return state.token
    },
    isAuthenticated (_, getters) {
      return !!getters.token
    }
  },

  mutations: {
    SET_USER (state, user) {
      state.user = user
      const parsedUser = JSON.stringify(user)
      localStorage.setItem(USER, parsedUser)
    },
    SET_TOKEN (state, token) {
      state.token = token
      localStorage.setItem(TOKEN_KEY, token)
    },
    LOGOUT (state) {
      state.user = null
      state.token = null
    },

    SET_IS_SENDING_REQUEST_PAGE (state, bool = true) {
      state.isSendingRequestPage = bool
    },
    START_IS_SENDING_REQUEST_PAGE (state) {
      state.isSendingRequestPage = true
    },
    FINISH_IS_SENDING_REQUEST_PAGE (state) {
      state.isSendingRequestPage = false
    },
    SET_IS_SENDING_REQUEST_MODAL (state, bool = true) {
      state.isSendingRequestModal = bool
    },
    START_IS_SENDING_REQUEST_MODAL (state) {
      state.isSendingRequestModal = true
    },
    FINISH_IS_SENDING_REQUEST_MODAL (state) {
      state.isSendingRequestModal = false
    },

    START_IS_LOADING_DND: state => {
      state.isLoadingDnD = true
    },
    FINISH_IS_LOADING_DND: state => {
      state.isLoadingDnD = false
    },
    SET_USER_META (state, meta) {
      const user = JSON.parse(localStorage.getItem(USER))
      user.meta = meta
      state.user = user
      const parsedUser = JSON.stringify(user)
      localStorage.setItem(USER, parsedUser)
    }
  },

  actions: {
    async initRequests ({ dispatch }) {
      await axios.all([
        dispatch('handbook/userRole/loadUserRoles'),
        dispatch('handbook/userDepartment/loadList'),
        dispatch('handbook/userPost/loadList'),

        dispatch('records/getTimingAll'),
        dispatch('records/getPropertiesAll'),
        dispatch('warehouse/getMaterials'),

        // after refactor
        // Пользователи
        dispatch('users/loadAll'),
        // Шаблоны
        dispatch('productions/template/position/loadAll'),
        dispatch('productions/template/stageLine/loadAll')
      ])
    },

    async login ({ commit, dispatch }, payload) {
      try {
        const res = await UserController.login(payload)
        if (res?.status !== 200) return

        commit('SET_TOKEN', res.data.data.token)
        commit('SET_USER', res.data.data.user)
        commit('permission/SET_PERMISSION_STATE', res.data.data.user)
      } finally {
        this.appLoading = false
      }
    },

    async logout ({ commit }) {
      try {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(USER)
        commit('permission/CLEAR_PERMISSION_STATE')
        commit('LOGOUT')
        delete axios.defaults.headers.common.Authorization
      } catch (e) {
        const message = e.response.data.message
        await setAlertError(e, message)
      }
    }
  },

  modules
})
