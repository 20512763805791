import { RECLAMATION_INNER_REPORT_INFO } from '@/const/analytics'
import {
  CONSIGNMENT_RECLAMATION_INNER_TYPE_LIST
} from '@/const/production/consignment/consignmentReclamationInnerTypeList'

function getMapped (data) {
  let count = 0
  let rows = null
  const mappedRows = []
  for (let i = 0; i < data.length; i++) {
    count = data[i]?.rows?.length
    rows = [{}]
    for (const key in data[i]) {
      if (key === 'rows') continue

      if (key === RECLAMATION_INNER_REPORT_INFO.thDecision.name) {
        rows[0][key] = getDecisionTable(data[i][key])
      } else if (key === RECLAMATION_INNER_REPORT_INFO.thConsignmentLink.name) {
        rows[0][key] = `Партия № ${data[i][key]} (№${data[i][key]?.order_account_number ?? ''}  / №${data[i][key]?.position_sort ?? ''})`
      } else {
        rows[0][key] = data[i][key]
      }
    }
    if (!count) continue

    for (let k = 0; k < count; k++) {
      if (!rows[k]) {
        rows.push({})
      }

      for (const key in data[i].rows[k]) {
        if (key === RECLAMATION_INNER_REPORT_INFO.thDecision.name) {
          rows[k][key] = getDecisionTable(data[i].rows[k][key])
        } else if (key === RECLAMATION_INNER_REPORT_INFO.thConsignmentLink.name) {
          rows[k][key] = `Партия № ${data[i].rows[k][key]} (№${data[i].rows[k]?.order_account_number ?? ''}  / №${data[i].rows[k]?.position_sort ?? ''})`
        } else {
          rows[k][key] = data[i].rows[k][key]
        }
      }
    }

    mappedRows.push(...rows)
  }
  return mappedRows
}

function getDecisionTable (type) {
  switch (type) {
    case 'remake': type = CONSIGNMENT_RECLAMATION_INNER_TYPE_LIST[0].name
      break
    case 'partial-remake': type = CONSIGNMENT_RECLAMATION_INNER_TYPE_LIST[1].name
      break
    case 'rework': type = CONSIGNMENT_RECLAMATION_INNER_TYPE_LIST[2].name
      break
    case 'deviation_agreed_client': type = CONSIGNMENT_RECLAMATION_INNER_TYPE_LIST[3].name
      break
    case 'shipment_no_agreement': type = CONSIGNMENT_RECLAMATION_INNER_TYPE_LIST[4].name
  }
  return type
}

export {
  getMapped,
  getDecisionTable
}
