import { NavItem } from '@/classes/nav/NavItem'

export class HeaderNavItem extends NavItem {
  icon = 'nav/header/'
  accessKey = ''

  constructor ({ label, routeName, icon, accessKey }) {
    super({ label, routeName })

    this.icon = this.icon + icon
    this.accessKey = accessKey
  }
}
