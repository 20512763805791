import store from '@/store'

 const state = () => ({
  tabs: [
    {
      id: 1,
      routeName: 'OrderList',
      label: 'Заказы'
    },
    {
      id: 2,
      routeName: 'ConsignmentList',
      label: 'Партии'
    }
  ],
  tabsOrderList: [
    {
      id: 1,
      label: 'Новые заказы',
      routeName: 'OrderListItem',
      count: 0,
      slug: 'new'
    },
    {
      id: 6,
      label: 'Заказы на запуск',
      routeName: 'LaunchOrderList',
      count: 0,
      slug: 'launch'
    },
    {
      id: 2,
      label: 'Заказы в работе',
      routeName: 'ProgressOrderList',
      count: 0,
      slug: 'work'
    },
    {
      id: 3,
      label: 'Готовые заказы',
      routeName: 'ReadyOrderList',
      count: 0,
      slug: 'complete'
    },
    {
      id: 4,
      label: 'Заказы на отгрузку',
      routeName: 'ShippingOrderList',
      count: 0,
      slug: 'delivery'
    },
    {
      id: 5,
      label: 'Успешно реализовано',
      routeName: 'ArchiveOrderList',
      count: 0,
      slug: 'archive'
    }
  ],
  tabsConsignmentList: [
    {
      id: 1,
      label: 'Партии в работе',
      routeName: 'ConsignmentListItem',
      count: 0,
      slug: 'work'
    },
    {
      id: 2,
      label: 'Готовые партии',
      routeName: 'ReadyConsignmentList',
      count: 0,
      slug: 'complete'
    },
    {
      id: 3,
      label: 'Партии на отгрузку',
      routeName: 'ShippingConsignmentList',
      count: 0,
      slug: 'delivery'
    },
    {
      id: 4,
      label: 'Успешно реализовано',
      routeName: 'ArchiveConsignmentList',
      count: 0,
      slug: 'archive'
    }
  ]
})

const getters = {
  tabsOrderList: (state) => {
    state.tabsOrderList.forEach(tab => {
      if (tab.slug === 'new') {
        if ((store.getters['permission/isBrigadier'] || store.getters['permission/isProductionMaster'] || store.getters['permission/isExecutor'])) tab.hidden = true
        else tab.hidden = false
      }
    })
    return state.tabsOrderList
  }
}

const mutations = {
  SET_TABS_AMOUNT (state, { tabsName, data }) {
    state[`tabs${tabsName}List`].forEach(tab => {
      tab.count = data[tab.slug]
    })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
