import OrderApi from '@/api/production/Order/OrderApi'
import { createBaseController } from '@/controllers/baseController'
import { createBaseResponseErrors } from '@/utils/error/responseErrors'
import { setAlertError } from '@/utils/store/alert'

const ORDER_ERRORS = {
  ...createBaseResponseErrors('заказ', 'заказов'),
  massStatus: 'Не удалось изменить статус заказа',
  deliveryPermission: '',
  restorePermission: 'Не удалось восстановит заказ',
  markers: 'Не удалось обновить готовность к отгрузке'
}

export default {
  ...createBaseController(OrderApi, ORDER_ERRORS),
  updateMassStatus: async (id, direction) => {
    try {
      return await OrderApi.updateMassStatus(id, direction)
    } catch (e) {
      await setAlertError(e, ORDER_ERRORS.massStatus)
    }
  },
  updateOrderStatus: async (id, status) => {
    try {
      return await OrderApi.updateOrderStatus(id, status)
    } catch (e) {
      await setAlertError(e, ORDER_ERRORS.massStatus)
    }
  },
  updateDeliveryPermission: async (id) => {
    try {
      return await OrderApi.updateDeliveryPermission(id)
    } catch (e) {
      await setAlertError(e, ORDER_ERRORS.deliveryPermission)
    }
  },
  restoreOrder: async (id, externalId) => {
    try {
      return await OrderApi.restoreOrder(id, externalId)
    } catch (e) {
      await setAlertError(e, ORDER_ERRORS.restorePermission)
    }
  },
  updateMarkers: async (id, data) => {
    try {
      return await OrderApi.updateMarkers(id, data)
    } catch (e) {
      await setAlertError(e, ORDER_ERRORS.markers)
    }
  }
}
