<template>
  <FilterForm
    v-model="modelFormData"
    @request="request"
    @reset="onReset"
  >
    <VInputDate
      ref="VInputDate"
      label="По дате поступления:"
      :value="modelData"
      @value-changed="setDate"
    />

    <ReclamationExternalSelectIndicator
      label="Индикатор:"
      name="indicator"
      :options="indicatorOptions"
    />

    <ReclamationExternalSelectResolution
      is-multiple
      is-allow-empty
    />

    <VSelect
      label="По типу изделия:"
      name="position_type"
      :options="properties.product_type"
      multiple
    />

    <VSelect
      label="По исполнителю несоответствия:"
      name="executor"
      :options="$store.state.users.all"
      multiple
      searchable
    />

    <VSelect
      label="По первопричине:"
      name="reason_reclamation_outer"
      :options="reclamationOuterReasonList"
      multiple
      searchable
    />
  </FilterForm>
</template>

<script>
import VSelect from '@/components/Form/VSelect.vue'
import {
  ReclamationExternalSelectIndicator,
  ReclamationExternalSelectResolution,
  reclamationExternalModel
} from '@/entities/reclamation-external'
import FilterForm from '@/components/Form/FilterForm.vue'
import { mixinFilterEmit } from '@/mixins/form/mixinFilterEmit'
import VInputDate from '@/components/Form/VInputDate.vue'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'

export default {
  components: {
    VInputDate,
    ReclamationExternalSelectIndicator,
    ReclamationExternalSelectResolution,
    FilterForm,
    VSelect
  },
  mixins: [mixinFilterEmit],
  computed: {
    ...mapState('handbook/reclamationOuterReason', {
      reclamationOuterReasonList: 'list'
    }),
    ...mapState('records', {
      properties: 'properties'
    }),
    ...mapState('reclamationExternal/filterList', {
      filter: 'filter',
      date: 'date'
    }),
    ...mapGetters('reclamationExternal/filterList', {
      getDate: 'getDate'
    }),

    modelFormData: {
      get () {
        return this.filter
      },
      set (formData) {
        this.SET_FILTER(formData)
      }
    },

    modelData: {
      get () {
        return this.getDate
      },
      set (date) {
        this.SET_DATE(date)
      }
    },
    resolutionOptions: () => reclamationExternalModel.INDICATORS.slice(-4),
    indicatorOptions: () => reclamationExternalModel.INDICATORS
  },
  created () {
    if (!this.reclamationOuterReasonList.length) {
      this.fetchReclamationOuterReasonList()
    }
  },
  methods: {
    ...mapActions('handbook/reclamationOuterReason', {
      loadList: 'loadList'
    }),
    ...mapMutations('reclamationExternal/filterList', {
      SET_FILTER: 'SET_FILTER',
      SET_DATE: 'SET_DATE'
    }),

    setDate ({ value }) {
      this.SET_DATE(value)
    },

    fetchReclamationOuterReasonList () {
      this.loadList()
    },

    onReset () {
      this.$refs.VInputDate.$refs.dateRef._flatpickr.clear()
      this.reset()
    }
  }
}
</script>
