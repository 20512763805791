<template>
  <div class="tabs-swiper">
    <div
      :id="swiperId"
      class="swiper-container tabs-swiper__container"
      :class="`tabs-swiper--${_uid}`"
    >
      <ul class="swiper-wrapper">
        <!--для корректной работы внутри слота должны быть слайды с классом swiper-slide-->
        <slot />
      </ul>
    </div>

    <VButton
      class="tabs-swiper__btn tabs-swiper__btn--prev"
      :class="`tabs-swiper__btn--prev-${_uid}`"
      block="swiper"
      size="init"
      :icon="{ name: 'swiper-arrow', dir: '180' }"
    />

    <VButton
      class="tabs-swiper__btn"
      :class="`tabs-swiper__btn--next-${_uid}`"
      block="swiper"
      size="init"
      icon="swiper-arrow"
    />
  </div>
</template>

<script>
import VButton from '@/components/simple/button/VButton'
import Swiper, { Navigation, Pagination } from 'swiper'

Swiper.use([Navigation, Pagination])

export default {
  name: 'TabsSwiper',
  components: {
    VButton
  },
  props: {
    spaceBetween: {
      type: Number,
      default: 20
    }
  },
  data: () => ({
    swiper: null
  }),
  computed: {
    swiperId () {
      return 'tabs-swiper--' + this._uid
    }
  },
  mounted () {
    this.swiper = new Swiper(`#${this.swiperId}`, {
      slidesPerView: 'auto',
      spaceBetween: this.spaceBetween,
      watchOverflow: true,
      navigation: {
        prevEl: `.tabs-swiper__btn--prev-${this._uid}`,
        nextEl: `.tabs-swiper__btn--next-${this._uid}`
      },
      on: {
        update: () => {
          this.swiper.params.spaceBetween = this.spaceBetween
          this.swiper.update()
        }
      }
    })
  },
  destroyed () {
    this.swiper.destroy()
  }
}
</script>
