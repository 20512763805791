/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/table-time-and-load': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<rect pid="0" x=".5" y="1.5" width="19" height="19" rx=".5" _stroke="currentColor"/><rect pid="1" x="3.5" y="9.5" width="6" height="3" rx=".5" _stroke="currentColor"/><rect pid="2" x="3.5" y="14.5" width="10" height="3" rx=".5" _stroke="currentColor"/><rect pid="3" x="6.5" y="9.5" width="10" height="3" rx=".5" _stroke="currentColor"/><rect pid="4" x="10.5" y="14.5" width="6" height="3" rx=".5" _stroke="currentColor"/><rect pid="5" x="3.25" y=".25" width=".5" height="2.5" rx=".25" _fill="currentColor" _stroke="currentColor" stroke-width=".5"/><rect pid="6" x="7.25" y=".25" width=".5" height="2.5" rx=".25" _fill="currentColor" _stroke="currentColor" stroke-width=".5"/><rect pid="7" x="11.25" y=".25" width=".5" height="2.5" rx=".25" _fill="currentColor" _stroke="currentColor" stroke-width=".5"/><rect pid="8" x="15.25" y=".25" width=".5" height="2.5" rx=".25" _fill="currentColor" _stroke="currentColor" stroke-width=".5"/><path pid="9" d="M0 6h19" _stroke="currentColor"/>'
  }
})
