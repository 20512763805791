import { isNavigationFailure, NavigationFailureType } from 'vue-router/src/util/errors'
import router from '@/router'

async function goToPage (name, params) {
  await router
    .push({ name, params })
    .catch(onErrorNavigation)
}

function goToPrevPage () {
  if (router.currentRoute.path !== '/') {
    router.back()
  }
}

async function setCurrentRouteQueryPage (pageNumber) {
  await setCurrentRouteQuery({ page: pageNumber })
}

async function setCurrentRouteQuery (query) {
  await router
    .push({
      name: router.currentRoute.name,
      params: router.currentRoute.params,
      query
    })
    .catch(onErrorNavigation)
}

function onErrorNavigation (error) {
  if (isNavigationFailure(error, NavigationFailureType.duplicated)) return

  console.error(error)
}

export {
  goToPage,
  goToPrevPage,
  setCurrentRouteQuery,
  setCurrentRouteQueryPage
}
