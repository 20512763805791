/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/info': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.493 17.932a7.993 7.993 0 100-15.986 7.993 7.993 0 000 15.986zm0 1a8.993 8.993 0 100-17.986 8.993 8.993 0 000 17.986z" _fill="#697178"/><path pid="1" d="M9.975 7.428a1.17 1.17 0 01-.797-.287.95.95 0 01-.243-.312.921.921 0 010-.77c.06-.122.141-.23.243-.323a1.03 1.03 0 01.36-.214c.139-.053.284-.08.437-.08.152 0 .298.027.436.08.139.049.261.12.367.214a.955.955 0 01.243.323.862.862 0 010 .77.842.842 0 01-.243.312c-.106.09-.228.16-.367.214a1.303 1.303 0 01-.436.073zm-.963 7.007c-.453 0-.81-.118-1.074-.354-.296-.26-.444-.641-.444-1.143 0-.236.037-.509.111-.818l.963-4.014h2.044l-1.018 4.252c-.037.106-.056.24-.056.403 0 .171.044.292.132.36.092.07.24.105.443.105.139 0 .273-.027.402-.08.134-.057.256-.134.367-.232.227-.2.398-.464.513-.794h.596c-.254.631-.573 1.134-.956 1.509a2.91 2.91 0 01-2.023.806z" _fill="#697178"/>'
  }
})
