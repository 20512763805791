/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'document-ready': {
    width: 20,
    height: 22,
    viewBox: '0 0 20 22',
    data: '<mask id="svgicon_document-ready_a" fill="#fff"><rect pid="0" y="3.625" width="15.75" height="18.375" rx="1"/></mask><rect pid="1" y="3.625" width="15.75" height="18.375" rx="1" _stroke="currentColor" stroke-width="3" mask="url(#svgicon_document-ready_a)"/><path pid="2" d="M2.625 4.5V2a1 1 0 011-1h13.75a1 1 0 011 1v16.375a1 1 0 01-1 1h-2.5" _stroke="currentColor" stroke-width="1.5"/><path pid="3" d="M7 16.625L8.983 18.5l3.267-4.375M3.5 8H7M3.5 11.5h6.125" _stroke="currentColor" stroke-width="1.5" stroke-linecap="round"/>'
  }
})
