/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.611 10.456a7.156 7.156 0 11-14.311 0 7.156 7.156 0 0114.311 0zm-1.689 6.451a8.456 8.456 0 11.985-.985l3.89 3.89a.696.696 0 01-.986.984l-3.889-3.889zm.854-.834l3.88 3.879-3.88-3.88z" _fill="#7B8996"/>'
  }
})
