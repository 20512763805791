/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/add': {
    width: 18,
    height: 18,
    viewBox: '0 0 17 17',
    data: '<path pid="0" d="M13.188 10.375V16v-5.625zm-2.813 2.813H16h-5.625zm-7.5-6.563H4.75A1.875 1.875 0 006.625 4.75V2.875A1.875 1.875 0 004.75 1H2.875A1.875 1.875 0 001 2.875V4.75a1.875 1.875 0 001.875 1.875zm9.375 0h1.875A1.875 1.875 0 0016 4.75V2.875A1.875 1.875 0 0014.125 1H12.25a1.875 1.875 0 00-1.875 1.875V4.75a1.875 1.875 0 001.875 1.875zM2.875 16H4.75a1.875 1.875 0 001.875-1.875V12.25a1.875 1.875 0 00-1.875-1.875H2.875A1.875 1.875 0 001 12.25v1.875A1.875 1.875 0 002.875 16z" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
