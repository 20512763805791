import { fullName } from '@/utils/string'

function getUserListByRole (userList, roleId) {
  if (!roleId?.length) {
    return userList.filter(user => user.roles[0]?.id === roleId)
  }

  return userList.filter(user => roleId.includes(user.roles[0]?.id))
}

function getUserItem (user) {
  if (!user?.id) return null

  return {
    id: user.id,
    name: getUserFullName(user)
  }
}

function getUserFullName (user) {
  return fullName(user?.surname, user?.name)
}

export function mapUser (user) {
  return {
    ...user,
    name: getUserFullName(user)
  }
}

export {
  getUserFullName,
  getUserListByRole,
  getUserItem
}
