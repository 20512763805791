/* eslint-disable camelcase */
export default class ProductPosition {
  d_wire
  index
  index_2
  stiffness
  d_inner
  d_outer_1
  d_outer_2
  overall_length
  body_length
  perpendicularity
  gap
  reference_turn_1
  reference_turn_2
  number_turns
  length_by_hooks
  number_full_turns
  number_turn_cylinder
  number_turn_cone
  tap_length_1
  hook_type_1
  flatness
  step
  tap_length_2
  hook_type_2
  chamfer
  hook_orientation_1
  hook_length_1
  comment
  note_otk

  winding_dir
  tap_orientation
  tap_angle
  d_outer_1_limit
  d_outer_2_limit
  d_inner_limit
  linear_size_limit
  body_length_limit
  folds
  winding_type
  turns_limit
  tap_length_1_limit
  tap_length_2_limit
  hook_length_2
  step_limit
  hook_gap_1
  hook_gap_2
  hook_bend_angle

  material
  sweep_length
  hardness
  surface_treatment
  tests
  coating
  color

  constructor ({
    d_wire = null,
    index = null,
    index_2 = null,
    stiffness = null,
    d_inner = null,
    d_outer_1 = null,
    d_outer_2 = null,
    overall_length = null,
    body_length = null,
    perpendicularity = null,
    gap = null,
    reference_turn_1 = null,
    reference_turn_2 = null,
    number_turns = null,
    length_by_hooks = null,
    number_full_turns = null,
    number_turn_cylinder = null,
    number_turn_cone = null,
    tap_length_1 = null,
    hook_type_1 = null,
    flatness = null,
    step = null,
    tap_length_2 = null,
    hook_type_2 = null,
    chamfer = null,
    hook_orientation_1 = null,
    comment = null,
    note_otk = null,
    hook_length_1 = null,
    winding_dir = null,
    tap_orientation = null,
    tap_angle = null,
    d_outer_1_limit = null,
    d_outer_2_limit = null,
    d_inner_limit = null,
    linear_size_limit = null,
    body_length_limit = null,
    folds = null,
    winding_type = null,
    turns_limit = null,
    tap_length_1_limit = null,
    tap_length_2_limit = null,
    hook_length_2 = null,
    step_limit = null,
    hook_gap_1 = null,
    hook_gap_2 = null,
    hook_bend_angle = null,
    material = null,
    sweep_length = null,
    hardness = null,
    surface_treatment = null,
    tests = null,
    coating = null,
    color = null
  }) {
    this.d_wire = d_wire
    this.index = index
    this.index_2 = index_2
    this.stiffness = stiffness
    this.d_inner = d_inner
    this.d_outer_1 = d_outer_1
    this.d_outer_2 = d_outer_2
    this.overall_length = overall_length
    this.body_length = body_length
    this.perpendicularity = perpendicularity
    this.gap = gap
    this.reference_turn_1 = reference_turn_1
    this.reference_turn_2 = reference_turn_2
    this.number_turns = number_turns
    this.length_by_hooks = length_by_hooks
    this.number_full_turns = number_full_turns
    this.number_turn_cylinder = number_turn_cylinder
    this.number_turn_cone = number_turn_cone
    this.tap_length_1 = tap_length_1
    this.hook_type_1 = hook_type_1
    this.flatness = flatness
    this.step = step
    this.tap_length_2 = tap_length_2
    this.hook_type_2 = hook_type_2
    this.chamfer = chamfer
    this.hook_orientation_1 = hook_orientation_1
    this.comment = comment
    this.note_otk = note_otk
    this.hook_length_1 = hook_length_1
    this.winding_dir = winding_dir
    this.tap_orientation = tap_orientation
    this.tap_angle = tap_angle
    this.d_outer_1_limit = d_outer_1_limit
    this.d_outer_2_limit = d_outer_2_limit
    this.d_inner_limit = d_inner_limit
    this.linear_size_limit = linear_size_limit
    this.body_length_limit = body_length_limit
    this.folds = folds
    this.winding_type = winding_type
    this.turns_limit = turns_limit
    this.tap_length_1_limit = tap_length_1_limit
    this.tap_length_2_limit = tap_length_2_limit
    this.hook_length_2 = hook_length_2
    this.step_limit = step_limit
    this.hook_gap_1 = hook_gap_1
    this.hook_gap_2 = hook_gap_2
    this.hook_bend_angle = hook_bend_angle
    this.material = material
    this.sweep_length = sweep_length
    this.hardness = hardness
    this.surface_treatment = surface_treatment
    this.tests = tests
    this.coating = coating
    this.color = color
  }
}
