/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/changelog': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<path pid="0" d="M3 9h13M3 14h14M3 4h7" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
