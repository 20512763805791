import Vue from 'vue'
import setQueryParams from '@/utils/store/setQueryParams'
import { getPageCount } from '@/utils/store/pagination'

const createState = () => ({
  controller: {},
  list: [],
  item: {}
})

const createGetters = () => ({
  currentItem: state => state.item,
  currentItemId: state => state.item?.id || null
})

const createMutations = () => ({
  SET_LIST: (state, list) => {
    Vue.set(state, 'list', list)
  },
  ADD_LIST: (state, item) => {
    state.list.unshift(item)
  },
  UPDATE_LIST: (state, data) => {
    const i = state.list.findIndex(item => item.id === data.id)
    if (i === -1) return
    Vue.set(state.list, i, { ...state.list[i], ...data })
  },
  DELETE_LIST: (state, id) => {
    state.list = state.list.filter(item => item.id !== id)
  },

  SET_ITEM: (state, item) => {
    Vue.set(state, 'item', item)
  }
})

const createActions = () => ({
  async loadList ({ commit, state }) {
    try {
      commit('SET_IS_LOADING')

      const response = await state.controller.getAll(setQueryParams(state))
      if (response?.status !== 200) return

      commit('SET_LIST', response.data)

      // если есть пагинация
      if (state?.pagination?.count) {
        commit('SET_PAGE_COUNT', getPageCount(response))
      }
    } finally {
      commit('SET_IS_LOADING', false)
    }
  },
  async loadItem ({ state, commit }, id) {
    try {
      commit('SET_IS_LOADING', true)

      const response = await state.controller.getById(id)
      if (response.status !== 200) return

      commit('SET_ITEM', response.data)
    } finally {
      commit('SET_IS_LOADING', false)
    }
  },
  async createItem ({ state }, { data, params }) {
    return state.controller.create(data, params)
  },
  async updateItem ({ state }, { id, data, params }) {
    return state.controller.update(id, data, params)
  },
  async deleteItem ({ state }, id) {
    return state.controller.destroy(id)
  }
})

const vuexBaseModel = {
  createState,
  createGetters,
  createMutations,
  createActions
}

export {
  vuexBaseModel
}
