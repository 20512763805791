/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/reset': {
    width: 17,
    height: 18,
    viewBox: '0 0 17 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.828 7.456a.445.445 0 01-.451.465.49.49 0 01-.481-.465 6.99 6.99 0 10-8.373 7.314v-2.655l3.728 2.795-3.728 2.796v-1.988a7.92 7.92 0 119.305-8.262zm-4.55 1.896a.5.5 0 01.707 0l1.897 1.897 1.896-1.897a.5.5 0 01.707.707l-1.896 1.897 1.896 1.896a.5.5 0 11-.707.707l-1.896-1.896-1.897 1.896a.5.5 0 11-.707-.707l1.896-1.896-1.896-1.897a.5.5 0 010-.707z" _fill="currentColor"/>'
  }
})
