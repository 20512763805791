import { instance } from '@/api'
import { createBaseApi } from '@/api/baseApi'
import { createFileApi } from '@/api/baseFileApi'
import { throwResponseError } from '@/utils/error/throwResponseError'

const POSITION_URL = 'position/positions'
const POSITION_BY_TEMPLATE_URL = 'position/by-template'
const POSITION_BY_POSITION_URL = 'position/by-position'

export default {
  ...createBaseApi(POSITION_URL),
  ...createFileApi(POSITION_URL),

  // data = {
  //  article: string
  //  value: number
  //  order_id: number
  // }
  async createByTemplate (data) {
    try {
      return await instance.post(POSITION_BY_TEMPLATE_URL, data)
    } catch (e) {
      throwResponseError(e, 'Не удалось создать позицию по шаблону')
    }
  },

  // data = {
  //  workshop_id: number
  // }
  async patchWorkshop (id, data) {
    try {
      return await instance.patch(`/${POSITION_URL}/${id}/workshop`, data)
    } catch (e) {
      throwResponseError(e, 'Не удалось сохранить цех производства')
    }
  },

  // data = {
  //    "value": number,
  //    "position_id": number
  // }
  async copyPosition (data) {
    try {
      return await instance.post(POSITION_BY_POSITION_URL, data)
    } catch (e) {
      throwResponseError(e, 'Не удалось скопировать позицию')
    }
  }
}
