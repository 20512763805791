import { vuexHandbook } from '@/store/composition/handbook'
import { COATING_COLOR_URL, CoatingColorApi } from '@/api/handbook/position/CoatingColor/CoatingColorApi'
import { findById } from '@/utils/array/find'

const state = () => ({
  ...vuexHandbook.createState(),
  url: COATING_COLOR_URL,
  api: CoatingColorApi,
  expand: 'coating'
})

const getters = {
  ...vuexHandbook.createGetters(),
  getList: (state, _, rootState) => state.list.map(item => {
    const coating = item.coating_id ? findById(rootState.records.properties.coating, item.coating_id) : null

    return {
      ...item,
      coating,
      data: [
        { name: 'Покрытие', value: coating?.name || '-' }
      ]
    }
  })
}

const mutations = {
  ...vuexHandbook.createMutations()
}

const actions = {
  ...vuexHandbook.createActions()
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
