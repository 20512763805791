/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/comment': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<path pid="0" d="M5.542 9h7.916M5.542 6h4.75" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M2.375 15.217V3.75c0-.828.709-1.5 1.583-1.5h11.084c.874 0 1.583.672 1.583 1.5v7.5c0 .828-.709 1.5-1.583 1.5h-8.74c-.48 0-.935.207-1.236.563l-1.845 2.185c-.28.332-.846.145-.846-.28z" _stroke="currentColor"/>'
  }
})
