/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/header/production': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<rect pid="0" x=".5" y="1.5" width="19" height="17" rx=".5" _stroke="currentColor"/><rect pid="1" x="3.5" y="1.5" width="13" height="14" rx=".5" _stroke="currentColor"/><rect pid="2" x="5.5" y=".5" width="9" height="2" rx=".5" _fill="#fff" _stroke="currentColor"/><path pid="3" d="M7 6h6M7 9h6M7 12h6" _stroke="currentColor" stroke-linecap="round"/>'
  }
})
