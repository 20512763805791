import Vue from 'vue'

export const mixinFormData = {
  props: {
    value: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      formData: { ...this.value }
    }
  },
  watch: {
    value: {
      handler (val) {
        for (const key in val) {
          Vue.set(this.formData, key, val[key])
        }
      },
      deep: true,
      immediate: true
    },
    formData: {
      handler (val) {
        this.$emit('input', val)
      },
      deep: true
    }
  }
}
