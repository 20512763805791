<template>
  <VSelect
    :label="label"
    :name="name"
    :options="options"
    :multiple="isMultiple"
    :disabled="isDisabled"
    :required="isRequired"
    :allow-empty="isAllowEmpty"
  >
    <template v-slot:singleLabel="{ option }">
      <Indicator :indicator-id="option.id" />
    </template>

    <template v-slot:tag="{ option, remove }">
      <div class="multiselect__tag">
        <Indicator :indicator-id="option.id" />

        <i
          class="multiselect__tag-icon"
          aria-hidden="true"
          tabindex="1"
          @click="remove(option)"
        />
      </div>
    </template>

    <template v-slot:option="{ option }">
      <Indicator :class="isMultiple && 'pl-m'" :indicator-id="option.id" />
    </template>

    <slot />
  </VSelect>
</template>

<script>
import VSelect from '@/components/Form/VSelect.vue'
import Indicator from '../Indicator/Indicator.vue'

export default {
  components: { Indicator, VSelect },
  props: {
    label: { type: String, required: true },
    name: { type: String, required: true },
    options: { type: Array, required: true },
    isDisabled: { type: Boolean, default: false },
    isMultiple: { type: Boolean, default: false },
    isRequired: { type: Boolean },
    isAllowEmpty: { type: Boolean, default: true }
  }
}
</script>
