import Vue from 'vue'
import { mixinDefineProp } from '@/mixins/form/mixinDefineProp'

// Для работы через v-model необходимо определить props value
export const mixinInputModel = {
  inject: {
    formData: {
      from: 'formData',
      default: null
    }
  },
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  mixins: [mixinDefineProp],
  props: {
    name: {
      type: String,
      required: false
    },
    value: {}
  },
  computed: {
    modelValue: {
      get () {
        if (this.isValue) return this.value
        
        if (this.formData && this.name) {
          return this.formData[this.name]
        }

        return this.value
      },
      set (val) {
        if (this.formData && this.name) {
          Vue.set(this.formData, this.name, val)
        }
        this.$emit('changeValue', val)
      }
    }
  }
}
