/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/rollup': {
    width: 13,
    height: 9,
    viewBox: '0 0 13 9',
    data: '<path pid="0" stroke-width="2" _stroke="currentColor" d="M12 8L6.5 2 1 8"/>'
  }
})
