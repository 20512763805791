<template>
  <div class="spinner-simple">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<script>
export default {
  name: 'SpinnerSimple'
}
</script>

<style lang="scss">
@import "styles";
</style>
