/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'new': {
    width: 14,
    height: 12,
    viewBox: '0 0 14 12',
    data: '<path pid="0" d="M3.254 7.47l.497-.444c.234.261.347.61.311.958l-.3 2.973 2.734-1.204a1.25 1.25 0 011.008 0l-.302.686.302-.686 2.735 1.204-.301-2.973a1.25 1.25 0 01.311-.958l.56.5-.56-.5 1.991-2.229-2.92-.632a1.25 1.25 0 01-.816-.593L7 .99 5.496 3.572l-.648-.377.648.377a1.25 1.25 0 01-.816.593l-2.92.632 1.99 2.229-.496.444zm0 0l-.03.026.03-.026zM6.784.62h0s0 0 0 0L7 .492 6.784.62z" _stroke="#fff" stroke-width="1.5"/>'
  }
})
