import { Resolution } from '../classes/Resolution'
import { EAppColors } from '@/shared/lib/types'

export const RESOLUTIONS = [
  new Resolution('Нет решения', EAppColors.orange),
  new Resolution('Доделать', EAppColors.main),
  new Resolution('Переделать', EAppColors.main),
  new Resolution('Отклонить', EAppColors.green),
  new Resolution('Возврат средств', EAppColors.red)
]
