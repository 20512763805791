import { render, staticRenderFns } from "./VInputDate.vue?vue&type=template&id=67cbe68a&scoped=true&"
import script from "./VInputDate.vue?vue&type=script&lang=js&"
export * from "./VInputDate.vue?vue&type=script&lang=js&"
import style0 from "./VInputDate.vue?vue&type=style&index=0&id=67cbe68a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67cbe68a",
  null
  
)

export default component.exports