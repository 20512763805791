import { vuexHandbook } from '@/store/composition/handbook'
import { WindingMethodApi } from '@/api/handbook/position/WindingMethod/WindingMethodApi'
import { showAlertError } from '@/utils/store/alert'
import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'

const state = () => ({
  ...vuexHandbook.createState(),
  api: WindingMethodApi
})

const getters = {
  ...vuexHandbook.createGetters(),
  getList: (state) => {
    return state.list.map(item => {
      const workshopNameList = item.workshops?.map(item => item.name).join('; ')

      return {
        ...item,
        data: [
          { name: 'Цех производства', value: workshopNameList || '-' }
        ]
      }
    })
  }
}

const mutations = {
  ...vuexHandbook.createMutations(),

  OPEN_MODAL: (state, item) => {
    state.modal.active = true

    if (!item) return
    state.modal.updateId = item.id
    state.modal.formData.name = item.name

    if (item.workshops?.length) {
      state.modal.formData.workshop = item.workshops.map(w => ({ ...w }))
    }
  }
}

const actions = {
  ...vuexHandbook.createActions(),

  async saveItem ({ dispatch, commit, state }, data) {
    try {
      commit('SET_IS_SENDING_REQUEST_MODAL', true, { root: true })

      const preparedData = prepareFormDataIdArray(data, ['workshop'])

      state.modal.updateId ?
        await state.api.update(state.modal.updateId, preparedData)
        : await state.api.create(preparedData)

      commit('CLOSE_MODAL')
      dispatch('loadList')
    } catch (e) {
      showAlertError(e)
    } finally {
      commit('SET_IS_SENDING_REQUEST_MODAL', false, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
