/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/set-executor': {
    width: 15,
    height: 19,
    viewBox: '0 0 15 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.292 13.083a.5.5 0 01.707.02l2.69 2.843-2.69 2.844a.5.5 0 01-.727-.688l1.567-1.656H4a.5.5 0 110-1h4.839L7.272 13.79a.5.5 0 01.02-.707z" _fill="#697178"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M9.985 4.82c0 2.123-1.389 3.375-2.955 3.375S4.076 6.943 4.076 4.82 5.464 1.446 7.03 1.446c1.566 0 2.955 1.251 2.955 3.374zm-2.955 5.6c.714 0 1.393-.19 2.002-.525h1.513c1.942 0 3.515 1.88 3.515 4.199v.35h-1v-.35c0-1.941-1.285-3.2-2.515-3.2h-1.27a5.11 5.11 0 01-2.245.525c-.802 0-1.559-.192-2.244-.524h-1.27C2.285 10.895 1 12.153 1 14.094v.35H0v-.35c0-2.32 1.574-4.2 3.515-4.2h1.513a4.151 4.151 0 002.002.525zm0-1.225c2.183 0 3.955-1.767 3.955-4.375S9.213.446 7.03.446 3.076 2.212 3.076 4.82c0 2.608 1.771 4.375 3.954 4.375z" _fill="#697178"/>'
  }
})
