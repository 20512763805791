import { vuexBasePagination } from '@/store/composition/modules/query/pagination'
import { vuexBaseSearch } from '@/store/composition/modules/query/search'
import { vuexBaseFilter } from '@/store/composition/modules/query/filter'

const createState = () => ({
  expand: null,
  ...vuexBasePagination.createState(),
  ...vuexBaseSearch.createState(),
  ...vuexBaseFilter.createState()
})

const createMutations = () => ({
  ...vuexBasePagination.createMutations(),
  ...vuexBaseSearch.createMutations(),
  ...vuexBaseFilter.createMutations()
})

const createActions = () => ({
  ...vuexBasePagination.createActions(),
  ...vuexBaseSearch.createActions(),
  ...vuexBaseFilter.createActions()
})

const vuexBaseQuery = {
  createState,
  createMutations,
  createActions
}

export {
  vuexBaseQuery
}
