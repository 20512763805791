/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification-warning': {
    width: 23,
    height: 19,
    viewBox: '0 0 23 19',
    data: '<path pid="0" d="M10.82 1.708a.5.5 0 01.86 0l9.496 16.037a.5.5 0 01-.43.755H1.754a.5.5 0 01-.43-.755L10.82 1.708z" _stroke="#F90"/><path pid="1" d="M12.403 7l-.276 6.169H10.36L10.083 7h2.32zm-1.16 7.45c.35 0 .645.128.884.381.249.244.373.54.373.887 0 .357-.124.662-.373.916a1.19 1.19 0 01-.884.366 1.22 1.22 0 01-.884-.366 1.286 1.286 0 01-.359-.916c0-.347.12-.643.36-.887.248-.253.542-.38.883-.38z" _fill="#F90"/>'
  }
})
