import { CATALOG_META } from '@/router/catalog/meta'

export default [
  {
    path: '/catalog/edit',
    name: 'CatalogCategoriesEdit',
    meta: CATALOG_META,
    component: () => import('@/views/Catalog/CatalogCategoriesEdit.vue')
  },
  {
    path: '/catalog/export',
    name: 'CatalogCategoriesExport',
    meta: CATALOG_META,
    component: () => import('@/views/Catalog/CatalogCategoriesExport.vue')
  },
  {
    path: '/catalog/:categoryId?',
    name: 'Catalog',
    meta: CATALOG_META,
    component: () => import('@/views/Catalog/CatalogView.vue')
  },
  {
    path: '/catalog/:categoryId?/product/:productId?',
    name: 'ProductEdit',
    meta: CATALOG_META,
    component: () => import('@/views/Catalog/ProductEdit.vue')
  }
]
