import router from '@/router'
import home from '@/store/modules/catalog/home'
import categories from '@/store/modules/catalog/categories'
import product from '@/store/modules/catalog/product'

import CatalogFilterSettingsApi from '@/api/catalog/CatalogFilterSettingsApi'
import CatalogSidebarNavItem from '@/classes/nav/CatalogSidebarNavItem'
import CatalogBreadcrumbsNavItem from '@/classes/nav/CatalogBreadcrumbsNavItem'
import { refreshArr } from '@/utils/array/reactive'
import { findById } from '@/utils/array/find'
import { RESET_SEARCH, SET_SEARCH } from '@/store/composition/modules/query/search/mutations'
import { SET_SORT } from '@/store/composition/modules/query/sort/mutations'
import { DEFAULT_CATALOG_FILTER_SETTING } from '@/const/catalog'
import { showAlertError } from '@/utils/store/alert'
import { RESET_FILTER, SET_FILTER } from '@/store/composition/modules/query/filter/mutations'
import { SET_PAGE_COUNT, SET_PAGE } from '@/store/composition/modules/query/pagination/mutations'
import Vue from 'vue'

const state = () => ({
  breadcrumbs: [],
  filterList: [],
  exportColumnList: [
    {
      id: 1,
      name: 'Артикул',
      search_field: 'article'
    }, 
    {
      id: 2,
      name: 'Раздел',
      search_field: 'category'
    },
    {
      id: 3,
      name: 'Наименование',
      search_field: 'name'
    },
    {
      id: 4,
      name: 'd проволоки',
      search_field: 'product_d_wire'
    },
    {
      id: 5,
      name: 'Фото',
      search_field: 'files_images'
    },
    {
      id: 6,
      name: 'D наружный / внутренний',
      search_field: 'product_diameter'
    },
    {
      id: 7,
      name: 'Длина',
      search_field: 'product_length'
    },
    {
      id: 8,
      name: 'n/n1',
      search_field: 'product_n_n1'
    },
    {
      id: 9,
      name: 'Ширина',
      search_field: 'product_width'
    },
    {
      id: 10,
      name: 'Вес (кг)',
      search_field: 'product_weight'
    },
    {
      id: 11,
      name: 'Сталь',
      search_field: 'product_material'
    },
    {
      id: 12,
      name: 'Навивка',
      search_field: 'product_winding_dir'
    },
    {
      id: 13,
      name: 'Марка техники',
      search_field: 'product_tech_brand'
    },
    {
      id: 14,
      name: 'Цены',
      search_field: 'product_prices'
    },
    {
      id: 15,
      name: 'Тип пружины',
      search_field: 'spring_type'
    },
    {
      id: 16,
      name: 'Наличие на складе',
      search_field: 'in_stock'
    }
  ],

  // query
  search: '',
  sort: '-created_at',
  sort_value: { 
    id: 1,
    name: 'От новых к старым',
    value: '-created_at'
  },
  filter: {},
  filterExport: {},
  pagination: {
    page: 1,
    perPage: 10,
    count: 1
  },
  filterFormValue: {},
  filterExportFormValue: {},
  isLoading: false,
  export: {
    column: {
      article: true,
      category: true,
      name: true,
      product_d_wire: true,
      files_images: false,
      product_diameter: false,
      product_length: false,
      product_n_n1: false,
      product_width: false,
      product_weight: false,
      product_material: false,
      product_winding_dir: false,
      product_tech_brand: false,
      product_prices: false,
      spring_type: false,
      in_stock: false
    }
  }
})

const getters = {
  categoryDescription: (state, getters) => {
    if (!getters.firstCrumbId) return ''

    const category = findById(getters['categories/categoriesAll'], getters.firstCrumbId)
    return category.description
  },

  getQueryParams: (state) => () => {
    const linkParams = {
      // sort: router.currentRoute.params.categoryId ? 'product_d_wire' : state.sort,
      sort: state.sort,
      search: state.search || null,
      ...state.filter,
      page: state.pagination.page,
      'per-page': state.pagination.perPage
    }
    if (router.currentRoute.params.categoryId) {
      linkParams.category_id = router.currentRoute.params.categoryId
    }
    return linkParams
  },

  currentFilterSetting: (state, getters) => {
    let categoryFilterSettings

    if (getters.currentCategoryFilterId === 1) {
      categoryFilterSettings = state.categories.categoriesTree.filter_settings
    } else {
      const category = findById(getters['categories/categoriesAll'], getters.currentCategoryFilterId)
      categoryFilterSettings = category?.filter_settings || []
    }

    const settings = {}

    categoryFilterSettings.forEach(item => {
      const filter = findById(state.filterList, item.filter_id)

      settings[filter.search_field] = item.is_active
    })

    return {
      ...DEFAULT_CATALOG_FILTER_SETTING,
      ...settings
    }
  },
  currentCategoryFilterId: (_, getters) => getters.firstCrumbId || 1,
  firstCrumbId: state => state.breadcrumbs[0]?.id,

  getFilterIdBy: state => searchField => state.filterList.find(item => item.search_field === searchField),

  getCurrentCategoryId: () => () => +router.currentRoute.params.categoryId,

  getSortValue: (state) => state.sort_value,
  getCollumnSetting: (state) => state.export.column
}

const mutations = {
  SET_SEARCH,
  SET_SORT,
  RESET_SEARCH,
  SET_FILTER,
  RESET_FILTER,
  SET_PAGE,
  SET_PAGE_COUNT,

  SET_FILTER_EXPORT (state, filter) {
    Vue.set(state, 'filterExport', filter)
  },
  
  RESET_FILTER_EXPORT (state) {
    state.filterExport = {}
  },

  SET_BREADCRUMBS: (state, breadcrumbs) => refreshArr(state.breadcrumbs, breadcrumbs),

  SET_FILTER_LIST: (state, list) => refreshArr(state.filterList, list),

  SET_COLLUMN_SETTING: (state, list) => {
    state.export.column = list
  },

  SET_FILTER_FORM_VALUE: (state, list) => {
    state.filterFormValue = list
  },

  RESET_FILTER_FORM_VALUE: (state) => {
    state.filterFormValue = {
      tech_brand_ids: null,
      tech_type_ids: null,
      d_wires: null,
      material_ids: null,
      spring_type_ids: null,
      country_ids: null,
      product_type_ids: null,
      in_stock: null,
      with_price: null,
      is_new_at: false
    }
  },

  SET_FILTER_EXPORT_FORM_VALUE: (state, list) => {
    state.filterExportFormValue = list
  },

  RESET_FILTER_EXPORT_FORM_VALUE: (state) => {
    state.filterExportFormValue = {
      tech_brand_ids: null,
      tech_type_ids: null,
      d_wires: null,
      material_ids: null,
      spring_type_ids: null,
      country_ids: null,
      product_type_ids: null,
      in_stock: null,
      with_price: null,
      is_new_at: false
    }
  },
  SET_IS_LOADING (state, loading) {
    state.isLoading = loading
  },

  SET_SORT_VALUE (state, sort) {
    state.sort_value = sort
  }
}

const actions = {
  getCatalogSidebarAndSetBreadcrumbsNav ({ commit, dispatch, getters }) {
    const categoryId = getters.getCurrentCategoryId()

    if (categoryId) {
      return dispatch('getCategorySidebarAndSetBreadcrumbsNav', categoryId)
    }

    commit('SET_BREADCRUMBS', [])
    return dispatch('getRootSidebarNav')
  },

  async getRootSidebarNav ({ state, getters, dispatch }) {
    dispatch('refreshRoot')

    const sidebarNav = [state.categories.root]

    for (const category of getters['categories/categoriesAll']) {
      sidebarNav.push(new CatalogSidebarNavItem({
        label: category.name,
        id: category.id,
        count: category.products_count
      }))
    }

    return sidebarNav
  },

  refreshRoot ({ commit, state }) {
    if (state.categories.root) {
      return commit('categories/updateRootCount')
    }

    commit('categories/createRoot')
  },

  async getCategorySidebarAndSetBreadcrumbsNav ({ state, commit, getters, dispatch }, findId) {
    const breadcrumbs = []
    let currentCategory = null

    findCurrentCategoryAndSetBreadcrumbs(getters['categories/categoriesAll'], 1)

    if (!currentCategory) return []
    const mappedBreadcrumbs = breadcrumbs.map(crumb => new CatalogBreadcrumbsNavItem(crumb))
    commit('SET_BREADCRUMBS', mappedBreadcrumbs)

    const sidebarNav = [
      new CatalogSidebarNavItem({
        label: currentCategory.name,
        id: currentCategory.id,
        parent_id: currentCategory.parent_id,
        count: currentCategory.products_count
      })
    ]

    for (const category of currentCategory.children) {
      sidebarNav.push(new CatalogSidebarNavItem({
        label: category.name,
        id: category.id,
        parent_id: category.parent_id,
        count: category.products_count
      }))
    }

    return sidebarNav

    function findCurrentCategoryAndSetBreadcrumbs (categories, lvl) {
      const indexCrumb = lvl - 1

      for (const category of categories) {
        if (currentCategory) break

        if (category.parent_id === 1) {
          breadcrumbs.length = 0
        }

        if (breadcrumbs.length > lvl) {
          breadcrumbs.length = lvl
        }

        if (breadcrumbs[indexCrumb]) {
          breadcrumbs[indexCrumb] = category
        } else {
          breadcrumbs.push(category)
        }

        if (category.id === findId) {
          currentCategory = category
          break
        }

        findCurrentCategoryAndSetBreadcrumbs(category.children, lvl + 1)
      }
    }
  },

  async fetchAndSetFilters ({ commit }) {
    try {
      const response = await CatalogFilterSettingsApi.get()
      commit('SET_FILTER_LIST', response.data)
    } catch (e) {
      showAlertError(e)
    }
  },

  async updateSectionFilterSetting ({ getters, commit }, data) {
    const id = getters.currentCategoryFilterId
    const response = await CatalogFilterSettingsApi.update(id, data)

    commit('categories/updateFilterSettings', { data: response.data, id })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules: {
    home,
    categories,
    product
  }
}
