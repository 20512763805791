/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/arrow-pagination': {
    width: 17,
    height: 13,
    viewBox: '0 0 17 13',
    data: '<path pid="0" d="M16.023 5.968l-14.024.079m0 0L7.225.973M2 6.047l5.356 5.516" _stroke="#000" stroke-width="1.5"/>'
  }
})
