/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/weight': {
    width: 14,
    height: 21,
    viewBox: '0 0 14 21',
    data: '<rect pid="0" x="1.5" y=".5" width="11" height="3" rx="1.5" _stroke="currentColor"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M4 4v1.5a.5.5 0 01-.5.5H2a2 2 0 00-2 2v11a2 2 0 002 2h10a2 2 0 002-2V8a2 2 0 00-2-2h-1.5a.5.5 0 01-.5-.5V4H9v2a1 1 0 001 1h2a1 1 0 011 1v11a1 1 0 01-1 1H2a1 1 0 01-1-1V8a1 1 0 011-1h2a1 1 0 001-1V4H4z" _fill="currentColor"/><path pid="2" d="M6.952 10.675L5.335 13.14 7.144 16H6.042l-1.475-2.456H3.89V16h-.93v-5.325h.93v2.142h.697l1.344-2.143h1.02zM9.13 16H8.2v-5.325h3.325l-.122.768H9.13V16z" _fill="currentColor"/>'
  }
})
