/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/header/reclamation': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<circle pid="0" cx="10.5" cy="10.5" r="10" _stroke="currentColor"/><path pid="1" d="M7 11h7" _stroke="currentColor" stroke-linecap="round"/>'
  }
})
