import adminMeta from './adminMeta'

export default [
  {
    path: 'directories',
    name: 'DirectoriesList',
    meta: adminMeta,
    component: () => import('@/views/AdminPanel/Handbook/Handbook.vue')
  },
  {
    path: 'handbook/:handbookName',
    name: 'HandbookUpdate',
    meta: adminMeta,
    component: () => import('@/views/AdminPanel/Handbook/HandbookUpdate'),
    props: true
  }
]
