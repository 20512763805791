<template>
  <span v-html="getText"/>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: false,
      default: ''
    },
    count: {
      type: [Number, String],
      required: false,
      default: null
    }
  },
  computed: {
    getText () {
      if (this.count || this.count === 0) {
        return this.text + ` (${this.count})`
      }
      return this.text
    }
  }
}
</script>
