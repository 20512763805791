/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file': {
    width: 17,
    height: 18,
    viewBox: '0 0 17 18',
    data: '<mask id="svgicon_file_a" fill="#fff"><path pid="0" d="M0 2a2 2 0 012-2h10l5 5v11a2 2 0 01-2 2H2a2 2 0 01-2-2V2z"/></mask><path pid="1" d="M17 5h1v-.414l-.293-.293L17 5zm-5-5l.707-.707L12.414-1H12v1zm3 17H2v2h13v-2zM1 16V2h-2v14h2zM2 1h10v-2H2v2zm9.293-.293l5 5 1.414-1.414-5-5L11.293.707zM16 5v11h2V5h-2zM2 17a1 1 0 01-1-1h-2a3 3 0 003 3v-2zm13 2a3 3 0 003-3h-2a1 1 0 01-1 1v2zM1 2a1 1 0 011-1v-2a3 3 0 00-3 3h2z" _fill="currentColor" mask="url(#svgicon_file_a)"/><path pid="2" d="M11 6V0l6 6h-6z" _fill="currentColor"/>'
  }
})
