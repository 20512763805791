/* eslint-disable camelcase */
import BaseModel from '@/classes/BaseModel'
import { generateId } from '@/utils/create'
import { formatRUB } from '@/utils/format/number'

export default class ProductCoatingPriceCategory extends BaseModel {
  cost_coating
  cost_not_coating

  constructor ({
    id = generateId(),
    name = '',
    cost_not_coating = null,
    cost_coating = null
  }) {
    super({ id, name })
    this.cost_coating = cost_coating
    this.cost_not_coating = cost_not_coating
  }

  get costCoating () {
    if (!this.cost_coating) return ''
    if (!Number(this.cost_coating)) return this.cost_coating
    return formatRUB(this.cost_coating)
  }

  get costNotCoating () {
    if (!this.cost_not_coating) return ''
    if (!Number(this.cost_not_coating)) return this.cost_not_coating
    return formatRUB(this.cost_not_coating)
  }
}
