export const ELabels = {
  sender: 'Отправитель',
  recipient: 'Получатель',
  TIN: 'ИНН/Серия,№',
  payer: 'Плательщик',
  delivery: 'Доставка',
  before: 'До',
  address: 'Адрес',
  phone: 'Телефон получателя',
  order_account_number: 'Совместная отгрузка с заказом №',
  comment: 'Комментарий к отгрузке',
  payment_method: 'Способ оплаты',
  account_double: 'Дубль счета'
}
