import {
  SET_PAGE,
  SET_PAGE_COUNT,
  SET_PER_PAGE
} from '@/store/composition/modules/query/pagination/mutations'

const createState = () => ({
  pagination: {
    page: 1,
    perPage: 10,
    count: 1
  }
})

const createMutations = () => ({
  SET_PAGE,
  SET_PAGE_COUNT,
  SET_PER_PAGE
})

const createActions = () => ({
  async loadPage ({ commit, dispatch }, page) {
    commit('SET_PAGE', page)
    await dispatch('loadList')
  }
})

const vuexBasePagination = {
  createState,
  createMutations,
  createActions
}

export {
  vuexBasePagination
}
