const SET_PER_PAGE = (state, num) => {
  state.pagination.perPage = num
}

const SET_PAGE = (state, num) => {
  state.pagination.page = num
}

const SET_PAGE_COUNT = (state, num) => {
  state.pagination.count = num
}

export {
  SET_PAGE,
  SET_PAGE_COUNT,
  SET_PER_PAGE
}
