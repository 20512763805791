import adminMeta from './adminMeta'

export default [
  {
    path: 'users',
    name: 'Users',
    meta: adminMeta,
    component: () => import('@/views/AdminPanel/Users/Users.vue')
  },
  {
    path: 'users/create',
    name: 'CreateUser',
    meta: adminMeta,
    component: () => import('@/views/AdminPanel/Users/CreateAndUpdateUser.vue'),
    props: { action: 'create' }
  },
  {
    path: 'users/:id/update',
    name: 'UpdateUser',
    meta: adminMeta,
    component: () => import('@/views/AdminPanel/Users/CreateAndUpdateUser.vue'),
    props: { action: 'update' }
  },
  {
    path: 'users/:id/detail',
    name: 'DetailUser',
    meta: adminMeta,
    component: () => import('@/views/AdminPanel/Users/CreateAndUpdateUser.vue'),
    props: { action: 'detail' }
  }
]
