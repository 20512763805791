import TemplateStageLineController from '@/controllers/production/Template/TemplateStageLineController'
import { vuexProductionTemplate } from '@/store/composition/productionTemplate'

const state = () => ({
  ...vuexProductionTemplate.createState(),
  controller: TemplateStageLineController
})
const getters = {
  ...vuexProductionTemplate.createGetters()
}

const mutations = {
  ...vuexProductionTemplate.createMutations()
}

const actions = {
  ...vuexProductionTemplate.createActions()
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
