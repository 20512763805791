/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'status-start': {
    width: 23,
    height: 24,
    viewBox: '0 0 23 24',
    data: '<path pid="0" d="M.5 1A.5.5 0 011 .5h19.97a.5.5 0 01.395.805L17.323 6.56a1.5 1.5 0 000 1.83l4.042 5.255a.5.5 0 01-.396.805H1a.5.5 0 01-.5-.5V1zM.575 1.725V23M8.05 23H0M16.675 7.475H.575" _stroke="currentColor"/>'
  }
})
