<template>
  <InfoText
    :label="ELabels.orderNumber"
  >
    <router-link
      class="link-reset v-link hover--main"
      :to="{ name: 'OrderDetails', params: { id: orderId } }"
    >
      {{ accountNumber }}
    </router-link>
  </InfoText>
</template>

<script>
import { ELabels } from '../../model/types'
import InfoText from '@/components/simple/text/InfoText.vue'

export default {
  components: { InfoText },
  props: {
    orderId: {
      type: Number,
      required: true
    },
    accountNumber: {
      type: String,
      required: true
    }
  },
  computed: {
    ELabels: () => ELabels
  }
}
</script>
