/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/arrow-next': {
    width: 17,
    height: 9,
    viewBox: '0 0 16 9',
    data: '<path pid="0" d="M5 4.5h11.196m0 0l-4.412-4m4.412 4l-4.412 4" _stroke="#fff" stroke-linecap="round"/><path pid="1" d="M5 4.5c-.088 1.114-.971 2-2.04 2-1.067 0-1.95-.886-1.95-2 0-1.114.883-2 1.95-2 1.069 0 1.952.886 1.952 2z" _fill="#fff" _stroke="#fff"/>'
  }
})
