<template>
  <div class="semi-bold" :class="classColor">{{ text }}</div>
</template>

<script>
import { RESOLUTIONS } from '../../model/const/resolutions'

export default {
  props: {
    resolutionId: {
      type: Number,
      required: true
    }
  },
  computed: {
    text () {
      return this.currentResolution.name
    },

    classColor () {
      return `color--${this.currentResolution.color}`
    },

    currentResolution () {
      return RESOLUTIONS[this.resolutionId - 1]
    }
  }
}
</script>
