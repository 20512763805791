var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.navItem.hidden)?_c('router-link',{attrs:{"custom":"","to":{ name: _vm.navItem.routeName, params: _vm.navItem.routeParams || {} }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
return [_c('li',{staticClass:"nav-item",class:[
      isActive && 'router-link-active',
      _vm.isDisabled && 'disabled',
      _vm.block + '-item'
    ],on:{"click":navigate}},[_c('a',{staticClass:"nav-item__link",class:_vm.block + '-item__link',attrs:{"href":href},on:{"click":function($event){_vm.isResetFilter ? _vm.resetFilter() : ''}}},[(_vm.navItem.icon)?_c('svgicon',{staticClass:"nav-item__icon",class:_vm.block + '-item__icon',attrs:{"icon":_vm.iconPrefix + _vm.navItem.icon,"width":_vm.iconSize.width,"height":_vm.iconSize.height}}):_vm._e(),_c('TextCount',{staticClass:"nav-item__text",class:_vm.block + '-item__text',attrs:{"text":_vm.navItem.label,"count":_vm.navItem.count}})],1)])]}}],null,false,830972452)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }