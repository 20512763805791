/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/production-template': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1 0h5v1H1v5.5H0V0h1zM0 14.5V20h6v-1H1v-4.5H0zm19 0V19h-5v1h6v-5.5h-1zm1-8V0h-6v1h5v5.5h1z" _fill="currentColor"/><rect pid="1" x="4.5" y="4.5" width="11" height="11" rx=".5" _stroke="currentColor"/><path pid="2" d="M7 8.5h6M7 11h6" _stroke="currentColor" stroke-linecap="round"/>'
  }
})
