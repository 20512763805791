<template>
  <BaseModal
    class="modal--md"
    title="Решение принято."
    @close="close"
  >
    <p class="text-center mb-30">
      Перейди в реестр производства и запусти карточку заказа в работу
    </p>

    <div class="buttons">
      <router-link
        class="btn btn--primary btn-reset text-center"
        :to="{ name: 'OrderDetails', params: { id: reclamation.reclamation_order_id } }"
      >
        Перейти в карточку заказа
      </router-link>

      <VButton
        text="Закрыть и продолжить позже"
        @click.native="close"
      />
    </div>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/ui/Modal/BaseModal.vue'
import VButton from '@/components/simple/button/VButton.vue'
import { reclamationExternalModel } from '@/entities/reclamation-external'

export default {
  components: { VButton, BaseModal },
  props: {
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal,
      required: true
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>
