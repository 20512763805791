import Vue from 'vue'
import UserController from '@/controllers/user/UserController'
import { vuexAll } from '@/store/composition/modules/model/all'
import { vuexBaseModel } from '@/store/composition/modules/model'
import { vuexBaseLoading } from '@/store/composition/modules/loading'
import { vuexBaseQuery } from '@/store/composition/modules/query'
import { getUserListByRole, mapUser } from '@/utils/model/User'
import { sortByName } from '@/utils/array/sort'

const state = () => ({
  ...vuexAll.createState(),
  ...vuexBaseLoading.createState(),
  ...vuexBaseModel.createState(),
  ...vuexBaseQuery.createState(),

  controller: UserController,
  // query
  allQuery: {
    'per-page': 5000,
    sort: 'id'
  },
  pagination: {
    perPage: 5,
    page: 1,
    count: 1
  }
})
const getters = {
  ...vuexBaseModel.createGetters(),
  // фильтр юзеров по ролям
  executorList: state => {
    return getUserListByRole(state.all, [6, 9, 10, 11])
  },
  stageResponsibleList: state => {
    return getUserListByRole(state.all, [4, 6, 7, 8, 9, 11])
  },
  productionMasterList: state => {
    return getUserListByRole(state.all, 5)
  },
  processEngineerList: state => {
    return getUserListByRole(state.all, 4)
  },
  salesManagerList: state => {
    return getUserListByRole(state.all, 3)
  }
}
const mutations = {
  ...vuexAll.createMutations(),
  ...vuexBaseModel.createMutations(),
  ...vuexBaseQuery.createMutations(),
  ...vuexBaseLoading.createMutations(),

  SET_ALL: (state, data) => {
    const users = data
      .map(mapUser)
      .sort(sortByName)

    Vue.set(state, 'all', users)
  }
}

const actions = {
  ...vuexAll.createActions(),
  ...vuexBaseModel.createActions(),
  ...vuexBaseQuery.createActions(),

  async userDeactivate ({ state, commit }, id) {
    const res = await state.controller.deactivate(id)
    if (res?.status !== 204) return res

    commit('DELETE_ALL', id)
    return res
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
