<template>
  <BaseModal
    class="modal-form"
    :title="title"
    @close="onReject"
  >
    <VForm v-model="formData" @request="onSubmit">
      <slot/>

      <ButtonGroup
        :btn-size="buttonSize"
        :only-btn-submit="onlyBtnSubmit"

        :textBtnConfirm="textBtnConfirm"
        :loading="$store.state.isSendingRequestModal"

        :textBtnReject="textBtnReject"
        @reject="onReject"
      />
    </VForm>
  </BaseModal>
</template>

<script>
import BaseModal from '@/components/ui/Modal/BaseModal'
import ButtonGroup from '@/components/ui/ButtonGroup/ButtonGroup'
import VForm from '@/components/Form/VForm'

import { mixinButtonGroup } from '@/mixins/button/mixinButtonGroup'
import { mixinFormData } from '@/mixins/form/mixinFormData'

export default {
  name: 'ModalForm',
  components: {
    VForm,
    BaseModal,
    ButtonGroup
  },
  mixins: [mixinButtonGroup, mixinFormData],
  props: {
    title: {
      type: String,
      required: false,
      default: ''
    },
    textBtnConfirm: {
      type: String,
      required: false,
      default: 'Сохранить'
    },
    textBtnReject: {
      type: String,
      required: false,
      default: 'Отмена'
    },
    btnSize: {
      type: String,
      required: false,
      default: ''
    }
  },

  computed: {
    buttonSize () {
      if (this.btnSize) return this.btnSize

      return this.onlyBtnSubmit ? 'l' : '100'
    }
  },

  methods: {
    onSubmit (data) {
      this.$emit('request', data)
    }
  }
}
</script>
