<template>
  <div class="column gap-s">
    <div class="flex gap-s">
      <Indicator :indicator-id="reclamation.indicator" />

      <InfoText
        label="Статус рекламации"
        :text="getReclamationStatusName(reclamation.status)"
      />
    </div>

    <div class="d-grid d-grid--columns-2 gap-s">
      <LinkToOrder
        :account-number="reclamation.account_number"
        :order-id="reclamation.order_id"
      />

      <AppInfoTextBlock
        :label-text="ELabels.disk"
        :link="reclamation.google_link"
        :text="reclamation.google_link"
      />

      <InfoText
        :label="ELabels.positionNumber"
        :text="reclamation.position_number"
      />

      <AppInfoTextBlock
        :label-text="ELabels.dealAtCRM"
        :link="reclamation.crm_link"
        :text="reclamation.crm_link"
      />

      <AppInfoTextBlock
        :label-text="ELabels.company"
        :text="reclamation.client_name"
      />

      <InfoText
        :label="ELabels.dateCreated"
        :text="dateCreated"
      />

      <InfoText
        :label="ELabels.responsibleManager"
        :text="reclamation.responsible"
        is-handbook
      />

      <InfoText
        v-if="reclamation.started_at"
        :label="ELabels.dateInvestigation"
        :text="dateStarted"
      />
    </div>
    <div class="d-grid d-grid--columns-2 gap-s">
      <AppInfoTextBlock
        :label-text="ELabels.reasonPetition"
        class="info--column"
        :text="reclamation.reclamation_reason_treatment && reclamation.reclamation_reason_treatment.name"
        :has-tooltip="false"
      />
      <InfoText
        v-if="reclamation.reclamation_order_id"
        :label="ELabels.reclamationProductionRegister"
      >
        <router-link
          class="link-reset v-link hover--main"
          :to="{ name: 'OrderDetails', params: { id: reclamation.reclamation_order_id } }"
        >
          {{ reclamation.reclamation_order.account_number }}
        </router-link>
      </InfoText>
    </div>
  </div>
</template>

<script>
import AppInfoTextBlock from '@/components/ui/AppInfoTextBlock.vue'
import Indicator from '../Indicator/Indicator.vue'
import LinkToOrder from '../LinkToOrder/LinkToOrder.vue'
import InfoText from '@/components/simple/text/InfoText.vue'
import { ELabels } from '../../model/types'
import { ReclamationExternal } from '../../model/classes/ReclamationExternal'
import { getStatusNameBy } from '../../model/utils'

export default {
  components: {
    InfoText,
    Indicator,
    AppInfoTextBlock,
    LinkToOrder
  },
  props: {
    reclamation: {
      type: ReclamationExternal,
      required: true
    }
  },
  computed: {
    ELabels: () => ELabels,
    dateCreated () {
      return this.reclamation.getCreatedAt()
    },
    dateStarted () {
      return this.reclamation.getStartedAt()
    },
    getReclamationStatusName: () => (id) => getStatusNameBy(id)
  }
}
</script>
