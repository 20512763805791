const warehouseMeta = {
  layout: 'main',
  availableName: 'isWarehouse',
  auth: true
}

export default [
  {
    path: '/warehouse',
    name: 'Warehouse',
    meta: warehouseMeta,
    redirect: { name: 'WarehouseMaterials' },
    component: () => import('@/views/Warehouse/Warehouse.vue'),
    children: [
      {
        path: 'materials',
        name: 'WarehouseMaterials',
        meta: warehouseMeta,
        component: () => import('@/views/Warehouse/MaterialList.vue')
      },
      {
        path: 'materials/:id_material',
        name: 'WarehouseMaterialDetailed',
        meta: warehouseMeta,
        component: () => import('@/views/Warehouse/MaterialDetailed.vue'),
        props: true
      },

      {
        path: 'reserve',
        name: 'WarehouseReserve',
        meta: warehouseMeta,
        component: () => import('@/views/Warehouse/Reserve.vue')
      }
    ]
  }
]
