/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/refresh': {
    width: 19,
    height: 19,
    viewBox: '0 0 19 19',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.873 15.92l-1.727 1.726.708.708 2.5-2.5.322-.323-.292-.351-2.5-3-.768.64 1.782 2.138A7.045 7.045 0 1117.045 8H18a8.007 8.007 0 00-1.348-4.445 8 8 0 10-7.78 12.365z" _fill="currentColor"/>'
  }
})
