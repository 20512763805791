/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/header/media-files': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<rect pid="0" x="5.5" y="5.5" width="14" height="12" rx=".5" _stroke="currentColor"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M1 1h13v4h1V1a1 1 0 00-1-1H1a1 1 0 00-1 1v11a1 1 0 001 1h4v-1H1V1z" _fill="currentColor"/><path pid="2" d="M14.764 11.044a.5.5 0 010 .912l-3.31 1.486a.5.5 0 01-.704-.456v-2.972a.5.5 0 01.705-.456l3.309 1.486z" _stroke="currentColor"/>'
  }
})
