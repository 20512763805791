import productionMeta from '@/router/production/productionMeta'

export default [
  {
    path: 'consignment-list',
    name: 'ConsignmentList',
    meta: productionMeta,
    redirect: { name: 'ConsignmentListItem' },
    component: () => import('@/views/Production/Order/ConsignmentList/ConsignmentList.vue'),
    children: [
      {
        path: 'progress',
        name: 'ConsignmentListItem',
        meta: productionMeta,
        component: () => import('@/views/Production/Order/ConsignmentList/ConsignmentListItem.vue')
      },
      {
        path: 'ready',
        name: 'ReadyConsignmentList',
        meta: productionMeta,
        component: () => import('@/views/Production/Order/ConsignmentList/ConsignmentListItem.vue')
      },
      {
        path: 'shipping',
        name: 'ShippingConsignmentList',
        meta: productionMeta,
        component: () => import('@/views/Production/Order/ConsignmentList/ConsignmentListItem.vue')
      },
      {
        path: 'archive',
        name: 'ArchiveConsignmentList',
        meta: productionMeta,
        component: () => import('@/views/Production/Order/ConsignmentList/ConsignmentListItem.vue')
      }
    ]
  }
]
