/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'user-avatar': {
    width: 25,
    height: 25,
    viewBox: '0 0 25 25',
    data: '<circle pid="0" cx="12.5" cy="12.5" r="12" _stroke="currentColor"/><path pid="1" d="M12.5 12.333c2.019 0 3.656-1.682 3.656-4.166C16.156 5.682 14.52 4 12.5 4S8.844 5.682 8.844 8.167c0 2.484 1.637 4.166 3.656 4.166zm3.25.667h-1.399c-.564.319-1.19.5-1.851.5-.66 0-1.285-.181-1.851-.5H9.25C7.455 13 6 14.79 6 17v.5c0 .828.546 1.5 1.219 1.5H17.78c.673 0 1.219-.672 1.219-1.5V17c0-2.21-1.455-4-3.25-4z" _fill="currentColor"/>'
  }
})
