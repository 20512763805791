import { refreshArr } from '@/utils/array/reactive'
import { UserRoleApi } from '@/api/handbook/user/UserRole/UserRoleApi'
import { showAlertError } from '@/utils/store/alert'

const state = () => ({
  userRoles: [],
  queryParams: {
    'per-page': 1000
  }
})

const mutations = {
  SET_USER_ROLES: (state, roles) => refreshArr(state.userRoles, roles)
}

const actions = {
  async loadUserRoles ({ commit, state }) {
    try {
      const response = await UserRoleApi.getAll(state.queryParams)
      commit('SET_USER_ROLES', response.data)
    } catch (e) {
      showAlertError(e)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
