import { CATALOG_URL } from '@/api/catalog/index'
import { configMultipart, instance } from '@/api'
import { throwResponseError } from '@/utils/error/throwResponseError'
import { deleteFile } from '@/api/request'

const CATALOG_HOME_URL = `${CATALOG_URL}/home`

const errors = {
  getHome: 'Не удалось загрузить главную страницу каталога',
  updateHome: 'Не удалось загрузить главную страницу каталога',
  deleteHomeFile: 'Не удалось удалить изображение с сервера'
}

export default {
  get: async () => {
    try {
      return await instance.get(CATALOG_HOME_URL)
    } catch (e) {
      throwResponseError(e, errors.getHome)
    }
  },

  update: async (data) => {
    try {
      return await instance.post(CATALOG_HOME_URL, data, configMultipart)
    } catch (e) {
      throwResponseError(e, errors.updateHome)
    }
  },

  deleteFile: async (id) => {
    try {
      return await deleteFile(id)
    } catch (e) {
      throwResponseError(e, errors.deleteHomeFile)
    }
  }
}
