import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'

import production from '@/router/production'
import warehouse from '@/router/warehouse'
import analytics from '@/router/analytics'
import admins from '@/router/admin-panel'
import { routes as loadCalendar } from '@/views/LoadCalendar'
import mediaFiles from '@/router/media-files'
import { reclamationRoutes } from '@/views/Reclamation'
import catalog from '@/router/catalog'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    name: 'NotFound',
    meta: {
      layout: 'empty',
      auth: true
    },
    component: () => import('@/views/PageNotFound.vue')
  },
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      layout: 'empty',
      auth: false
    },
    component: () => import('@/views/NewAuth.vue')
  },
  {
    path: '/',
    redirect: { name: 'Order' }
  },
  {
    path: '/home',
    redirect: { name: 'Order' }
  },
  {
    path: '/UI',
    name: 'UIKit',
    meta: {
      layout: 'main',
      auth: true
    },
    component: () => import('@/views/UI.vue')
  },
  ...admins,
  ...production,
  ...catalog,
  ...warehouse,
  ...analytics,
  ...reclamationRoutes,
  ...loadCalendar,
  ...mediaFiles
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requireAuth = to.meta.auth

  if (requireAuth && store.getters.isAuthenticated) {
    next()
  } else if (requireAuth && !store.getters.isAuthenticated) {
    next('/auth?message=auth')
  } else {
    next()
  }
})

export default router
