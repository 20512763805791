<template>
  <router-link
    class="link-page-back link-reset"
    :to="{ name: routeName, params: routeParams }"
  >
    <svgicon
      icon="button/arrow-back"
      class="svg-icon--arrow-back"
      width="14"
      height="10"
    />

    {{ text }}
  </router-link>
</template>

<script>
export default {
  name: 'LinkPageBack',
  props: {
    text: {
      type: String,
      required: false,
      default: 'Назад'
    },
    routeName: {
      type: String,
      required: true
    },
    routeParams: {
      type: Object,
      required: false,
      default: () => {}
    }
  }
}
</script>
