import productionMeta from '@/router/production/productionMeta'
import position from '@/router/production/template/position'
import stageLine from '@/router/production/template/stageLine'

export default [
  {
    path: 'template',
    name: 'ProductionTemplate',
    meta: productionMeta,
    redirect: { name: 'TemplatePositionList' },
    component: () => import('@/views/Production/Template/Template.vue'),
    children: [
      ...position,
      ...stageLine
    ]
  }
]
