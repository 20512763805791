import productionMeta from '@/router/production/productionMeta'

export default [
  {
    path: 'position',
    name: 'TemplatePositionList',
    meta: productionMeta,
    component: () => import('@/views/Production/Template/TemplatePositionList.vue')
  },
  {
    path: 'position/create',
    name: 'TemplatePositionCreate',
    meta: productionMeta,
    component: () => import('@/views/Production/Template/TemplatePosition.vue'),
    props: { action: 'create' }
  },
  {
    path: 'position/:id/update',
    name: 'TemplatePositionUpdate',
    meta: productionMeta,
    component: () => import('@/views/Production/Template/TemplatePosition.vue'),
    props: { action: 'update' }
  },
  {
    path: 'position/:id/detail',
    name: 'TemplatePositionDetail',
    meta: productionMeta,
    component: () => import('@/views/Production/Template/TemplatePosition.vue'),
    props: { action: 'detail' }
  }
]
