import { SET_FILTER } from '@/store/composition/modules/query/filter/mutations'

const createState = () => ({
  filter: {}
})

const createMutations = () => ({
  SET_FILTER
})

const createActions = () => ({
  async loadFilter ({ commit, dispatch }, filter = {}) {
    commit('SET_PAGE', 1)
    commit('SET_FILTER', filter)
    await dispatch('loadList')
  }
})

const vuexBaseFilter = {
  createState,
  createMutations,
  createActions
}

export {
  vuexBaseFilter
}
