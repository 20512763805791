<template>
  <a :href="link"
     :title="title"
     :class="['link', { link_dashed: dashed }]"
     target="_blank"
  >{{ text.toString() | stringMaxLength(length) }}</a>
</template>

<script>
export default {
  name: 'AppLink',
  props: {
    title: String,
    link: [String, Number],
    text: {
      type: String
    },
    dashed: Boolean,
    disabled: Boolean,
    length: [String, Number]
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
