import { route as indexRoute } from './route'
import { route as listRoute } from './List'
import { route as createRoute } from './Create'
import { route as detailsRoute } from './Details'
import { route as changelogRoute } from './Changelog'
import { route as reportsRoute } from './Reports'
import { reclamationExternalModel } from '@/entities/reclamation-external'

const meta = {
  layout: 'main',
  availableName: 'isReclamation',
  auth: true
}

indexRoute.meta = meta
indexRoute.redirect = { name: listRoute.name, params: { status: reclamationExternalModel.STATUSES[0].value } }

listRoute.meta = meta
createRoute.meta = meta
detailsRoute.meta = meta
changelogRoute.meta = meta
reportsRoute.meta = meta

export const routes = [
  indexRoute,
  listRoute,
  createRoute,
  detailsRoute,
  changelogRoute,
  reportsRoute
]
