<template>
  <component :is="layout" />
</template>

<script>
import axios from 'axios'

import MainLayout from '@/layouts/MainLayout'
import EmptyLayout from '@/layouts/EmptyLayout'

require('flatpickr/dist/flatpickr.css')
require('vue-multiselect/dist/vue-multiselect.min.css')

export default {
  components: {
    MainLayout,
    EmptyLayout
  },
  computed: {
    layout () {
      return (this.$route.meta.layout || 'empty') + '-layout'
    }
  },
  async created () {
    axios.interceptors.response.use(undefined, async (err) => {
      if (err.response.status === 401) {
        await this.$store.dispatch('logout')
        await this.$router.push({ name: 'Auth' })
        await window.location.reload()
      }
    })

    if (this.$store.getters.currentUser) {
      this.$store.commit('permission/SET_PERMISSION_STATE', this.$store.getters.currentUser)
    }
  }
}
</script>
