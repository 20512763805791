export class Indicator {
  static counter = 1
  name = ''
  color = ''

  constructor (name, color) {
    this.name = name
    this.color = color
    this.id = Indicator.counter
    Indicator.incrementCounter()
  }

  static incrementCounter () {
    Indicator.counter++
  }
}
