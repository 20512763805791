import { CATALOG_URL } from '@/api/catalog/index'
import { instance } from '@/api'
import { throwResponseError } from '@/utils/error/throwResponseError'

const CATALOG_FILTER_SETTINGS_URL = `${CATALOG_URL}/filter-settings`

const errors = {
  get: 'Не удалось загрузить список фильтров',
  update: 'Не удалось сохранить настройки фильтра'
}

export default {
  get: async () => {
    try {
      return await instance.get(CATALOG_FILTER_SETTINGS_URL)
    } catch (e) {
      throwResponseError(e, errors.get)
    }
  },

  // data = [{ filter_id, is_active }, ...]
  update: async (id, data) => {
    try {
      return await instance.post(`${CATALOG_FILTER_SETTINGS_URL}/${id}`, data)
    } catch (e) {
      throwResponseError(e, errors.update)
    }
  }
}
