import { deleteFiles as deleteFileApi } from '@/api/baseFileApi'
import { showAlertError } from '@/utils/store/alert'
import { sortFiles } from '@/api/request'

export async function deleteFiles ({ state, commit }) {
  if (!state.filesToDelete.length) return

  try {
    await deleteFileApi(state.filesToDelete)
  } catch (e) {
    showAlertError(e)
  } finally {
    commit('RESET_FILES_TO_DELETE')
  }
}

export async function updateFilesSort (_, ids) {
  try {
    await sortFiles(ids)
  } catch (e) {
    showAlertError(e)
  }
}
