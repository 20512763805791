import { instance } from '@/api'

const PRODUCTION_CALENDAR_URL = 'dict/calendar'

export default {
  get: (year = null) => instance.get(PRODUCTION_CALENDAR_URL, { params: { year } }),
  updateWeekends: (weekends) => instance.post(PRODUCTION_CALENDAR_URL, { weekends }),
  toDefault: (year = null) => {
    return instance.post(`${PRODUCTION_CALENDAR_URL}/default`, { year })
  }
}
