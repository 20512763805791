<template>
  <transition-group
    v-if="show"
    class="alert-list"
    name="alert-list"
    tag="div"
  >
    <AlertCard
      v-for="alert of alerts"
      :key="alert.id"
      :alert="alert"
      @click.native="$store.commit('alert/DELETE_ALERT', alert.id)"
    />
  </transition-group>
</template>
<script>
import AlertCard from '@/components/Alert/AlertCard'

export default {
  name: 'AlertList',
  components: { AlertCard },
  data: () => ({
    show: false,
    timeout: null
  }),
  computed: {
    alerts () {
      return this.$store.state.alert.alertList
    }
  },
  watch: {
    'alerts.length' (length) {
      clearTimeout(this.timeout)
      if (this.show && length) return

      if (!length) {
        this.timeout = setTimeout(() => {
          this.show = false
        }, 50)
      } else {
        this.show = true
      }
    }
  }
}
</script>
