import { Status } from '../classes/Status'

export const STATUSES = [
  new Status('Не обработано', 'not_processed'),
  new Status('Расследование рекламации', 'investigation'),
  new Status('Запущен в производство', 'production'),
  new Status('Готово', 'complete'),
  new Status('Расследование завершено', 'investigation_finish'),
  new Status('Успешно реализовано', 'successful'),
  new Status('Не успешные', 'unsuccessful')
]
