import { createFormDataNewFiles } from '@/utils/files'
import { setAlertError } from '@/utils/store/alert'

export function createBaseFileController (api, errors) {
  return {
    postFiles: async (id, data) => {
      try {
        const formData = createFormDataNewFiles(data)
        if (formData) {
          return await api.postFiles(id, formData)
        }
      } catch (e) {
        await setAlertError(e, errors.postFiles)
      }
    },
    deleteFiles: async (idList) => {
      try {
        if (idList?.length) {
          await api.deleteFiles(idList)
        }
      } catch (e) {
        await setAlertError(e, errors.deleteFiles)
      }
    }
  }
}
