import Vue from 'vue'
import { vuexAll } from '@/store/composition/modules/model/all'
import { vuexBaseModel } from '@/store/composition/modules/model'
import { vuexBaseLoading } from '@/store/composition/modules/loading'
import { vuexBaseSearch } from '@/store/composition/modules/query/search'

const createState = () => ({
  ...vuexAll.createState(),
  ...vuexBaseModel.createState(),
  ...vuexBaseLoading.createState(),

  // query
  sort: {
    descending: true
  },
  pagination: {
    perPage: 5000,
    page: 1
  },
  ...vuexBaseSearch.createState()
})
const createGetters = () => ({
  ...vuexBaseModel.createGetters(),
  currentList: state => state.search ? state.list : state.all
})

const createMutations = () => ({
  ...vuexAll.createMutations(),
  ...vuexBaseModel.createMutations(),
  ...vuexBaseLoading.createMutations(),
  ...vuexBaseSearch.createMutations(),
  RESET_STORE: (state) => {
    Vue.set(state, 'list', [])
    state.search = ''
  }
})

const createActions = () => ({
  ...vuexAll.createActions(),
  ...vuexBaseModel.createActions(),
  async loadSearch ({ commit, dispatch }, search) {
    commit('SET_SEARCH', search)
    if (!search) {
      commit('SET_LIST', [])
      return
    }
    await dispatch('loadList')
  }
})

export const vuexProductionTemplate = {
  createState,
  createGetters,
  createMutations,
  createActions
}
