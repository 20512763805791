<template>
  <ModalForm
    v-model="formData"
    class="modal--md"
    title="Рекламация будет переведена в статус «Запущен в производство»."
    text-btn-confirm="ОК"
    @request="onSubmit"
    @reject="onReject"
  >
    <VSelect
      label="Сроки изготовления:"
      class="mb-30"
      name="timing"
      :options="$store.state.records.timingAll"
      required
    >
      <Validation for="required" />
    </VSelect>

    <VCheckbox
      label="Скопировать позицию в карточку заказа"
      class="mb-30"
      name="need_copy_position"
      :is-disabled="!reclamation.position_id"
    />

    <VInput
      v-show="formData.need_copy_position"
      label="Количество изделий:"
      name="spring_count"
      class="mb-30"
      :required="formData.need_copy_position"
      :pattern="PATTERN_LIST.num"
    >
      <Validation for="required" />
      <Validation for="pattern" key-text="num" />
    </VInput>
  </ModalForm>
</template>

<script>
import ModalForm from '@/components/ui/Modal/ModalForm'
import Validation from '@/components/Form/Validation'
import VCheckbox from '@/components/Form/VCheckbox.vue'
import VSelect from '@/components/Form/VSelect.vue'
import VInput from '@/components/Form/Vinput/VInput.vue'
import { reclamationExternalModel } from '@/entities/reclamation-external'
import { ReclamationApi } from '@/api/reclamation/ReclamationApi'
import { mapMutations, mapState } from 'vuex'
import { PATTERN_LIST } from '@/const/validation'
import { showAlertError, showAlertSuccess } from '@/utils/store/alert'

export default {
  components: { VInput, VSelect, VCheckbox, Validation, ModalForm },
  props: {
    reclamation: {
      type: reclamationExternalModel.ReclamationExternal,
      required: true
    }
  },
  data: () => ({
    formData: {
      timing: null,
      need_copy_position: false,
      spring_count: null
    }
  }),

  computed: {
    ...mapState('reclamationExternal/details', {
      reclamationUpdated: 'reclamationUpdated'
    }),
    PATTERN_LIST: () => PATTERN_LIST
  },

  methods: {
    ...mapMutations('reclamationExternal/details', {
      UPDATE_GENERAL_INFORMATION: 'UPDATE_GENERAL_INFORMATION',
      UPDATE_RECLAMATION_ORDER: 'UPDATE_RECLAMATION_ORDER',
      UPDATE_STATUS_AND_INDICATOR: 'UPDATE_STATUS_AND_INDICATOR'
    }),

    async onSubmit () {
      try {
        this.$store.commit('START_IS_SENDING_REQUEST_MODAL')
        if (this.reclamationUpdated?.indicator === 5) {
          const response = await ReclamationApi.update(this.reclamation.id, this.reclamationUpdated)
          this.UPDATE_GENERAL_INFORMATION(this.reclamationUpdated)
          this.UPDATE_STATUS_AND_INDICATOR(response.data)
          showAlertSuccess('Общая информация обновлена')
        }
        const data = this.getMapped(this.formData)

        await this.createOrder(data)
        this.$emit('create-success')
      } catch (e) {
        showAlertError(e)
      } finally {
        this.$store.commit('FINISH_IS_SENDING_REQUEST_MODAL')
      }
    },

    async createOrder (data) {
      const response = await ReclamationApi.createOrder(this.reclamation.id, data)

      this.UPDATE_RECLAMATION_ORDER({
        reclamation_order_id: response.data.reclamation_order_id,
        reclamation_order: response.data.reclamation_order
      })
      this.UPDATE_STATUS_AND_INDICATOR(response.data)
    },

    getMapped (formData) {
      const data = {
        timing_id: formData.timing.id
      }

      if (formData.need_copy_position) {
        data.position_id = this.reclamation.position_id
        data.value = +formData.spring_count
      }

      return data
    },

    onReject () {
      this.$emit('reject')
    }
  }
}
</script>
