import { reclamationExternalModel } from '@/entities/reclamation-external'
import { reclamationExternalFilterListModel } from '@/features/reclamation-external/filter-list'
import { reclamationExternalLaunchIntoProductionModel } from '@/features/reclamation-external/launch-into-production'

export default {
  namespaced: true,
  modules: {
    details: reclamationExternalModel.store,
    list: reclamationExternalModel.store,
    filterList: reclamationExternalFilterListModel.store,
    launchIntoProduction: reclamationExternalLaunchIntoProductionModel.store
  }
}
