/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/notification': {
    width: 16,
    height: 19,
    viewBox: '0 0 16 19',
    data: '<path pid="0" d="M8 19c1.261 0 2.285-1.063 2.285-2.375h-4.57C5.715 17.937 6.74 19 8 19zm7.693-5.556c-.69-.77-1.982-1.929-1.982-5.725 0-2.884-1.945-5.192-4.569-5.758v-.774C9.142.532 8.631 0 8 0c-.63 0-1.142.532-1.142 1.188v.773c-2.624.566-4.57 2.874-4.57 5.758 0 3.796-1.29 4.955-1.98 5.725-.215.24-.31.526-.308.806.004.609.464 1.188 1.146 1.188h13.708c.682 0 1.142-.58 1.146-1.188a1.184 1.184 0 00-.307-.806z" _fill="currentColor"/>'
  }
})
