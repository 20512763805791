import { deleteFiles } from '@/store/composition/modules/file/actions'
import { ADD_FILE_TO_DELETE, RESET_FILES_TO_DELETE } from '@/store/composition/modules/file/mutations'

const createState = () => ({
  filesToDelete: []
})

const createMutations = () => ({
  ADD_FILE_TO_DELETE,
  RESET_FILES_TO_DELETE
})

const createActions = () => ({
  deleteFiles,

  async createItemWithFiles ({ dispatch }, { data, params }) {
    const response = await dispatch('createItem', { data, params })
    if (response?.status !== 201) return

    await dispatch('postFiles', { id: response.data.id, files: data.files })

    return response
  },
  async updateItemWithFiles ({ dispatch, commit }, { id, data, params }) {
    const response = await dispatch('updateItem', { id, data, params })
    if (response?.status !== 200) {
      commit('RESET_FILES_TO_DELETE')
      return
    }

    await dispatch('postFiles', { id, files: data.files })
    await dispatch('deleteFiles')

    return response
  },
  async postFiles ({ state }, { id, files }) {
    return state.controller.postFiles(id, files)
  }
})

const vuexBaseFile = {
  createState,
  createMutations,
  createActions
}

export {
  vuexBaseFile
}
