import { vuexHandbook } from '@/store/composition/handbook'
import TechTypeApi from '@/api/handbook/TechType/TechTypeApi'

const state = () => ({
  ...vuexHandbook.createState(),
  api: TechTypeApi
})

const getters = {
  ...vuexHandbook.createGetters()
}

const mutations = {
  ...vuexHandbook.createMutations()
}

const actions = {
  ...vuexHandbook.createActions()
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
