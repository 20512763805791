/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/remove': {
    width: 15,
    height: 18,
    viewBox: '0 0 15 18',
    data: '<path pid="0" _stroke="currentColor" d="M6.435 2.478C6.27-.158 8.804-.063 8.591 2.461l3.88-.032c1.683.187 1.017 2.175.018 2.064H2.469c-1.146.143-1.412-1.876-.049-2.031l4.015.016zM2.014 7.702A1.5 1.5 0 013.5 6h8.123a1.5 1.5 0 011.487 1.702l-1.043 7.647a1.5 1.5 0 01-1.486 1.298H4.543c-.75 0-1.385-.554-1.487-1.298L2.014 7.702z"/><path pid="1" d="M7.562 8.676v5.294M4.915 8.676l.529 5.294M10.209 8.676l-.53 5.294" stroke-linecap="round" _stroke="currentColor"/>'
  }
})
