/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'qr-green': {
    width: 36,
    height: 36,
    viewBox: '0 0 36 36',
    data: '<rect pid="0" width="36" height="36" rx="3" _fill="#3EAF3C"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M3.344 4.875c0-.846.685-1.531 1.531-1.531h8.458v1.312H4.875a.219.219 0 00-.219.219v8.458H3.344V4.875zm29.312 26.25c0 .846-.685 1.531-1.531 1.531h-8.458v-1.312h8.458c.12 0 .219-.098.219-.219v-8.458h1.312v8.458zM31.125 3.344c.846 0 1.531.685 1.531 1.531v8.458h-1.312V4.875a.219.219 0 00-.219-.219h-8.458V3.344h8.458zM3.344 31.125c0 .846.685 1.531 1.531 1.531h8.458v-1.312H4.875a.219.219 0 01-.219-.219v-8.458H3.344v8.458zm5.08-15.993V8.424h6.708v6.708H8.424zM7.11 7.986c0-.483.392-.875.875-.875h7.583c.484 0 .875.392.875.875v7.583a.875.875 0 01-.875.875H7.986a.875.875 0 01-.875-.875V7.986zm0 10.913h9.333v1.313h-3.208v1.07h-3.111v-1.07H7.111v-1.313zm3.111 3.111h-3.11v1.313h3.11V22.01zM11 11.122h1.556v1.312H11v-1.312zm14 12.444h-1.556v1.312H25v-1.312zm-14.875-.097h3.111v1.312h-3.111V23.47zm-3.014 3.208h9.333v1.313H7.111v-1.313zm21.656-10.233V7.111h-1.312v9.333h1.312zm-3.11-6.319V7.111h-1.313v3.111h.875v3.014h1.312v-3.111h-.875zm-1.75 3.5v-1.75h-1.313v1.75h.875v1.75h1.312v-1.75h-.875zm-2.918-6.514v9.333h-1.312V7.111h1.313zm-.12 20.465v-6.708h6.707v6.708h-6.708zm-1.313-7.146c0-.483.391-.875.875-.875h7.583c.483 0 .875.392.875.875v7.584a.875.875 0 01-.875.875h-7.583a.875.875 0 01-.875-.875V20.43z" _fill="#fff"/>'
  }
})
