import { routeName as index } from './route'
import { routeName as list } from './List'
import { routeName as create } from './Create'
import { routeName as details } from './Details'
import { routeName as changelog } from './Changelog'
import { routeName as reports } from './Reports'

export const pages = {
  index,
  list,
  create,
  details,
  changelog,
  reports
}
