<template>
  <div class="base-input" :class="{ readonly, disabled, error: hasValidationError && !loading }">
    <label
      v-if="label"
      :class="['input__label', { readonly }]"
      @click="() => !readonly && $refs.input.focus()"
    >
      {{ label }}
      <span v-if="required" class="input__label-required">*</span>
    </label>

    <div class="input__field" v-if="readonly">
      {{ inputValue }}
    </div>

    <input
      v-else
      v-model="inputValue"
      ref="input"
      :name="name"
      class="input__field input"
      :class="withCopy ? 'input__field--with-icon' : ''"
      :style="{ width: inputWidth }"
      :type="inputType"
      :placeholder="placeholder"
      :maxlength="usedMask.mask.length || limitMaxLength"
      :autocomplete="autocomplete"
      :readonly="readonly"
      :disabled="disabled || loading"
      @keydown="keydown"
      @keyup="keyup"
      @keypress="keypress"
      @paste="paste"
      @input="input"
      @blur="handleBlur"
      @focus="$emit('focus')"
    />

    <img
      class="loader_img"
      v-if="loading || serverValidationPending"
      :src="require('@/assets/images/Loader.gif')"
      alt=""
    />

    <svgicon
      v-if="withCopy && !disabled"
      class="base-input__icon-copy"
      icon="input-copy"
      width="23"
      height="20"
      @click="copyField"
    />

    <slot v-if="hasValidationError && !serverValidationPending" />

    <slot name="after"/>
  </div>
</template>

<script>
import Base from './Base/Base'

export default {
  name: 'VText',
  extends: Base,
  props: {
    withCopy: {
      type: Boolean,
      required: false,
      default: false
    },
    inputType: {
      type: String,
      default: 'text'
    }
  },
  methods: {
    handleBlur () {
      this.$emit('blur')
      this.validate()
    },
    focus () {
      this.$refs.input.focus()
    },

    copyField () {
      this.$emit('copyField')
    },

    input (e) {
      this.$emit('input', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.loader_img {
  width: 20px;
  position: absolute;
  top: 44px;
  right: 10px;
}

.base-input__icon-copy {
  position: absolute;
  right: 10px;
  bottom: 10px;
  z-index: 2;
  color: $color__blue--light;
  cursor: pointer;
  transition: color 150ms ease-in-out;

  &:hover {
    color: var(--hover__main);
  }

  &:active {
    color: $color__light-sea-green--light;
  }
}
</style>
