/* eslint-disable camelcase */
import { NavItem } from '@/classes/nav/NavItem'
import IconSvg from '@/classes/IconSvg'

export default class CatalogSidebarNavItem extends NavItem {
  static iconSvg = new IconSvg({
    name: 'swiper-arrow',
    width: 6,
    height: 12,
    prefix: 'button/'
  })

  count
  _parent_id

  constructor ({ label, id, count = 0, parent_id = null }) {
    super({
      label,
      routeName: 'Catalog',
      routeParams: { categoryId: id || null }
    })

    this._parent_id = parent_id
    this.count = count
  }

  get parent_id () {
    return this._parent_id
  }

  get icon () {
    return CatalogSidebarNavItem.iconSvg.fullName
  }

  get iconSize () {
    return CatalogSidebarNavItem.iconSvg.size
  }
}
