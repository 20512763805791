import { SET_SEARCH, RESET_SEARCH } from '@/store/composition/modules/query/search/mutations'
import { SET_FILTER } from '@/store/composition/modules/query/filter/mutations'
import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'
import getValuesArray from '@/utils/array/getValuesArray'
import { transformDateForSend } from '@/utils/form/date'
import { ReclamationApi } from '@/api/reclamation/ReclamationApi'
import { ReclamationExternal } from '@/entities/reclamation-external/model/classes/ReclamationExternal'
import { showAlertError } from '@/utils/store/alert'
import { reclamationExternalModel } from '@/entities/reclamation-external'
import { findByKeyValue } from '@/utils/array/find'
import { getPageCount } from '@/utils/store/pagination'
import { reclamationPages } from '@/views/Reclamation'
import router from '@/router'

export default {
  namespaced: true,
  state: () => ({
    search: '',
    filter: {
      indicator: null,
      resolution: null,
      position_type: null,
      executor: null,
      reason_reclamation_outer: null
    },
    date: '',
    reclamations: [],
    // Query
    pagination: {
      perPage: 20,
      page: 1,
      pageCount: 1
    },
    tabs: reclamationExternalModel.STATUSES.map(status => ({
        key: status.value,
        id: status.id,
        label: status.name,
        count: 0,
        routeName: reclamationPages.list,
        routeParams: {
          status: status.value
        }
    })),
    isLoading: false
  }),

  getters: {
    getFilter: (state) => {
      const toIds = [
        'position_type',
        'executor',
        'reason_reclamation_outer'
      ]

      return () => {
        const prepared = prepareFormDataIdArray(state.filter, toIds)

        if (prepared.indicator) {
          prepared.indicator = prepared.indicator.id
        }

        if (prepared.resolution) {
          prepared.reclamation_decisions = getValuesArray(prepared.resolution)
          delete prepared.resolution
        }

        return {
          search: state.search,
          ...prepared,
          ...transformDateForSend(state.date)
        }
      }
    },
    getDate: (state) => state.date,
    getSearch: (state) => state.search
  },

  mutations: {
    SET_SEARCH,
    RESET_SEARCH,
    SET_FILTER,
    RESET_FILTER (state) {
      state.filter = {
        indicator: null,
        resolution: null,
        position_type: null,
        executor: null,
        reason_reclamation_outer: null
      }
    },
    SET_DATE (state, date) {
      state.date = date
    },
    RESET_DATE (state) {
      state.date = ''
    },
    SET_RECLAMATIONS (state, reclamations) {
      state.reclamations = []
      for (const item of reclamations) {
        state.reclamations.push(new ReclamationExternal(item))
      }
    },
    SET_PAGE (state, pageNumber) {
      state.pagination.page = pageNumber
    },
    SET_PAGINATION_PAGE_COUNT (state, pageCount) {
      state.pagination.pageCount = pageCount
    },
    SET_STATUSES_COUNT (state, counts) {
      for (const key in counts) {
        const tab = state.tabs.find(tab => tab.key === key)
        tab.count = counts[key]
      }
    },
    SET_IS_LOADING (state, loading) {
      state.isLoading = loading
    }
  },

  actions: {
    async fetchReclamations ({ commit, getters, state, dispatch }) {
      try {
        commit('SET_IS_LOADING', true)
        const status = findByKeyValue(reclamationExternalModel.STATUSES, 'value', router.currentRoute.params.status)
        const response = await ReclamationApi.getAll({
          status: status.id,
          ...getters.getFilter(),
          'per-page': state.pagination.perPage,
          page: state.pagination.page
          })
        commit('SET_RECLAMATIONS', response.data)
        commit('SET_PAGINATION_PAGE_COUNT', getPageCount(response))
        dispatch('fetchStatusesCount')
      } catch (e) {
        showAlertError(e)
      }
    },
    async fetchStatusesCount ({ commit, getters }) {
      try {
        const response = await ReclamationApi.getAmountByStatuses(getters.getFilter())
        commit('SET_STATUSES_COUNT', response.data)
      } catch (e) {
        showAlertError(e)
      } finally {
        commit('SET_IS_LOADING', false)
      }
    }
  }
}
