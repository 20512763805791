/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'error': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M11.403 5l-.276 6.477H9.36L9.083 5h2.32zm-1.16 7.823c.35 0 .645.133.884.4.249.256.373.566.373.931 0 .375-.124.695-.373.962-.24.256-.534.384-.884.384a1.19 1.19 0 01-.884-.384A1.388 1.388 0 019 14.154c0-.365.12-.675.36-.931.248-.267.542-.4.883-.4z" _fill="#F74B4B"/><circle pid="1" cx="10" cy="10" r="9.5" _stroke="#F74B4B"/>'
  }
})
