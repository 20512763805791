<script>
export default {
  props: {
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: null
    },
    label: String,
    placeholder: String,
    readonly: Boolean,
    disabled: Boolean,
    loading: Boolean,
    autocomplete: {
      type: [Boolean, String],
      default: false
    },
    name: String,
    inputWidth: {
      type: String,
      default: '100%'
    },
    width: {
      type: String,
      default: '100%'
    },
    textareaStyle: Object,
    limitMaxLength: {
      type: Number,
      default: Infinity
    }
  }
}
</script>
