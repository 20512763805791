import axios from 'axios'

const configMultipart = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL
})

instance.interceptors.request.use(config => {
    if (localStorage.getItem('jwt-token')) {
      config.headers.authorization = `Bearer ${localStorage.getItem('jwt-token')}`
    }
    return config
  }, error => error
)

export {
  instance,
  configMultipart
}
