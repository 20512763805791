export function sortByName (a, b) {
  return a.name.localeCompare(b.name)
}

export function bySort (a, b) {
  if (a.sort > b.sort) {
    return 1
  }

  if (a.sort < b.sort) {
    return -1
  }

  return 0
}

export function byTypeId (a, b) {
  if (a.type_id > b.type_id) {
    return 1
  }

  if (a.type_id < b.type_id) {
    return -1
  }

  return 0
}
