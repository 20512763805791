import BreadcrumbsNavItem from '@/classes/nav/BreadcrumbsNavItem'
import { generateLorem } from '@/mocks/mockLorem'
import { generateId } from '@/utils/create'

export default class CatalogBreadcrumbsNavItem extends BreadcrumbsNavItem {
  constructor ({ name = generateLorem(3), id = generateId() }) {
    super({
      label: name,
      id,
      routeName: 'Catalog',
      routeParams: {
        categoryId: id
      }
    })
  }
}
