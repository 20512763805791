/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'submenu': {
    width: 9,
    height: 13,
    viewBox: '0 0 9 13',
    data: '<path pid="0" d="M1 11V2" _stroke="currentColor" stroke-linecap="round"/><path pid="1" d="M7.998 6.159a.5.5 0 010 .682l-2.882 3.09a.5.5 0 01-.866-.34V3.409a.5.5 0 01.866-.34l2.882 3.09z" _stroke="currentColor"/>'
  }
})
