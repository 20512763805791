import StageLineApi from '@/api/production/StageLine/StageLineApi'
import { setAlertError } from '@/utils/store/alert'

const singular = 'линию этапов'
const STAGE_LINE_ERRORS = {
  create: 'Не удалось создать ' + singular,
  update: 'Не удалось обновить ' + singular
}

export default {
  create: async (data) => {
    try {
      return await StageLineApi.create(data)
    } catch (e) {
      await setAlertError(e, STAGE_LINE_ERRORS.create)
    }
  },
  update: async (id, data) => {
    try {
      return await StageLineApi.update(id, data)
    } catch (e) {
      await setAlertError(e, STAGE_LINE_ERRORS.update)
    }
  }
}
