export class Resolution {
  static counter = 1
  name = ''
  color = ''

  constructor (name, color) {
    this.name = name
    this.color = color
    this.id = Resolution.counter
    Resolution.incrementCounter()
  }

  static incrementCounter () {
    Resolution.counter++
  }
}
