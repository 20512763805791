export default class IconSvg {
  name
  width
  height
  prefix

  constructor ({ name, width = 0, height = 0, prefix = '' }) {
    this.name = name
    this.width = width
    this.height = height
    this.prefix = prefix
  }

  get fullName () {
    return this.prefix + this.name
  }

  get size () {
    return {
      width: this.width.toString(),
      height: this.height.toString()
    }
  }
}
