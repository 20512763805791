/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-long': {
    width: 25,
    height: 10,
    viewBox: '0 0 25 10',
    data: '<path pid="0" d="M.5 5h23.716m0 0L20.01 1m4.206 4L20.01 9" _stroke="#C3D1E4" stroke-linecap="round"/>'
  }
})
