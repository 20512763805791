export function createFormDataNewFiles (files) {
  if (!files?.length) return false

  const formData = new FormData()
  let count = 0

  files.forEach(file => {
    if (!file.id) {
      formData.append('files[]', file)
      count += 1
    }
  })

  return count ? formData : false
}

export function getURL (file) {
  if (isFilePrototype(file)) {
    return URL.createObjectURL(file)
  }
  return file?.url_pdf || file?.url || file?.original_url
}

export function isFilePrototype (file) {
  return Object.getPrototypeOf(file) === File.prototype
}

export function getFileName (file) {
  return file?.file_name || file?.name
}

export function getFileTypeBy (fileName) {
  return fileName.split('.').at(-1).toLowerCase()
}
