import { RESOLUTIONS } from './const/resolutions'
import { STATUSES } from './const/statuses'
import { INDICATORS } from './const/indicators'

export function getResolutionNameBy (id) {
  return RESOLUTIONS[id - 1].name
}

export function getStatusNameBy (id) {
  return STATUSES[id - 1].name
}

export function isIndicatorForCopyPosition (id) {
  switch (id) {
    case INDICATORS[4].id:
      return true
  }

  return false
}
