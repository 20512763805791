function createBaseResponseErrors (singular, plural) {
  return {
    getAll: 'Не удалось загрузить список ' + plural,
    getById: 'Не удалось загрузить ' + singular,
    create: 'Не удалось создать ' + singular,
    update: 'Не удалось обновить ' + singular,
    destroy: 'Не удалось удалить ' + singular
  }
}

function createFilesResponseErrors () {
  return {
    postFiles: 'Не удалось загрузить файл',
    deleteFiles: 'Не удалось удалить файл'
  }
}

function createHandbookResponseErrors (singular, plural) {
  return {
    ...createBaseResponseErrors(singular, plural),
    postSorted: 'Не удалось сохранить сортировку ' + plural,
    hide: createHideResponseError(singular)
  }
}

function createHideResponseError (name) {
  return `Не удалось скрыть ${name}`
}

export {
  createBaseResponseErrors,
  createFilesResponseErrors,
  createHandbookResponseErrors,
  createHideResponseError
}
