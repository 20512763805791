const createState = () => ({
  search: ''
})

const createMutations = () => ({
  SET_SEARCH: (state, search) => {
    state.search = search
  }
})

const createActions = () => ({
  async loadSearch ({ commit, dispatch }, search) {
    commit('SET_PAGE', 1)
    commit('SET_SEARCH', search)
    await dispatch('loadList')
  }
})

const vuexBaseSearch = {
  createState,
  createMutations,
  createActions
}

export {
  vuexBaseSearch
}
