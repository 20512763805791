import productionMeta from '@/router/production/productionMeta'
import orderList from '@/router/production/register/orderList'
import consignmentList from '@/router/production/register/consignmentList'

export default [
  {
    path: 'order',
    name: 'Order',
    redirect: { name: 'OrderList' },
    meta: productionMeta,
    component: () => import('@/views/Production/Order/Order.vue'),
    children: [
      ...orderList,
      ...consignmentList
    ]
  },
  {
    path: 'create',
    name: 'OrderCreate',
    meta: productionMeta,
    component: () => import('@/views/Production/Order/OrderCreationAndEditing.vue')
  },
  {
    path: ':id/update',
    name: 'OrderUpdate',
    meta: productionMeta,
    component: () => import('@/views/Production/Order/OrderCreationAndEditing.vue')
  },
  {
    path: 'order-one/:id',
    name: 'OrderDetails',
    meta: productionMeta,
    component: () => import('@/views/Production/Order/OrderDetails.vue'),
    props: true
  },
  {
    path: 'consignment-one/:id_cons',
    name: 'DetailedConsignment',
    meta: productionMeta,
    component: () => import('@/views/Production/Order/ConsignmentDetails.vue'),
    props: true
  }
]
