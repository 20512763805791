/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/production-spring': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<circle pid="0" cx="10.5" cy="10.5" r="10" _stroke="currentColor"/><rect pid="1" width="9.065" height="1.017" rx=".508" transform="matrix(-.97985 -.19975 -.1157 .99328 15 12.908)" _fill="currentColor"/><rect pid="2" width="9.083" height="1.015" rx=".508" transform="matrix(.97526 -.22107 .12846 .99172 6.005 14.993)" _fill="currentColor"/><rect pid="3" width="9.083" height="1.015" rx=".508" transform="matrix(.97526 -.22107 .12846 .99172 6.005 11.023)" _fill="currentColor"/><rect pid="4" width="9.069" height="1.016" rx=".508" transform="matrix(-.97883 -.20466 -.11864 .99294 14.998 8.938)" _fill="currentColor"/><rect pid="5" width="9.083" height="1.015" rx=".508" transform="matrix(.97526 -.22107 .12846 .99172 6.005 7.008)" _fill="currentColor"/>'
  }
})
