import TemplateStageLineApi from '@/api/production/Template/TemplateStageLineApi'
import { createBaseController } from '@/controllers/baseController'
import { createBaseResponseErrors } from '@/utils/error/responseErrors'

const TEMPLATE_STAGE_LINE_ERRORS = {
  ...createBaseResponseErrors('шаблон линии этапов', 'шаблонов линий этапов')
}

export default {
  ...createBaseController(TemplateStageLineApi, TEMPLATE_STAGE_LINE_ERRORS)
}
