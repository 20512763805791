import { createNewHandbookApi } from '@/api/handbookApi'
import { CATALOG_URL } from '@/api/catalog'
import { createHandbookResponseErrors } from '@/utils/error/responseErrors'

const TECH_BRAND_URL = `${CATALOG_URL}/tech-brands`

const TECH_BRAND_ERRORS = {
  ...createHandbookResponseErrors('марку техники', 'марок техники')
}

export default {
  ...createNewHandbookApi(TECH_BRAND_URL, TECH_BRAND_ERRORS)
}
