function getOrderStatusText (statusId) {
  switch (statusId) {
    case 1: return 'Новые заказы'
    case 6: return 'Заказы на запуск'
    case 2: return 'Заказы в работе'
    case 3: return 'Готовые заказы'
    case 4: return 'Заказы на отгрузку'
    case 5: return 'Успешно реализовано'
    default: return '-'
  }
}

function getOrderStatusIdByRouteName (routeName) {
  switch (routeName) {
    case 'OrderListItem': return 1
    case 'LaunchOrderList': return 6
    case 'ProgressOrderList': return 2
    case 'ReadyOrderList': return 3
    case 'ShippingOrderList': return 4
    case 'ArchiveOrderList': return 5
  }
}

export {
  getOrderStatusText,
  getOrderStatusIdByRouteName
}
