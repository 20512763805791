export const ELabels = {
  orderNumber: '№ заказа',
  positionNumber: '№ позиции',
  company: 'Компания / Контакт',
  responsibleManager: 'Ответственный менеджер',
  reasonPetition: 'Причина обращения',
  reasonReclamation: 'Причина рекламации',
  disk: 'Диск',
  dealAtCRM: 'Рекламация в CRM',
  dateCreated: 'Дата поступления рекламации',
  dateInvestigation: 'Дата начала расследования',
  reclamationProductionRegister: 'Рекламация в производстве'
}

export const EInvestigationLabels = {
  investigationReclamation: 'Расследование рекламации',
  primeCause: 'Первопричина',
  primeCauseComment: 'Комментарий к первопричине',
  countSprings: 'Кол-во поступивших изделий',
  weight: 'Вес, кг',
  responsibleForNonCompliance: 'Ответственные за несоответствие',
  leader: 'Руководитель',
  executor: 'Исполнитель',
  executorPosition: 'Должность исполнителя'
}

export const EResolutionLabels = {
  resolution: 'Решение',
  comment: 'Комментарий к решению',
  responsibleInvestigation: 'Ответственный за расследование',
  countOfSpringDefective: 'Кол-во бракованных изделий',
  typeLoss: 'Вид потери (металл, кг)',
  countOfSpringAll: ' Кол-во всех навитых изделий',
  weightAll: 'Всего кг. потрачено',
  dateReclamationClose: 'Дата закрытия рекламации'
}
