import Vue from 'vue'

export const mixinDefineProp = {
  created () {
    this.defineProp()
  },
  methods: {
    defineProp () {
      if (!this.name || !this.formData) return

      if (!this.formData[this.name] || !Object.prototype.hasOwnProperty.call(this.formData, this.name)) {
        Vue.set(this.formData, this.name, this.value)
      }
    }
  }
}
