/* eslint-disable camelcase */
import BaseModel from '@/classes/BaseModel'
import ProductCoatingPriceCategory from '@/classes/model/Catalog/ProductCoatingPriceCategory'
import ProductPosition from '@/classes/model/Catalog/ProductPosition'
import { comma2Dot } from '@/utils/string'

export default class Product extends BaseModel {
  // head
  spring_type_id
  spring_type
  article
  categories
  additional_articles
  created_at

  // main
  files_images
  note_product
  d_wire
  diameter
  length
  weight
  n_n1
  product_winding_dir
  width
  material
  tech_brand
  shape

  // general
  application_areas
  tech_type
  product_type
  country
  files_start
  files_sales
  files_admin
  applicability
  note

  // price
  note_admin
  coating
  color
  prices
  prices_updated

  //
  position

  // amount
  warehouse_amount
  warehouse_amount_reserved
  warehouse_hidden

  static keyList = {
    toIds: [
      'categories',
      'additional_articles',
      'application_areas',
      'tech_brand'
    ],
    toId: [
      'tech_type',
      'product_type',
      'country',
      'spring_type',
      'coating',
      'color',
      'material',
      'product_winding_dir',
      'shape'
    ],
    commaToDot: [
      'd_wire',
      'length',
      'weight',
      'width'
    ],
    files: [
      'files_sales',
      'files_admin',
      'files_start',
      'files_images'
    ],
    forPrefixProduct: [
      'application_areas_ids',
      'additional_articles_ids',
      'categories_ids',
      'material_id',
      'tech_brand_ids',
      'tech_type_id',
      'country_id',
      'coating_id',
      'color_id',
      'note_product',
      'd_wire',
      'diameter',
      'length',
      'weight',
      'n_n1',
      'width',
      'applicability',
      'note',
      'note_admin',
      'prices_updated',
      'warehouse_amount',
      'warehouse_amount_reserved',
      'warehouse_hidden'
    ]
  }

  constructor (data) {
    super({ id: data.id, name: data.name })

    this.spring_type_id = data.spring_type_id
    this.spring_type = data.spring_type
    this.categories = data.product_categories
    this.article = data.article
    this.additional_articles = data.product_additional_articles
    this.created_at = data.created_at

    this.files_images = data.files_images || []
    this.note_product = data.product_note_product
    this.d_wire = data.product_d_wire
    this.diameter = data.product_diameter
    this.length = data.product_length
    this.weight = data.product_weight
    this.n_n1 = data.product_n_n1
    this.product_winding_dir = data.product_winding_dir
    this.width = data.product_width
    this.material = data.product_material
    this.tech_brand = data.product_tech_brand
    this.shape = data.shape

    this.application_areas = data.product_application_areas
    this.tech_type = data.product_tech_type
    this.product_type = data.product_type
    this.country = data.product_country
    this.files_start = data.files_start || []
    this.files_sales = data.files_sales || []
    this.files_admin = data.files_admin || []
    this.applicability = data.product_applicability
    this.note = data.product_note

    this.note_admin = data.product_note_admin
    this.coating = data.product_coating
    this.color = data.product_color
    this.prices = data.product_prices?.length ? data.product_prices.map(item => new ProductCoatingPriceCategory(item)) : []
    this.prices_updated = data.product_prices_updated

    this.warehouse_amount = data.warehouse_amount
    this.warehouse_amount_reserved = data.warehouse_amount_reserved
    this.warehouse_hidden = data.warehouse_hidden

    this.position = new ProductPosition(data)
  }

  static addPrefixProductAndDeleteOldKeyForRequest (data) {
    const prefix = 'product_'

    for (const key of Product.keyList.forPrefixProduct) {
      data[`${prefix + key}`] = data[key]

      delete data[key]
    }

    data[`${prefix}prices`] = data.prices.map(item => ({ ...item }))

    delete data.prices
  }

  static formatPrices (prices) {
    for (const item of prices) {
      item.cost_coating = comma2Dot(item.cost_coating)
      item.cost_not_coating = comma2Dot(item.cost_not_coating)
    }
  }
}
