var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VSelect',{attrs:{"label":"Решение:","name":_vm.name,"options":_vm.options,"multiple":_vm.isMultiple,"disabled":_vm.isDisabled,"allow-empty":_vm.isAllowEmpty},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_c('Resolution',{attrs:{"resolution-id":option.id}})]}},{key:"tag",fn:function(ref){
var option = ref.option;
var remove = ref.remove;
return [_c('div',{staticClass:"multiselect__tag"},[_c('Resolution',{attrs:{"resolution-id":option.id}}),_c('i',{staticClass:"multiselect__tag-icon",attrs:{"aria-hidden":"true","tabindex":"1"},on:{"click":function($event){return remove(option)}}})],1)]}},{key:"option",fn:function(ref){
var option = ref.option;
return [_c('Resolution',{class:_vm.isMultiple && 'pl-m',attrs:{"resolution-id":option.id}})]}}])},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }