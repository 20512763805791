/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/filter': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.26 7.45V0h1.028v7.473a2.717 2.717 0 010 5.31v3.63H2.261v-3.607a2.716 2.716 0 010-5.355zm.456.99a1.688 1.688 0 110 3.377 1.688 1.688 0 010-3.377zM13.871 7.936v8.477h-1.027V7.964a2.716 2.716 0 010-5.36V0h1.027v2.632a2.717 2.717 0 010 5.304zm-.587-.964a1.688 1.688 0 110-3.376 1.688 1.688 0 010 3.376z" _fill="#697178"/>'
  }
})
