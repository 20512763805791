/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'status-shipped': {
    width: 32,
    height: 21,
    viewBox: '0 0 32 21',
    data: '<rect pid="0" x=".5" y=".5" width="19" height="17" rx=".5" _stroke="currentColor"/><path pid="1" d="M19.5 4a.5.5 0 01.5-.5h7.101a.5.5 0 01.429.242l3.898 6.478a.5.5 0 01.072.258V17a.5.5 0 01-.5.5H20a.5.5 0 01-.5-.5V4z" _stroke="currentColor"/><path pid="2" d="M24 4v6a1 1 0 001 1h6" _stroke="currentColor"/><circle pid="3" cx="7.405" cy="17.511" r="1.968" _fill="#fff" _stroke="currentColor"/><circle pid="4" cx="24.941" cy="17.738" r="1.968" _fill="#fff" _stroke="currentColor"/>'
  }
})
