import { getAll } from '@/api/request'
import { throwResponseError } from '@/utils/error/throwResponseError'

const USER_ROLE_URL = 'user/roles'

export const UserRoleApi = {
  getAll: async (params) => {
    try {
      return await getAll(USER_ROLE_URL, params)
    } catch (e) {
      throwResponseError(e, 'Не удалось загрузить список ролей')
    }
  }
}
