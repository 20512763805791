const formatterRUB = new Intl.NumberFormat('ru-Ru', {
  style: 'currency',
  currency: 'RUB',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const formatterCurrency = new Intl.NumberFormat('ru-Ru', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

const formatterNum = new Intl.NumberFormat('ru-Ru')

const formatterDWire = new Intl.NumberFormat('ru-Ru', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 2
})

function formatRUB (num) {
  return formatterRUB.format(num)
}

function formatCurrency (num) {
  return formatterCurrency.format(num)
}

function formatNum (num) {
  return formatterNum.format(num)
}

function formatDWire (num) {
  return formatterDWire.format(num)
}

export {
  formatRUB,
  formatCurrency,
  formatNum,
  formatDWire
}
