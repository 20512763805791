import adminMeta from './adminMeta'

export default [
  {
    path: 'order-chains',
    name: 'OrderChain',
    meta: adminMeta,
    component: () => import('@/views/AdminPanel/OrderChain/OrderChain.vue')
  }
]
