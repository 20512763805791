import {
  required,
  minLength,
  sameAs,
  maxLength,
  requiredIf,
  email,
  helpers,
  maxValue,
  minValue
} from 'vuelidate/lib/validators'
import { comma2Dot } from '@/utils/string'

const minValueComma2Dot = (value, validValue) => {
  return +comma2Dot(value) >= +validValue
}

export default {
  required,
  minLength,
  maxLength,
  sameAs,
  requiredIf,
  email,
  helpers,
  maxValue,
  minValue,
  minValueComma2Dot
}
