import { fixedNumberCopy } from '@/utils/number'
import { comma2Dot, toNum } from '@/utils/string'

// TODO DELETE
export function formulaTotalWeight (data) {
  const density = toNum(data?.material?.density)
  if (!density) return ''

  const d = +data.d_wire
  const l = comma2Dot(data.sweep_length)

  let value
  if (l && d && density) {
    value = (3.14 * l * Math.pow(d, 2) * density) / (4 * Math.pow(10, 9))
  }
  // TODO !!!
  data.weight = value

  return value ? fixedNumberCopy(value) : ''
}
