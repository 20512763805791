import { createNewHandbookApi } from '@/api/handbookApi'
import { createHandbookResponseErrors } from '@/utils/error/responseErrors'
import { CATALOG_URL } from '@/api/catalog'

const TECH_TYPE_URL = `${CATALOG_URL}/tech-types`

const TECH_TYPE_ERRORS = {
  ...createHandbookResponseErrors('тип техники', 'типов техники')
}

export default {
  ...createNewHandbookApi(TECH_TYPE_URL, TECH_TYPE_ERRORS)
}
