<template>
  <div>
    <InfoText label="Совместная отгрузка с заказами">
      <ul v-if="relationOrders.length" class="link-list flex f-wrap gap-xxs">
        <li
          v-for="(item, index) in relationOrders"
          :key="index"
          class="link-list__item"
        >
          <span v-if="item.relation_order_id">
            <router-link  class="link" :to="{ name: 'OrderDetails', params: { id: item.relation_order_id } }">{{ item.account_number }}</router-link>
            <span v-if="item.text"> {{ item.text }}</span>
          </span>
          <span v-else>{{ item.account_number }}</span>
        </li>
      </ul>

      <span v-else>-</span>
    </InfoText>
  </div>
</template>

<script>
import InfoText from '@/components/simple/text/InfoText.vue'
import { splitRelationOrders } from '@/utils/model/Order'
import { splitRelationOrdersSpace } from '@/utils/model/Waybill'
import { findByKeyValue } from '@/utils/array/find'

export default {
  name: 'WaybillRelationOrders',
  components: { InfoText },
  props: {
    waybill: {
      type: Object,
      required: true
    }
  },
  computed: {
    relationOrders () {
      const relationOrders = []

      if (this.waybill?.relation_orders?.length) {
        this.waybill.relation_orders.forEach(order => {
          relationOrders.push(order)
        })
      }

      if (this.waybill?.order_account_number) {
        const relationOrdersPoint = splitRelationOrders(this.waybill.order_account_number)
        for (const k in relationOrdersPoint) {
          const relationOrdersSpace = splitRelationOrdersSpace(relationOrdersPoint[k])

          if (findByKeyValue(relationOrders, 'account_number', relationOrdersSpace[0])) continue

          relationOrders.push({
            account_number: relationOrdersPoint[k],
            id: null,
            text: ''
          })
        }
      }

      return relationOrders
    }
  }
}
</script>

<style lang='scss'>
.link-list {
  &__item {
    &::after {
      content: ';';
    }
  }
}
</style>
