function getConsignmentStatusIdByRouteName (routeName) {
  switch (routeName) {
    case 'ConsignmentListItem': return 1
    case 'ReadyConsignmentList': return 2
    case 'ShippingConsignmentList': return 3
    case 'ArchiveConsignmentList': return 4
  }
}

export {
  getConsignmentStatusIdByRouteName
}
