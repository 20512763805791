/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/reset-sort': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<path pid="0" d="M5.784 5.178L13.959 13M13.96 5.178L5.783 13" _stroke="#69F"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M9.5 16.986A7.993 7.993 0 109.5 1a7.993 7.993 0 000 15.986zm0 1A8.993 8.993 0 109.5 0a8.993 8.993 0 000 17.986z" _fill="#69F"/>'
  }
})
