const state = () => ({
  nodeId: ''
})

const mutations = {
  SET_NODE_ID (state, nodeId) {
    state.nodeId = nodeId
  },
  RESET_STORE (state) {
    state.nodeId = ''
  }
}

const actions = {
  scrollToNode ({ commit, state }) {
    if (!state.nodeId) return

    setTimeout(() => {
      const node = document.getElementById(state.nodeId)

      if (node) {
        node.scrollIntoView({
          behavior: 'smooth'
        })
      }

      commit('RESET_STORE')
    }, 0)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
