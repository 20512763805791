/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'successfully': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M6 9l3.611 3.667L14.667 6" _stroke="#3EAF3C" stroke-width="3"/><circle pid="1" cx="10" cy="10" r="9.5" _stroke="#3EAF3C"/>'
  }
})
