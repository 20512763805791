import productionMeta from '@/router/production/productionMeta'

export default [
  {
    path: 'stage-line',
    name: 'TemplateStageLineList',
    meta: productionMeta,
    component: () => import('@/views/Production/Template/TemplateStageLineList.vue')
  },
  {
    path: 'stage-line/create',
    name: 'TemplateStageLineCreate',
    meta: productionMeta,
    component: () => import('@/views/Production/Template/TemplateStageLine.vue'),
    props: { action: 'create' }
  },
  {
    path: 'stage-line/:id/update',
    name: 'TemplateStageLineUpdate',
    meta: productionMeta,
    component: () => import('@/views/Production/Template/TemplateStageLine.vue'),
    props: { action: 'update' }
  },
  {
    path: 'stage-line/:id/detail',
    name: 'TemplateStageLineDetail',
    meta: productionMeta,
    component: () => import('@/views/Production/Template/TemplateStageLine.vue'),
    props: { action: 'detail' }
  }
]
