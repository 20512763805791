<template>
  <div class="d-grid d-grid--columns-2 gap-s">
    <div class="column gap-s">
      <AppInfoTextBlock
        :label-text="EInvestigationLabels.investigationReclamation"
        class="info--column"
        :text="reclamation.investigation"
        :has-tooltip="false"
      />

      <AppInfoTextBlock
        :label-text="EInvestigationLabels.primeCause"
        :text="reasonText"
      />

      <AppInfoTextBlock
        :label-text="EInvestigationLabels.primeCauseComment"
        :text="reclamation.reason_comment"
        class="info--column"
        :has-tooltip="false"
      />
    </div>

    <div class="column gap-s">
      <InfoText
        v-if="reclamation.isSpringReceived()"
        :label="EInvestigationLabels.countSprings"
        :text="reclamation.number_received"
      />

      <InfoText
        v-if="reclamation.isSpringReceived()"
        :label="EInvestigationLabels.weight"
        :text="reclamation.weight"
      />

      <p class="info__label">
        {{ EInvestigationLabels.responsibleForNonCompliance }}:
      </p>

      <InfoText
        :label="EInvestigationLabels.leader"
        class="pl-m"
        is-handbook
        :text="reclamation.manager"
      />

      <InfoText
        :label="EInvestigationLabels.executor"
        class="pl-m"
        :text="reclamation.executor"
        is-handbook
      />

      <InfoText
        :label="EInvestigationLabels.executorPosition"
        class="pl-m"
        :text="executorPosition"
      />
    </div>
  </div>
</template>

<script>
import AppInfoTextBlock from '@/components/ui/AppInfoTextBlock.vue'
import { EInvestigationLabels } from '../../model/types'
import { ReclamationExternal } from '../../model/classes/ReclamationExternal'
import InfoText from '@/components/simple/text/InfoText.vue'

export default {
  components: { InfoText, AppInfoTextBlock },
  props: {
    reclamation: {
      type: ReclamationExternal,
      required: true
    }
  },
  computed: {
    EInvestigationLabels: () => EInvestigationLabels,

    reasonText () {
      if (!this.reclamation.reason_reclamation_outer) return '-'

      return this.reclamation.reason_reclamation_outer
        .map(item => item.name)
        .join('/')
    },

    executorPosition () {
      return this.reclamation.getExecutorPost()
    }
  }
}
</script>
