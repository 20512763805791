<template>
  <span v-show="showError" class="error-message">
    <slot>{{ errorText }}</slot>
  </span>
</template>

<script>
import { VALIDATION_TEXT } from '@/const/validation'

export default {
  // TODO: вшить Validation в VInput
  name: 'Validation',
  props: {
    for: {
      type: String,
      default: ''
    },
    keyText: {
      type: String,
      default: ''
    },
    count: {
      type: [String, Number],
      default: ''
    }
  },
  computed: {
    showError () {
      if (this.$parent?.propToValidate) {
        const input = this.$parent.propToValidate
        const possibleErrors = Object.keys(input.$params)

        const firstError = Object.keys(input).find(
          item => possibleErrors.some(error => error === item) && input[item] === false
        )

        return firstError === this.for
      }
      return false
    },
    errorText () {
      if (this.count) {
        return VALIDATION_TEXT[this.for] + this.count
      }
      if (this.keyText) {
        return VALIDATION_TEXT[this.keyText]
      }

      return VALIDATION_TEXT[this.for]
    }
  }
}
</script>
