import Vue from 'vue'

function refreshArr (arr, newArr) {
  arr.length = 0
  arr.push(...newArr)
}

function spliceBy (id, arr) {
  const i = arr.findIndex(item => item.id === id)
  if (i === -1) return

  arr.splice(i, 1)
}

function syncItemById (obj, array) {
  const i = array.findIndex(item => item.id === obj.id)
  if (i === -1) return

  Vue.set(array, i, { ...array[i], ...obj })
}

export {
  refreshArr,
  spliceBy,
  syncItemById
}
