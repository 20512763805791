import store from '@/store'
import { getResponseErrorMessage } from '@/utils/error/getResponseErrorMessage'

function setAlertError (error, message) {
  console.error('Error: ', { ...error })
  const errorMessage = getResponseErrorMessage(error)
  return store.dispatch('alert/setAlertError', errorMessage || message)
}

function setAlertSuccess (message) {
  return store.dispatch('alert/setAlertSuccess', message)
}

function showAlertError (error) {
  store.dispatch('alert/setAlertError', error.message)
}

function showAlertSuccess (message) {
  store.dispatch('alert/setAlertSuccess', message)
}

function showAlertWarning (message, timeout) {
  store.dispatch('alert/setAlertWarning', { message, timeout })
}

export {
  setAlertError,
  setAlertSuccess,
  showAlertError,
  showAlertSuccess,
  showAlertWarning
}
