import { ReclamationApi } from '@/api/reclamation/ReclamationApi'
import { ReclamationExternal } from './classes/ReclamationExternal'
import Vue from 'vue'

export default {
  namespaced: true,

  state: () => ({
    reclamation: null,
    reclamationUpdated: null,
    isDisableEdit: false
  }),

  mutations: {
    SET_RECLAMATION (state, reclamation) {
      state.reclamation = new ReclamationExternal(reclamation)
    },
    RESET_STATE (state) {
      state.reclamation = null
    },

    UPDATE_STATUS_AND_INDICATOR (state, data) {
      Vue.set(state.reclamation, 'status', data.status)
      Vue.set(state.reclamation, 'indicator', data.indicator)
    },

    UPDATE_GENERAL_INFORMATION (state, data) {
      for (const key in data) {
        Vue.set(state.reclamation, key, data[key])
      }
    },

    UPDATE_RECLAMATION (state, data) {
      state.reclamationUpdated = data
    },

    UPDATE_INVESTIGATION (state, data) {
      for (const key in data) {
        Vue.set(state.reclamation, key, data[key])
      }
    },

    UPDATE_RESOLUTION (state, data) {
      for (const key in data) {
        Vue.set(state.reclamation, key, data[key])
      }
    },

    UPDATE_SPRING_RECEIVED (state, data) {
      for (const key in data) {
        Vue.set(state.reclamation, key, data[key])
      }
    },

    UPDATE_RECLAMATION_ORDER (state, data) {
      for (const key in data) {
        Vue.set(state.reclamation, key, data[key])
      }
    },

    ADD_POSITION (state, data) {
      for (const key in data) {
        Vue.set(state.reclamation, key, data[key])
      }
    },

    DESTROY_POSITION (state, data) {
      for (const key in data) {
        Vue.set(state.reclamation, key, data[key])
      }
    },

    SET_DISABLE_EDIT (state, param) {
      state.isDisableEdit = param
    }
  },

  actions: {
    async fetchReclamation ({ commit }, id) {
      const response = await ReclamationApi.getById(id)
      commit('SET_RECLAMATION', response.data)
    }
  }
}
