/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/pickup': {
    width: 23,
    height: 23,
    viewBox: '0 0 23 23',
    data: '<path pid="0" d="M.354 6.418L5.05 7.493l3.636 12.01 10.867-3.29" _stroke="currentColor" stroke-width="1.5"/><circle pid="1" cx="6.672" cy="19.672" r="1.393" transform="rotate(-16.845 6.672 19.672)" _stroke="currentColor" stroke-width="1.5"/><rect pid="2" width="11.19" height="1.251" rx=".625" transform="matrix(-.99563 .09343 .1767 .98426 20.154 9.313)" _fill="currentColor"/><rect pid="3" width="11.211" height="1.249" rx=".625" transform="matrix(.86971 -.49356 .41073 .91176 10.268 14.985)" _fill="currentColor"/><rect pid="4" width="11.211" height="1.249" rx=".625" transform="matrix(.86971 -.49356 .41073 .91176 8.853 10.31)" _fill="currentColor"/><rect pid="5" width="11.195" height="1.251" rx=".625" transform="matrix(-.99608 .08845 .17379 .98478 18.736 4.639)" _fill="currentColor"/><rect pid="6" width="11.211" height="1.249" rx=".625" transform="matrix(.86971 -.49356 .41073 .91176 7.421 5.583)" _fill="currentColor"/>'
  }
})
