const mixinNavItem = {
  props: {
    navItem: {
      type: Object,
      required: true
    },
    iconPrefix: {
      type: String,
      required: false,
      default: ''
    },
    block: {
      type: String,
      required: false,
      default: 'tab'
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false
    },
    isResetFilter: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}

export {
  mixinNavItem
}
