import { formatDate } from '@/utils/format/date'
import { STATUSES } from '../const/statuses'
import { INDICATORS } from '../const/indicators'
import { RESOLUTIONS } from '../const/resolutions'
import { fixedNumberCopy } from '@/utils/number'
import { mapUser } from '@/utils/model/User'
// TODO import не по FSD
import { formulaTotalWeight } from '@/utils/production/position/formulas'

export class ReclamationExternal {
  constructor (data) {
    this.status = data.status
    this.indicator = data.indicator
    this.id = data.id
    this.created_at = data.created_at
    this.updated_at = data.updated_at
    // Номер заказа
    this.account_number = data.account_number
    // № позиции
    this.position_number = data.position_number
    // Компания / Контакт
    this.client_name = data.client_name
    // Ответственный менеджер
    this.responsible_id = data.responsible_id
    this.responsible = data.responsible ? mapUser(data.responsible) : null
    // Причина обращения
    this.reclamation_reason_treatment_id = data.reclamation_reason_treatment_id
    this.reclamation_reason_treatment = data.reclamation_reason_treatment
    // Диск
    this.google_link = data.google_link
    // Рекламация в CRM
    this.crm_link = data.crm_link
    // Дата начала расследования
    this.started_at = data.started_at
    // Позиция
    this.position_id = data.position_id
    this.position = data.position || { value: 25 }
    // Заказ
    this.order_id = data.order_id
    this.order = data.order
    // Расследование рекламации
    this.investigation = data.investigation
    // Первопричина рекламации
    this.reason_reclamation_outer_ids = data.reason_reclamation_outer_ids
    this.reason_reclamation_outer = data.reason_reclamation_outers
    // Комментарий к первопричине
    this.reason_comment = data.reason_comment
    // Кол-во поступивших изделий
    this.number_received = data.number_received
    // Вес, кг
    this.weight = data.weight
    // Руководитель
    this.manager_id = data.manager_id
    this.manager = data.manager ? mapUser(data.manager) : null
    // Исполнитель
    this.executor_id = data.executor_id
    this.executor = data.executor ? mapUser(data.executor) : null
    // Рекламация в производстве
    this.reclamation_order_id = data.reclamation_order_id
    this.reclamation_order = data.reclamation_order
    // Решение
    this.decision = data.decision
    // Комментарий к решению
    this.decision_comment = data.decision_comment
    // Ответственный по решению
    this.decision_manager_id = data.decision_manager_id
    this.decision_manager = data.decision_manager ? mapUser(data.decision_manager) : null
    // Кол-во бракованных изделий
    this.number_defected = data.number_defected
    // Дата закрытия рекламации
    this.stopped_at = data.stopped_at
  }

  getCreatedAt () {
    return formatDate(this.created_at)
  }

  getStartedAt () {
    return formatDate(this.started_at)
  }

  getStoppedAt () {
    return formatDate(this.stopped_at)
  }

  getExecutorPost () {
    return this.executor?.post?.name
  }

  isSpringReceived () {
    return Boolean(this.number_received)
  }

  checkCanDestroy () {
    return this.status === STATUSES[0].id
  }

  canSpringReceived () {
    return this.isStatusInit() || this.isStatusInvestigation()
  }

  isStatusInit () {
    return this.status === STATUSES[0].id
  }

  isStatusInvestigation () {
    return this.status === STATUSES[1].id
  }

  isStatusLaunchIntoProduction () {
    return this.status === STATUSES[2].id
  }

  isStatusComplete () {
    return this.status === STATUSES[3].id
  }

  hasPosition () {
    return this.position_id
  }

  hasReclamationOrder () {
    return Boolean(this.reclamation_order_id)
  }

  isIndicatorForLaunchIntoProduction () {
    switch (this.indicator) {
      case INDICATORS[4].id:
      case INDICATORS[5].id:
      case INDICATORS[6].id:
        return true
    }

    return false
  }

  isResolutionForLaunchIntoProduction () {
    switch (this.decision) {
      case RESOLUTIONS[1].id:
      case RESOLUTIONS[2].id:
        return true
    }

    return false
  }

  isResolutionReject () {
    return this.decision === RESOLUTIONS[3].id
  }

  isResolutionRefund () {
    return this.decision === RESOLUTIONS[4].id
  }

  hasResolution () {
    return this.decision !== RESOLUTIONS[0].id
  }

  calcMetalLoss (countSpring) {
    const weight = countSpring * formulaTotalWeight(this.position)
    return fixedNumberCopy(weight, 3)
  }

  getReclamationOrderPositionNumber (position) {
    return `${this.reclamation_order.account_number}.${position.sort}`
  }
}
