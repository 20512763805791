/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/print': {
    width: 19,
    height: 18,
    viewBox: '0 0 19 18',
    data: '<path pid="0" d="M14.222 13.444h1.89a1.95 1.95 0 001.335-.52c.354-.334.553-.786.553-1.257V8.11c0-.471-.199-.924-.553-1.257a1.95 1.95 0 00-1.336-.52H2.89a1.95 1.95 0 00-1.336.52A1.726 1.726 0 001 8.111v3.556c0 .471.199.923.553 1.257.354.333.835.52 1.336.52h1.889M6.667 17h5.666a1.95 1.95 0 001.336-.52c.354-.334.553-.786.553-1.258v-3.555c0-.472-.199-.924-.553-1.257a1.95 1.95 0 00-1.336-.521H6.667a1.95 1.95 0 00-1.336.52 1.726 1.726 0 00-.553 1.258v3.555c0 .472.199.924.553 1.257.354.334.835.521 1.336.521zm7.555-10.667V2.778c0-.472-.199-.924-.553-1.257A1.95 1.95 0 0012.333 1H6.667a1.95 1.95 0 00-1.336.52 1.726 1.726 0 00-.553 1.258v3.555h9.444z" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
