const mixinButtonGroup = {
  props: {
    textBtnConfirm: {
      type: String,
      required: false,
      default: 'Да'
    },
    textBtnReject: {
      type: String,
      required: false,
      default: 'Нет'
    },
    btnSize: {
      type: String,
      required: false,
      default: 'm'
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    onlyBtnSubmit: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isPrevent: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    onConfirm (e) {
      if (this.isPrevent) e.preventDefault()

      this.$emit('confirm')
    },
    onReject () {
      this.$emit('reject')
    }
  }
}

export {
  mixinButtonGroup
}
