/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/header/catalog': {
    width: 21,
    height: 20,
    viewBox: '0 0 21 20',
    data: '<path pid="0" d="M2.5 3A2.5 2.5 0 015 .5h15a.5.5 0 01.5.5v17a.5.5 0 01-.5.5H3a.5.5 0 01-.5-.5V3z" _stroke="currentColor"/><rect pid="1" x="7.5" y="3.357" width="8" height="4.714" rx=".5" _stroke="currentColor"/><path pid="2" d="M2.5 7H2a1 1 0 01-1-1v0a1 1 0 011-1h1a1 1 0 011 1v.25a.75.75 0 01-.75.75v0M2.5 13H2a1 1 0 01-1-1v0a1 1 0 011-1h1a1 1 0 011 1v.25a.75.75 0 01-.75.75v0" _stroke="currentColor"/><rect pid="3" x="7.238" y="10.714" width="8.524" height=".476" rx=".238" _stroke="currentColor" stroke-width=".476"/><rect pid="4" x="7.238" y="12.619" width="8.524" height=".476" rx=".238" _stroke="currentColor" stroke-width=".476"/><path pid="5" d="M2.5 18A1.5 1.5 0 014 16.5h16.5v3H4A1.5 1.5 0 012.5 18z" _stroke="currentColor"/>'
  }
})
