<template>
  <div class="info">
    <p v-if="!notLabel" class="info__label">{{ labelText }}:</p>

    <VTooltip
      :popupText="!link ? text : link"
      :showTip="showTooltip"
    >
      <p v-if="!link && !otherShowText.length">
        {{ currentText }}
      </p>

      <p v-if="!link && otherShowText.length">
        {{ otherShowText }}
      </p>

      <AppLink
        v-if="link"
        :length="length"
        :link="link"
        :text="currentText"
      />
    </VTooltip>
  </div>
</template>

<script>
import AppLink from '../simple/button/AppLink'
import VTooltip from './VTooltip'
import stringMaxLengthFilter from '@/filters/stringMaxLength.filter'

export default {
  name: 'AppInfoTextBlock',
  components: { VTooltip, AppLink },
  props: {
    labelText: {
      type: String,
      default: ''
    },
    text: {
      type: [String, Number, null],
      default: ''
    },
    otherShowText: {
      type: [String, Number, null],
      default: ''
    },
    link: {
      type: [String, Number],
      default: ''
    },
    length: {
      type: Number,
      default: 30
    },
    hasTooltip: {
      type: Boolean,
      default: true
    },
    notLabel: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentText () {
      if (!this.text) return '-'

      const text = this.text.toString()
      return this.hasTooltip ? stringMaxLengthFilter(text, this.length) : text
    },

    showTooltip () {
      return this.hasTooltip && (this.text?.length > this.length) && !this.link
    }
  }
}
</script>
