<template>
  <div class="input__wrap" :class="disabled">
    <p v-if="label" class="input__title">
      {{ label }}{{ required ? '*' : '' }}
    </p>

    <div class="input__inner">
      <label class="input__inner__item">
        <input
          class="input"
          :class="validError ? 'input_error' : ''"
          type="text"
          :id="`flatpickr-${this._uid}`"
          :disabled="disabled"
          :required="required"
          :placeholder="placeholder"
          :value="value"
          :name="name"
          @focus="focused"
          :tabindex="1"
          @change="onChange"
          ref="dateRef"
        >

        <svgicon
          icon="input-calendar"
          class="input__icon"
          width="19"
          height="21"
        />
      </label>
    </div>

    <transition name="fade">
      <span v-if="validError" class="input-valid">
        <svgicon
          icon="notification-error"
          class="input-valid__icon svg-icon--notification-error"
          width="16"
          height="16"
        />

        {{ validError }}
      </span>
    </transition>
  </div>
</template>

<script>
import flatpickr from 'flatpickr'
import { Russian } from 'flatpickr/dist/l10n/ru.js'

export default {
  name: 'VInputDate',
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'дд.мм.гггг'
    },
    value: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    placeholderEnd: {
      type: String,
      default: 'дд.мм.гггг'
    },
    valueEnd: {
      type: String,
      default: ''
    },
    nameEnd: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    validError: {
      type: String,
      default: ''
    },
    width: {
      type: String,
      default: '400px'
    },
    type: {
      type: String,
      default: 'single'
    },
    timer: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onChange (event) {
      const data = { inputName: event.currentTarget.name, value: event.currentTarget.value }
      this.$emit('value-changed', data)
    },
    focused () {
      this.$emit('got-focus')
    }
  },
  watch: {
    value (newVal) {
      if (!newVal) {
        console.log('newVal', newVal)
        this.$refs.dateRef._flatpickr.clear()
      }
    }
  },
  mounted () {
    const inputMode = (this.$props.type !== undefined) ? this.$props.type : 'single'
    const dateFormat = this.$props.timer ? 'Y-m-d H:i' : 'Y-m-d'
    const altDateFormat = this.$props.timer ? 'd.m.Y H:i' : 'd.m.Y'
    const enableTime = this.$props.timer

    // на этом сепараторе завязано разбиение на одиночные даты в фильтре
    // если придётся использовать одно поле ввода для указания периода
    Russian.rangeSeparator = ' - '

    flatpickr(`#flatpickr-${this._uid}`, {
      locale: Russian,
      mode: 'range',
      dateFormat,
      altInput: true,
      altFormat: altDateFormat,
      altInputClass: 'alt-input-date-global',
      time_24hr: enableTime,
      enableTime
    })

    if (inputMode === 'range') {
      flatpickr(`#flatpickr-${this._uid}_second`, {
        locale: Russian,
        dateFormat,
        altInput: true,
        altFormat: altDateFormat,
        altInputClass: 'alt-input-date-global',
        time_24hr: enableTime,
        enableTime
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.input {
  position: relative;
  z-index: 10;
  width: 100%;
  height: 40px;
  padding: 10px 40px 10px 15px;
  color: $color__input--font;
  border: 1px solid $color__input--border;
  border-radius: 12px;
  transition: $transition;
  font: inherit;

  &__inner {
    display: flex;
    flex-flow: row nowrap;

    &__item {
      position: relative;
      flex: 1;
      margin-right: 0;
    }
  }

  &__icon {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    color: $color__main;
  }

  &__title {
    display: block;
    margin-bottom: 10px;
    color: $color__font--dark !important;
  }

  &_error {
    border: 1px solid $color__red;
  }

  &[disabled] {
    background: $color__background--main;
  }

  &::placeholder {
    color: $color__input--font;
  }

  @media screen and (min-width: $hover-enable) {
    &:hover {
      border: 1px solid $color__main;
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px $color__blue--light;
  }
}

.input__wrap {
  position: relative;
  display: block;
  width: 100%;

  &.disabled {
    pointer-events: none;
  }

  .input__title {
    color: $color__third;
    font-size: 16px;
    line-height: 30px;
  }
}

</style>
