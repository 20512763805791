import { instance } from '@/api'
import { createBaseApi } from '@/api/baseApi'

const ORDER_URL = 'order/orders'

export default {
  ...createBaseApi(ORDER_URL),
  updateMassStatus: (id, direction = 'next') => {
    return instance.put(`/${ORDER_URL}/${id}/mass-status/${direction}`, null)
  },
  updateOrderStatus: (id, status) => {
    const params = { status_id: status }
    return instance.put(`/${ORDER_URL}/${id}/change-status/`, params)
  },
  restoreOrder: (id, externalId) => {
    const params = { external_id: externalId }
    return instance.put(`/${ORDER_URL}/${id}/restore/`, params)
  },
  updateDeliveryPermission: id => {
    return instance.put(`/${ORDER_URL}/${id}/delivery-permission`, null)
  },
  updateMarkers: (id, data) => {
    return instance.put(`/${ORDER_URL}/${id}/set-markers`, data)
  }
}
