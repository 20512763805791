import { deleteFiles as deleteFilesAll, postFiles } from '@/api/request'
import { throwResponseError } from '@/utils/error/throwResponseError'

export function createFileApi (url) {
  return {
    postFiles: (id, data) => postFiles(url, id, data),
    deleteFiles: (idList) => deleteFilesAll(idList)
  }
}

export function createBaseFileApi (url) {
  return {
    postFiles: async (id, files) => {
      try {
        return await postFiles(url, id, files)
      } catch (e) {
        throwResponseError(e, 'Не удалось сохранить файл')
      }
    },
    deleteFiles
  }
}

export async function deleteFiles (ids) {
  try {
    return await deleteFilesAll(ids)
  } catch (e) {
    throwResponseError(e, 'Не удалось удалить файл')
  }
}
