import { route as tableTimeAndLoadRoute } from './TableTimeAndLoad'
import {
  route as productionCalendarRoute
} from './ProductionCalendar'
import { route as changelogRoute } from './Changelog'

const meta = {
  layout: 'main',
  availableName: 'isLoadCalendar',
  auth: true
}

tableTimeAndLoadRoute.meta = meta
productionCalendarRoute.meta = meta
changelogRoute.meta = meta

export const routeName = 'LoadCalendarPage'

export const routes = [
  {
    path: '/load-calendar',
    name: routeName,
    meta,
    redirect: { name: tableTimeAndLoadRoute.name, params: { id: 1 } }
  },
  productionCalendarRoute,
  tableTimeAndLoadRoute,
  changelogRoute
]
