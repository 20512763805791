<template>
  <div class="info">
    <p class="info__label">{{ label }}:</p>

    <slot>
      <p>{{ currentText }}</p>
    </slot>
  </div>
</template>

<script>
import { minus } from '@/utils/string'

export default {
  name: 'InfoText',
  props: {
    label: {
      type: String,
      default: ''
    },
    text: {
      type: [String, Number, Array, Boolean, Object],
      default: '-'
    },
    join: {
      type: String,
      default: '/'
    },
    isHandbook: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentText () {
      if (this.isHandbook) {
        return minus(this.text?.name)
      }

      if (Array.isArray(this.text)) {
        return this.text
          .map(minus)
          .join(this.join)
      }

      return minus(this.text)
    }
  }
}
</script>
