<template>
  <div class="custom-table custom-table__wrapper">
    <table class="custom-table__table">
      <ReportTableHead :head="head" />

      <tbody class="custom-table__body">
        <div
          v-for="(row, idx) of rows"
          :key="idx"
          class="custom-table__row"
        >
          <tr
            v-for="(childRow, i) of row.rows"
            :key="i"
            :class="i === 0 && 'relative'"
          >
            <td
              v-if="i === 0"
              :class="{
                    'table-border-bottom-bold': idx !== rows.length - 1
                  }"
              :rowspan="row.rows.length"
            >
              <div class="center">
                <Resolution
                  v-if="fullRowColumns[0].name === 'decision'"
                  :resolution-id="row[fullRowColumns[0].name]"
                />

                <span v-else>{{ row[fullRowColumns[0].name] }}</span>
              </div>
            </td>

            <td
              v-if="i === 0"
              :class="{
                    'table-border-bottom-bold': idx !== rows.length - 1
                  }"
              :rowspan="row.rows.length"
            >
              <div class="center">
                {{ row[fullRowColumns[1].name] }}
              </div>
            </td>

            <td
              v-for="(col) of columns"
              :key="col.name"
              class='custom-table__td'
              :class="{
                    'table-border-bottom-bold': i === row.rows.length - 1 && idx !== rows.length - 1
                  }"
            >
              <Resolution
                v-if="col.name === 'decision'"
                :resolution-id="childRow[col.name]"
              />

              <router-link
                v-else-if="col.name === 'reclamation_id'"
                class="link-reset v-link hover--main"
                :to="{ name: $store.state.pages.reclamationPages.details, params: { id: childRow[col.name] } }"
              >
                рекламация №{{ childRow.account_number }}
              </router-link>

              <span v-else>{{ childRow[col.name] }}</span>
            </td>
          </tr>
        </div>
      </tbody>
    </table>
  </div>
</template>

<script>
import ReportTableHead from './ReportTableHead.vue'
import Resolution from '../Resolution/Resolution.vue'
import { REPORTS } from '../../model/const/reports'

export default {
  components: {
    ReportTableHead,
    Resolution
  },
  props: {
    reportId: {
      type: Number,
      required: true,
      validator (value) {
        switch (value) {
          case REPORTS[0].id:
          case REPORTS[1].id:
          case REPORTS[2].id: return true
        }
        return false
      }
    },
    rows: {
      type: Array,
      required: true
    }
  },

  computed: {
    head () {
      return this.currentReport.table.columns
    },

    fullRowColumns () {
      return this.currentReport.table.columns.slice(0, 2)
    },

    columns () {
      return this.currentReport.table.columns.slice(2)
    },

    currentReport () {
      return REPORTS[this.reportId - 1]
    }
  }
}
</script>

<style lang="scss">
@import "@/shared/ui/CustomTable/styles";
</style>
