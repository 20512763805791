import TemplatePositionController from '@/controllers/production/Template/TemplatePositionController'
import { vuexProductionTemplate } from '@/store/composition/productionTemplate'
import { vuexBaseFile } from '@/store/composition/modules/file'

const state = () => ({
  ...vuexProductionTemplate.createState(),
  ...vuexBaseFile.createState(),
  controller: TemplatePositionController
})
const getters = {
  ...vuexProductionTemplate.createGetters()
}

const mutations = {
  ...vuexProductionTemplate.createMutations(),
  ...vuexBaseFile.createMutations()
}

const actions = {
  ...vuexProductionTemplate.createActions(),
  ...vuexBaseFile.createActions()
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
}
