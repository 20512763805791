import productionMeta from '@/router/production/productionMeta'
import productionFirstTab from '@/router/production/productionFirstTab'

export default [
  {
    path: 'order-list',
    name: 'OrderList',
    redirect: productionFirstTab,
    meta: productionMeta,
    component: () => import('@/views/Production/Order/OrderList/OrderList.vue'),
    children: [
      {
        path: 'new',
        name: 'OrderListItem',
        meta: productionMeta,
        component: () => import('@/views/Production/Order/OrderList/OrderListItem.vue')
      },
      {
        path: 'launch',
        name: 'LaunchOrderList',
        meta: productionMeta,
        component: () => import('@/views/Production/Order/OrderList/OrderListItem.vue')
      },
      {
        path: 'progress',
        name: 'ProgressOrderList',
        meta: productionMeta,
        component: () => import('@/views/Production/Order/OrderList/OrderListItem.vue')
      },
      {
        path: 'ready',
        name: 'ReadyOrderList',
        meta: productionMeta,
        component: () => import('@/views/Production/Order/OrderList/OrderListItem.vue')
      },
      {
        path: 'shipping',
        name: 'ShippingOrderList',
        meta: productionMeta,
        component: () => import('@/views/Production/Order/OrderList/OrderListItem.vue')
      },
      {
        path: 'archive',
        name: 'ArchiveOrderList',
        meta: productionMeta,
        component: () => import('@/views/Production/Order/OrderList/OrderListItem.vue')
      }
    ]
  }
]
