import Vue from 'vue'
import { Waybill } from './classes/Waybill'

export default {
  namespaced: true,

  state: () => ({
    waybill: null
  }),

  mutations: {
    SET_WAYBILL (state, data) {
      state.waybill = new Waybill(data)
    },

    UPDATE_WAYBILL (state, data) {
      for (const key in data) {
        Vue.set(state.waybill, key, data[key])
      }
    },

    RESET_STATE (state) {
      state.waybill = null
    }
  }
}
