import registerPage from '@/store/modules/production/registerPage'
import order from '@/store/modules/production/order'
import consignment from '@/store/modules/production/consignment'
import orderDetail from '@/store/modules/production/order-detail'
import tabsOrderList from '@/store/modules/production/tabsOrderList'

export default {
  registerPage,
  tabsOrderList,
  order,
  consignment,
  orderDetail
}
