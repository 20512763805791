/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'input-calendar': {
    width: 19,
    height: 21,
    viewBox: '0 0 19 21',
    data: '<rect pid="0" x=".75" y="3.75" width="17.5" height="16.5" rx="1.25" _stroke="currentColor" stroke-width="1.5"/><rect pid="1" x="4.275" y=".475" width=".95" height="5.05" rx=".475" _stroke="currentColor" stroke-width=".95"/><rect pid="2" x="13.775" y=".475" width=".95" height="5.05" rx=".475" _stroke="currentColor" stroke-width=".95"/><path pid="3" d="M.95 10h17.1M6.65 10v11M12.35 10v11M.95 15h17.1" _stroke="currentColor" stroke-width="1.5"/>'
  }
})
