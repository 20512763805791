/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/export': {
    width: 18,
    height: 18,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6.25 2.75h-3.5A1.75 1.75 0 001 4.5v8.75A1.75 1.75 0 002.75 15h8.75a1.75 1.75 0 001.75-1.75v-3.5M9.75 1H15m0 0v5.25M15 1L6.25 9.75" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
