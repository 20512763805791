/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/admin-user': {
    width: 21,
    height: 21,
    viewBox: '0 0 21 21',
    data: '<circle pid="0" cx="10.5" cy="10.5" r="10" _stroke="currentColor"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13.719 7.667c0 2.297-1.507 3.666-3.219 3.666s-3.219-1.37-3.219-3.666C7.281 5.369 8.788 4 10.5 4s3.219 1.37 3.219 3.667zM10.5 13.64a4.42 4.42 0 002.136-.56h1.614c2.071 0 3.75 2.005 3.75 4.48v.373h-1v-.373c0-2.096-1.39-3.48-2.75-3.48h-1.37c-.726.356-1.53.56-2.38.56a5.39 5.39 0 01-2.379-.56H6.75C5.39 14.08 4 15.464 4 17.56v.373H3v-.373c0-2.474 1.679-4.48 3.75-4.48h1.614c.654.357 1.374.56 2.136.56zm0-1.307c2.33 0 4.219-1.884 4.219-4.666C14.719 4.884 12.829 3 10.5 3 8.17 3 6.281 4.884 6.281 7.667c0 2.782 1.89 4.666 4.219 4.666z" _fill="currentColor"/>'
  }
})
