export class NavItem {
  label = ''
  routeName = ''
  routeParams = null

  constructor ({ label, routeName, routeParams }) {
    this.label = label
    this.routeName = routeName
    this.routeParams = routeParams ? { ...routeParams } : null
  }
}
