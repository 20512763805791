<template>
  <div class="d-grid d-grid--columns-2 gap-s">
    <div class="column gap-s">
      <InfoText :label="EResolutionLabels.resolution" text="-">
        <Resolution v-if="reclamation.decision" :resolution-id="reclamation.decision" />
      </InfoText>

      <AppInfoTextBlock
        :label-text="EResolutionLabels.comment"
        :text="reclamation.decision_comment"
        class="info--column"
        :has-tooltip="false"
      />

      <AppInfoTextBlock
        :label-text="EResolutionLabels.responsibleInvestigation"
        :text="reclamation.decision_manager && reclamation.decision_manager.name"
      />
    </div>

    <div class="column gap-s">
      <InfoText
        v-if="reclamation.number_defected"
        :label="EResolutionLabels.countOfSpringDefective"
        :text="reclamation.number_defected"
      />

      <InfoText
        v-if="reclamation.number_defected"
        :label="EResolutionLabels.typeLoss"
        :text="metalLoss"
      />

      <InfoText
        :label="EResolutionLabels.countOfSpringAll"
      />

      <InfoText
        :label="EResolutionLabels.weightAll"
      />

      <InfoText
        v-if="reclamation.stopped_at"
        :label="EResolutionLabels.dateReclamationClose"
        :text="dateStopped"
      />
    </div>
  </div>
</template>

<script>
import AppInfoTextBlock from '@/components/ui/AppInfoTextBlock.vue'
import InfoText from '@/components/simple/text/InfoText.vue'
import Resolution from '../Resolution/Resolution.vue'
import { EResolutionLabels } from '../../model/types'
import { ReclamationExternal } from '../../model/classes/ReclamationExternal'

export default {
  components: {
    Resolution,
    InfoText,
    AppInfoTextBlock
  },
  props: {
    reclamation: {
      type: ReclamationExternal,
      required: true
    }
  },

  computed: {
    EResolutionLabels: () => EResolutionLabels,
    dateStopped () {
      return this.reclamation.getStoppedAt()
    },

    metalLoss () {
      return this.reclamation.calcMetalLoss(this.reclamation.number_defected)
    }
  }
}
</script>
