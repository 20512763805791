/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/import': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<path pid="0" d="M6.25.75h-3.5A1.75 1.75 0 001 2.5v8.75A1.75 1.75 0 002.75 13h8.75a1.75 1.75 0 001.75-1.75v-3.5m-.67-6.487L6.25 7.75m0 0V3m0 4.75H11" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
