/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'from-warehouse': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<circle pid="0" cx="15" cy="15" r="15" _fill="currentColor"/><rect pid="1" x="10.82" y="14.721" width="8.36" height="7.58" rx=".5" _stroke="#fff"/><path pid="2" d="M7.7 10.997a.5.5 0 01.314-.464l6.8-2.72a.5.5 0 01.372 0l6.8 2.72a.5.5 0 01.314.464V21.8a.5.5 0 01-.5.5H8.2a.5.5 0 01-.5-.5V10.997zM11.1 17.34h8.58M11.1 19.68h8.58" _stroke="#fff"/>'
  }
})
