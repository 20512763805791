export class Status {
  static counter = 1
  name = ''
  value = ''

  constructor (name, value) {
    this.name = name
    this.value = value
    this.id = Status.counter
    Status.incrementCounter()
  }

  static incrementCounter () {
    Status.counter++
  }
}
