<template>
  <div class="base-input" :class="{ error: hasValidationError }">
    <label
      @click="() => !readonly && $refs.input.focus()"
      v-if="label"
      :class="['input__label', { readonly }]"
      >{{ label }}</label
    >
    <div class="password-input">
      <input
        :autocomplete="autocomplete"
        ref="input"
        v-model="inputValue"
        @blur="handleBlur"
        @focus="$emit('focus')"
        :name="name"
        :placeholder="placeholder"
        :type="typeInput"
        class="input__field"
        :readonly="readonly"
        :disabled="disabled"
        :style="{paddingRight: '45px', width: inputWidth}"
        @keydown="evt => $emit('keydown', evt)"
        @keyup="evt => $emit('keyup', evt)"
        @keypress="evt => $emit('keypress', evt)"
      />
      <svgicon
        @click="show = !show"
        :class="['password-input__icon', { show, error: hasValidationError }]"
        :icon="show ? 'eye' : 'close_eye'"
        :width="show ? '22px' : '20px'"
      />
    </div>

    <slot v-if="hasValidationError && !serverValidationPending" class="validation-messages" />
  </div>
</template>

<script>
import Base from './Base/Base'

export default {
  name: 'vpassword',
  extends: Base,
  data () {
    return {
      show: false
    }
  },
  computed: {
    typeInput () {
      return !this.show ? 'password' : 'text'
    }
  },
  methods: {
    handleBlur () {
      this.$emit('blur')
      this.validate()
    }
  }
}
</script>

<style lang="scss" scoped>
.password-input {
  position: relative;

  .password-input__icon {
    position: absolute;
    right: 16px;
    top: 12px;
    cursor: pointer;
    color: #a9b6c2;
    fill: #a9b6c2;
    transition: fill 0.2s;

    &.show {
      fill: $color__main;
      color: $color__main;
    }

    &.error {
      fill: #f7acbb;
      stroke: #f7acbb;
    }
  }
}
</style>
