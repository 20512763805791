/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'without-wending': {
    width: 19,
    height: 20,
    viewBox: '0 0 19 20',
    data: '<path pid="0" d="M0 6.797a2 2 0 01.928-1.688L7.958.645a2 2 0 012.06-.051l7.97 4.529A2 2 0 0119 6.862V17.95a2 2 0 01-2 2H2a2 2 0 01-2-2V6.797z" _fill="#69F"/><path pid="1" d="M11.4 11.4c0 1.047-.854 1.9-1.9 1.9-1.046 0-1.9-.853-1.9-1.9 0-1.046.854-1.9 1.9-1.9 1.046 0 1.9.854 1.9 1.9zm3.8-.809a.27.27 0 00-.215-.267l-1.358-.208a4.085 4.085 0 00-.305-.727c.253-.349.527-.676.78-1.017a.28.28 0 00.007-.334c-.312-.438-.831-.898-1.225-1.262a.293.293 0 00-.185-.074.253.253 0 00-.178.067l-1.054.794a4.363 4.363 0 00-.668-.275l-.208-1.365c-.015-.126-.133-.223-.267-.223H8.676a.272.272 0 00-.267.208c-.119.445-.163.928-.215 1.38-.23.075-.46.171-.676.283l-1.024-.795a.33.33 0 00-.193-.074c-.252 0-1.254 1.084-1.432 1.329a.288.288 0 00-.067.17c0 .067.03.127.074.178.275.334.542.668.794 1.025a3.927 3.927 0 00-.29.682L4 10.294c-.11.023-.2.156-.2.268v1.647c0 .126.09.245.215.267l1.358.2c.075.253.179.498.305.736-.253.348-.527.675-.78 1.016a.289.289 0 00-.007.341c.312.431.831.891 1.225 1.248a.265.265 0 00.185.081.285.285 0 00.186-.067l1.046-.794c.215.112.438.2.668.275l.208 1.366c.015.126.134.222.267.222h1.648c.133 0 .237-.089.267-.208.119-.445.163-.927.215-1.38.23-.074.46-.17.676-.282l1.024.801c.06.038.126.067.193.067.252 0 1.254-1.09 1.432-1.328a.248.248 0 00.067-.171.293.293 0 00-.074-.185c-.275-.335-.542-.661-.794-1.025.118-.215.207-.438.29-.675l1.372-.208c.119-.022.208-.156.208-.267V10.59z" _fill="#fff"/>'
  }
})
