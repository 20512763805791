const VALIDATION_TEXT = {
  required: 'Поле обязательно',
  num: 'Только целые числа',
  num100: 'Введите положительное число до сотых',
  num1000: 'Введите положительное число до тысячных',
  num10000: 'Введите положительное число до десятитысячных',
  limitNum100: 'Введите +/- число до сотых',
  minValue: 'Количество не может быть меньше ',
  maxValue: 'Количество не может превышать '
}

const PATTERN_LIST = {
  num: /^\d+$/,
  num100: /^[0-9]\d*[.,]?(\d{0,2}?)$/,
  num1000: /^[0-9]\d*[.,]?(\d{0,3}?)$/,
  num10000: /^[0-9]\d*[.,]?(\d{0,4}?)$/,
  limitNum100: /[+-]?[0-9]\d*[.,]?(\d{0,2}?)$/
}

const ARTICLE_ERROR_MESSAGE = {
  hasTemplate: 'Шаблон Позиции с данным артикулом существует',
  hasProduct: 'Товар с данным артикулом существует'
}

const patternKeys = Object.keys(PATTERN_LIST)

export function patternKeyValidator (key) {
  return patternKeys.includes(key)
}

export {
  PATTERN_LIST,
  VALIDATION_TEXT,
  ARTICLE_ERROR_MESSAGE
}
