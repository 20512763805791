/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'util': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M15 11l-5 6 7.5-2-5 6 7.5-2-5 6m1-22h9a1.5 1.5 0 011.5 1.5v0A1.5 1.5 0 0125 6H4.5A1.5 1.5 0 013 4.5v0A1.5 1.5 0 014.5 3h9v-.75c0-.69.56-1.25 1.25-1.25v0c.69 0 1.25.56 1.25 1.25V3zm7.605 25.118l2.262-19A1.002 1.002 0 0024.87 8H5.13c-.6 0-1.067.523-.996 1.118l2.262 19a1 1 0 00.993.882h15.224a1 1 0 00.993-.882z" _stroke="#253759" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
