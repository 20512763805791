import PositionApi from '@/api/production/Position/PositionApi'
import { createBaseController } from '@/controllers/baseController'
import { createBaseFileController } from '@/controllers/baseFileController'
import { createBaseResponseErrors, createFilesResponseErrors } from '@/utils/error/responseErrors'
import { setAlertError } from '@/utils/store/alert'
import { goToPage } from '@/utils/router'

const POSITION_ERRORS = {
  ...createBaseResponseErrors('позицию', 'позиций'),
  ...createFilesResponseErrors()
}

export default {
  ...createBaseController(PositionApi, POSITION_ERRORS),
  ...createBaseFileController(PositionApi, POSITION_ERRORS),
  getById: async (id, params) => {
    try {
      return await PositionApi.getById(id, params)
    } catch (e) {
      if (e.response.status === 403) {
        await setAlertError(e, 'Недостаточно прав на просмотр позиции')
      } else {
        await setAlertError(e, POSITION_ERRORS.getById)
      }
      await goToPage('OrderList')
    }
  }
}
