export const CONSIGNMENT_RECLAMATION_INNER_TYPE_LIST = [
  {
    name: 'Полная переделка',
    id: 1,
    value: 'remake'
  },
  {
    name: 'Частичная переделка',
    id: 2,
    value: 'partial-remake'
  },
  {
    name: 'Доработка',
    id: 3,
    value: 'rework'
  },
  {
    name: 'Отклонение согласовано с клиентом',
    id: 4,
    value: 'deviation_agreed_client'
  },
  {
    name: 'Отгружаем без согласования',
    id: 5,
    value: 'shipment_no_agreement'
  }
]
