/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/scroll-up': {
    width: 18,
    height: 16,
    viewBox: '0 0 18 16',
    data: '<path pid="0" d="M16.5 8L9 1.75 1.5 8M16.5 15L9 8.75 1.5 15" _stroke="currentColor" stroke-width="2"/>'
  }
})
