/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close_eye': {
    width: 22,
    height: 18,
    viewBox: '0 0 22 18',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.487 16.071L3.9 17.485 19.457 1.93 18.043.515l-2.414 2.414A11.759 11.759 0 0011 1.987c-5 0-9.27 3.11-11 7.5a11.887 11.887 0 003.926 5.145l-1.44 1.44zm4.147-4.147l1.511-1.511A3.008 3.008 0 018 9.487c0-1.66 1.34-3 3-3 .323 0 .635.05.926.145l1.511-1.511a5.002 5.002 0 00-6.803 6.803z" _fill="#A5BAE5"/><path pid="1" d="M11 16.987c-1.147 0-2.255-.164-3.303-.47l2.162-2.161a5.002 5.002 0 006.01-6.01l3.184-3.185A11.877 11.877 0 0122 9.487c-1.73 4.39-6 7.5-11 7.5z" _fill="#A5BAE5"/>'
  }
})
