/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/plus': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13 12.85h-.15V18c0 .467-.383.85-.85.85a.853.853 0 01-.85-.85v-5.15H6a.853.853 0 01-.85-.85c0-.467.383-.85.85-.85h5.15V6c0-.467.383-.85.85-.85.467 0 .85.383.85.85v5.15H18c.467 0 .85.383.85.85 0 .467-.383.85-.85.85h-5z" _fill="currentColor" _stroke="currentColor" stroke-width=".3"/>'
  }
})
