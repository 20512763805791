import Vue from 'vue'

// Для единовременной загрузки всех записей модели

const createState = () => ({
  controller: {},
  all: [],
  allQuery: {
    'per-page': 5000,
    page: 1,
    sort: '-id',
    expand: null
  }
})

const createMutations = () => ({
  SET_ALL: (state, payload) => {
    Vue.set(state, 'all', payload)
  },
  ADD_ALL: (state, item) => {
    state.all.unshift(item)
  },
  UPDATE_ALL: (state, data) => {
    const i = state.all.findIndex(item => item.id === data.id)
    if (i === -1) return
    Vue.set(state.all, i, { ...state.all[i], ...data })
  },
  DELETE_ALL: (state, id) => {
    state.all = state.all.filter(item => item.id !== id)
  }
})

const createActions = () => ({
  async loadAll ({ commit, state }) {
    try {
      commit('SET_IS_LOADING')

      const response = await state.controller.getAll(state.allQuery)
      if (response?.status !== 200) return
      commit('SET_ALL', response.data)
    } finally {
      commit('SET_IS_LOADING', false)
    }
  },
  // Для обновления с детальной страницы
  syncAll ({ commit }, { data, isCreate = false }) {
    if (isCreate) {
      return commit('ADD_ALL', data)
    }
    return commit('UPDATE_ALL', data)
  }
})

const vuexAll = {
  createState,
  createMutations,
  createActions
}

export {
  vuexAll
}
