/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/edit': {
    width: 18,
    height: 18,
    viewBox: '0 0 17 17',
    data: '<path pid="0" d="M7.168 2.784H2.762A1.762 1.762 0 001 4.546v9.692A1.762 1.762 0 002.762 16h9.692a1.763 1.763 0 001.762-1.762V9.832M12.97 1.538a1.763 1.763 0 112.492 2.492l-7.565 7.565H5.405V9.103l7.565-7.565z" _stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
