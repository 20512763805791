/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/warehouse-material': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<rect pid="0" x=".857" width="9.474" height="9.474" rx=".5" transform="scale(1.21252 .72787) rotate(-45 10.135 2.89)" _stroke="currentColor"/><path pid="1" d="M4.075 6.795L1.865 8.11c-.477.284-.479.747-.004 1.032L9.122 13.5c.474.284 1.242.284 1.715 0l7.277-4.368c.469-.282.474-.738.011-1.023l-2.133-1.315" _stroke="currentColor"/><path pid="2" d="M4.075 10.78l-2.21 1.315c-.477.284-.479.746-.004 1.031l7.261 4.36c.474.284 1.242.284 1.715 0l7.277-4.369c.469-.281.474-.737.011-1.022l-2.133-1.315" _stroke="currentColor"/>'
  }
})
