export class StageItemModel {
  constructor (index = 1, status = null, user = null) {
    this.sort = index
    this.status = status
    this.user = user
  }
}

export const defaultStageList = [
  new StageItemModel(1, { id: 1, name: 'Навивка' }),
  new StageItemModel(100, { id: 13, flag_id: 3, name: 'Готово' }),
  new StageItemModel(101, { id: 14, flag_id: 4, name: 'Ожидает отгрузку' }),
  new StageItemModel(102, { id: 15, flag_id: 5, name: 'Отгружено' })
]
