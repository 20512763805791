<template>
  <div :id="`reclamation-card--${reclamation.id}`" class="card-long reclamation-card">
    <Indicator class="mb-20" :indicator-id="reclamation.indicator" />

    <div class="d-grid d-grid--columns-2 gap-s">
      <InfoText
        :label="ELabels.orderNumber"
        :text="reclamation.account_number"
      />

      <InfoText
        :label="ELabels.responsibleManager"
        :text="reclamation.responsible"
        is-handbook
      />

      <InfoText
        :label="ELabels.positionNumber"
        :text="reclamation.position_number"
      />

      <!-- <InfoText
        :label="ELabels.reasonPetition"
        :text="reclamation.reclamation_reason_treatment"
        is-handbook
      /> -->

      <AppInfoTextBlock
        :label-text="ELabels.reasonPetition"
        :text="reclamation.reclamation_reason_treatment ? reclamation.reclamation_reason_treatment.name : '-'"
        :length="50"
      />

      <AppInfoTextBlock
        :label-text="ELabels.company"
        :text="reclamation.client_name"
      />

      <InfoText
        :label="ELabels.dateCreated"
        :text="dateCreated"
      />
    </div>

    <router-link
      class="absolute-full"
      :to="{
        name: $store.state.pages.reclamationPages.details,
        params: { id: reclamation.id }
      }"
      draggable="false"
    />
  </div>
</template>

<script>
import AppInfoTextBlock from '@/components/ui/AppInfoTextBlock.vue'
import Indicator from '../Indicator/Indicator.vue'
import InfoText from '@/components/simple/text/InfoText.vue'
import { ELabels } from '../../model/types'
import { ReclamationExternal } from '../../model/classes/ReclamationExternal.js'

export default {
  components: {
    InfoText,
    Indicator,
    AppInfoTextBlock
  },
  props: {
    reclamation: {
      type: ReclamationExternal,
      required: true
    }
  },
  computed: {
    ELabels: () => ELabels,
    dateCreated () {
      return this.reclamation.getCreatedAt()
    }
  }
}
</script>

<style lang="scss">
@import "styles";
</style>
