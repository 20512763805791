/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'status-ready': {
    width: 27,
    height: 26,
    viewBox: '0 0 27 26',
    data: '<rect pid="0" width="10.026" height=".993" rx=".496" transform="matrix(-.98438 -.17603 -.13164 .9913 18 22.035)" _fill="currentColor"/><rect pid="1" width="10.035" height=".992" rx=".496" transform="matrix(.9808 -.19503 .14608 .98927 8.006 23.943)" _fill="currentColor"/><rect pid="2" width="10.035" height=".992" rx=".496" transform="matrix(.9808 -.19503 .14608 .98927 8.006 20.412)" _fill="currentColor"/><rect pid="3" width="10.028" height=".993" rx=".496" transform="matrix(-.9836 -.1804 -.13496 .99085 17.999 18.526)" _fill="currentColor"/><rect pid="4" width="10.035" height=".992" rx=".496" transform="matrix(.9808 -.19503 .14608 .98927 8.006 16.882)" _fill="currentColor"/><mask id="svgicon_status-ready_a" _fill="#fff"><path pid="5" fill-rule="evenodd" clip-rule="evenodd" d="M13.116.115a1 1 0 00-.973.023L.493 6.99A1 1 0 000 7.852V24.85a1 1 0 001 1h4a1 1 0 001-1V12.925a1 1 0 011-1h12.5a1 1 0 011 1v11.927a1 1 0 001 1h4.232a1 1 0 001-1V7.883a1 1 0 00-.534-.885L13.116.115z"/></mask><path pid="6" d="M12.143.138l-.506-.862.506.862zm.973-.023L12.65 1l.466-.885zM1 7.852L12.65 1 11.637-.724-.013 6.128 1 7.852zM1 24.85V7.852h-2V24.85h2zm4 0H1v2h4v-2zm2 0V12.925H5v11.927h2zm0-11.926h12.5v-2H7v2zm12.5 0v11.927h2V12.924h-2zm6.232 11.927H21.5v2h4.232v-2zm0-16.967V24.85h2V7.884h-2zM12.65 1l13.082 6.884.931-1.77L13.582-.77 12.65 1zm15.082 6.884a2 2 0 00-1.069-1.77l-.931 1.77h2zm-2 18.966a2 2 0 002-2h-2v2zm-6.232-2a2 2 0 002 2v-2h-2zm0-11.926h2a2 2 0 00-2-2v2zm-12.5 0v-2a2 2 0 00-2 2h2zM5 26.851a2 2 0 002-2H5v2zm-6-2a2 2 0 002 2v-2h-2zM12.65 1l.932-1.77a2 2 0 00-1.945.046L12.65 1zM-.014 6.128A2 2 0 00-1 7.852h2L-.014 6.128z" _fill="currentColor" mask="url(#svgicon_status-ready_a)"/>'
  }
})
