import { vuexHandbook } from '@/store/composition/handbook'
import { GOSTApi, GOST_URL } from '@/api/handbook/warehouse/GOSTApi'
import { prepareFormDataIdArray } from '@/utils/form/prepareFormDataIdArray'
import { showAlertError } from '@/utils/store/alert'

const state = () => ({
  ...vuexHandbook.createState(),
  api: GOSTApi,
  url: GOST_URL
})

const getters = {
  ...vuexHandbook.createGetters(),

  getList: (state) => {
    return state.list.map(item => {
      const materialNameList = item.materials?.map(item => item.name).join('; ')

      return {
        ...item,
        data: [
          { name: 'Материал', value: materialNameList || '-' }
        ]
      }
    })
  }
}

const mutations = {
  ...vuexHandbook.createMutations(),

  OPEN_MODAL: (state, item) => {
    state.modal.active = true

    if (!item) return
    state.modal.updateId = item.id
    state.modal.formData.name = item.name

    if (item.materials?.length) {
      state.modal.formData.material = item.materials.map(w => ({ ...w }))
    }

    if (item?.surface_treatment) {
      state.modal.formData.surface_treatment = item.surface_treatment
    }
  }
}

const actions = {
  ...vuexHandbook.createActions(),

  async saveItem ({ dispatch, commit, state }, data) {
    try {
      commit('SET_IS_SENDING_REQUEST_MODAL', true, { root: true })

      const preparedData = prepareFormDataIdArray(data, ['material'])

      if (preparedData?.surface_treatment) {
        preparedData.surface_treatment_id = preparedData.surface_treatment.id
        delete preparedData.surface_treatment
      }

      state.modal.updateId ?
        await state.api.update(state.modal.updateId, preparedData)
        : await state.api.create(preparedData)

      commit('CLOSE_MODAL')
      dispatch('loadList')
    } catch (e) {
      showAlertError(e)
    } finally {
      commit('SET_IS_SENDING_REQUEST_MODAL', false, { root: true })
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
