/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'spring': {
    width: 15,
    height: 18,
    viewBox: '0 0 15 18',
    data: '<rect pid="0" width="14.805" height="1.643" rx=".822" transform="matrix(.97562 -.21945 .12944 .99159 .209 15.455)" _fill="#69F"/><rect pid="1" width="14.777" height="1.645" rx=".823" transform="matrix(-.98015 -.19827 -.1166 .99318 14.877 12.287)" _fill="#0E1D3A"/><rect pid="2" width="14.805" height="1.643" rx=".822" transform="matrix(.97562 -.21945 .12944 .99159 .209 9.593)" _fill="#69F"/><rect pid="3" width="14.783" height="1.645" rx=".822" transform="matrix(-.97915 -.20315 -.11954 .99283 14.873 6.463)" _fill="#0E1D3A"/><rect pid="4" width="14.805" height="1.643" rx=".822" transform="matrix(.97562 -.21945 .12944 .99159 .209 3.732)" _fill="#69F"/>'
  }
})
