import { showAlertError } from '@/utils/store/alert'

export async function hideItem ({ dispatch, commit, state }, { id, hidden }) {
  try {
    await state.api.hide(id, hidden)
    dispatch('loadList')
  } catch (e) {
    showAlertError(e)
  }
}
