<template>
  <div class="base-input" :class="{ readonly: readonly, error: hasValidationError }">
    <label
      v-if="label"
      :class="['input__label', { readonly }]"
      @click="() => !readonly && $refs.input.focus()"
    >
      {{ label }}
    </label>

    <div class="d-flex jc-sb">
      <div class="search-input">
        <input
          v-model.lazy="inputValue"
          ref="input"
          class="input__field"
          autocomplete="off"
          :name="name"
          :placeholder="placeholder"
          v-mask="usedMask"
          :style="{paddingLeft: '35px', width: inputWidth}"
          :maxlength="usedMask.mask.length || maxLength || -1"
          :readonly="readonly"
          :disabled="loading"
          @keydown="keydown"
          @keyup="keyup"
          @keypress="keypress"
          @keydown.enter="onEnter"
          @blur="handleBlur"
          @focus="$emit('focus')"
        />

        <svgicon icon="search" width="24px" height="24px" />
      </div>
    </div>

    <slot
      v-if="hasValidationError && !serverValidationPending"
      class="validation-messages"
    />
  </div>
</template>

<script>
import Base from './Base/Base'

export default {
  name: 'VSearch',
  extends: Base,
  methods: {
    handleBlur () {
      this.$emit('blur')
      this.validate()
    },
    onEnter (e) {
      this.$emit('onEnter', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  margin-bottom: 0 !important;
}
.search-input {
  width: 100%;
  position: relative;

  .input__field {
    height: 45px;
    margin-bottom: 0;
  }

  .svg-icon {
    color: $color__third;
    position: absolute;
    transition: all 0.2s;
    background: transparent;
    fill: $color__third;
    left: 8px;
    top: 10px;
    pointer-events: none;
  }

  .input__field:focus + .svg-icon {
    fill: $color__main;
    color: $color__main;
  }
}
</style>
