const createState = () => ({
  isLoading: false
})

const createMutations = () => ({
  SET_IS_LOADING: (state, bool = true) => {
    state.isLoading = bool
  }
})

const vuexBaseLoading = {
  createState,
  createMutations
}

export {
  vuexBaseLoading
}
