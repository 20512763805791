import store from '@/store'
import { applyDrag } from '@/utils/array'
import setQueryParams from '@/utils/store/setQueryParams'
import { refreshArr } from '@/utils/array/reactive'
import { showAlertError } from '@/utils/store/alert'
import { hideItem } from '@/store/composition/modules/model/actions'
import { COATING_COLOR_URL } from '@/api/handbook/position/CoatingColor/CoatingColorApi'
import { MACHINE_URL } from '@/api/handbook/load/Machine/MachineApi'
import { MATERIAL_URL } from '@/api/handbook/warehouse/Material/MaterialApi'
import { CONSIGNMENT_STAGE_URL } from '@/api/handbook/consignment/ConsignmentStage/ConsignmentStageApi'

const createState = () => ({
  api: {},
  url: '',
  list: [],
  isLoading: false,

  modal: {
    active: false,
    updateId: null,
    formData: {}
  },

  // query
  sort: {
    name: 'sort'
  },
  filter: {},
  pagination: {
    perPage: 1000,
    page: 1,
    pageCount: 0
  }
})

const createGetters = () => ({
  getList: state => state.list,
  getModal: state => state.modal,
  getIsLoading: state => state.isLoading
})

const createMutations = () => ({
  SET_LIST: (state, list) => {
    state.list = list
  },

  // Drag and Drop sort
  SET_LIST_SORTING: (state, dropResult) => {
    const result = applyDrag(state.list, dropResult)
    refreshArr(state.list, result)
  },

  OPEN_MODAL: (state, item) => {
    state.modal.active = true

    if (!item) return

    state.modal.updateId = item.id
    state.modal.formData.name = item.name

    if (state.url === MACHINE_URL) {
      state.modal.formData.performance = item.product_per_minute ? item.product_per_minute.toString() : ''
      state.modal.formData.startTime = item.start_time ? item.start_time.toString() : ''
    }

    if (state.url === CONSIGNMENT_STAGE_URL) {
      state.modal.formData.types = []
      if (item.types) {
        item.types.forEach(status => {
          const current = store.state.records.properties.statuses_types.find(item => item.code === status)
          state.modal.formData.types.push(current)
        })
      }
    }

    if (state.url === MATERIAL_URL) {
      state.modal.formData.vendorCode = item.vendor_code ? item.vendor_code : ''
      state.modal.formData.hardness = item.hardness ? item.hardness : ''
      state.modal.formData.density = item.density ? item.density : ''
    }

    if (state.url === COATING_COLOR_URL) {
      state.modal.formData.coating = item.coating
    }
  },

  CLOSE_MODAL: (state) => {
    state.modal.active = false
    state.modal.updateId = null
    state.modal.formData = {}
  },

  SET_LOADING: (state, bool) => {
    state.isLoading = bool
  }
})

const createActions = () => ({
  async loadList ({ state, commit }) {
    try {
      commit('SET_LOADING', true)

      const response = await state.api.getAll(setQueryParams(state))

      commit('SET_LIST', response.data)
    } catch (e) {
      showAlertError(e)
    } finally {
      commit('SET_LOADING', false)
    }
  },

  async postSortedList ({ commit, state }, dropResult) {
    const { removedIndex, addedIndex } = dropResult
    if (removedIndex === null && addedIndex === null) return

    commit('SET_LIST_SORTING', dropResult)

    const idsArray = state.list.map(item => item.id)

    try {
      await state.api.postSorted(idsArray)
    } catch (e) {
      showAlertError(e)
    }
  },

  async saveItem ({ dispatch, commit, state }, data) {
    try {
      commit('SET_IS_SENDING_REQUEST_MODAL', true, { root: true })

      const postData = {
        name: data.name
      }

      if (state.url === MACHINE_URL) {
        postData.product_per_minute = data.performance ? Number(data.performance) : ''
        postData.start_time = data.startTime ? Number(data.startTime) : ''
      }

      if (state.url === MATERIAL_URL) {
        postData.vendor_code = data.vendorCode
        postData.hardness = data.hardness
        postData.density = data.density
        postData.amount = 0
        postData.amount_reserved = 0
      }

      if (state.url === COATING_COLOR_URL) {
        postData.coating_id = data.coating?.id
      }

      if (state.url === CONSIGNMENT_STAGE_URL) {
        if (data.types) {
          postData.types = []
          data.types.forEach(item => postData.types.push(item.code))
        } else postData.types = null
      }

      if (state.url === COATING_COLOR_URL) {
        postData.coating_id = data.coating?.id
      }

      state.modal.updateId ?
        await state.api.update(state.modal.updateId, postData)
        : await state.api.create(postData)

      commit('CLOSE_MODAL')
      dispatch('loadList')
    } catch (e) {
      showAlertError(e)
    } finally {
      commit('SET_IS_SENDING_REQUEST_MODAL', false, { root: true })
    }
  },

  hideItem
})

export const vuexHandbook = {
  createState,
  createGetters,
  createMutations,
  createActions
}
