import position from '@/store/modules/productions/template/position'
import stageLine from '@/store/modules/productions/template/stageLine'

export default {
  namespaced: true,
  modules: {
    position,
    stageLine
  }
}
