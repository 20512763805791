/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'filter': {
    width: 18,
    height: 20,
    viewBox: '0 0 18 20',
    data: '<circle pid="0" cx="15.118" cy="5.882" r="2.353" transform="rotate(90 15.118 5.882)" _stroke="currentColor"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M14.618 8.182a2.361 2.361 0 001 0V20h-1V8.182zm0-4.6V0h1v3.583a2.362 2.362 0 00-1 0z" _fill="currentColor"/><circle pid="2" cx="3.353" cy="14.118" r="2.353" transform="rotate(90 3.353 14.118)" _stroke="currentColor"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M5.206 14.118a1.853 1.853 0 10-3.706 0 1.853 1.853 0 003.706 0zm-1.853-2.853a2.853 2.853 0 110 5.706 2.853 2.853 0 010-5.706z" _fill="currentColor"/><circle pid="4" cx="3.353" cy="14.118" r="2.353" transform="rotate(90 3.353 14.118)" _stroke="currentColor"/><path pid="5" fill-rule="evenodd" clip-rule="evenodd" d="M2.854 16.417a2.363 2.363 0 001 0V20h-1v-3.583zm0-4.6V0h1v11.818a2.357 2.357 0 00-1 0z" _fill="currentColor"/>'
  }
})
