/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'button/viewtype-table': {
    width: 22,
    height: 23,
    viewBox: '0 0 22 23',
    data: '<rect pid="0" x="3.5" y="4" width="15" height="6" rx=".5" _stroke="#898989"/><rect pid="1" x="3.5" y="13" width="15" height="6" rx=".5" _stroke="#898989"/>'
  }
})
