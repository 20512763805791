/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/header/load-calendar': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<rect pid="0" x=".5" y="1.5" width="19" height="17" rx=".5" _stroke="currentColor"/><rect pid="1" x="3.25" y=".25" width=".5" height="2.5" rx=".25" _fill="#fff" _stroke="currentColor" stroke-width=".5"/><rect pid="2" x="7.25" y=".25" width=".5" height="2.5" rx=".25" _fill="#fff" _stroke="currentColor" stroke-width=".5"/><rect pid="3" x="11.25" y=".25" width=".5" height="2.5" rx=".25" _fill="#fff" _stroke="currentColor" stroke-width=".5"/><rect pid="4" x="15.25" y=".25" width=".5" height="2.5" rx=".25" _fill="#fff" _stroke="currentColor" stroke-width=".5"/><path pid="5" d="M1 6h18M1 10h18M5 6v13M10 6v13M15 6v13M1 14h18" _stroke="currentColor"/>'
  }
})
