<template>
  <div class="alert-card" :class="'alert-card--' + alert.type">
    <h3 class="alert-card__title flex gap-xxs">
      <svgicon
        :class="iconClass"
        :icon="iconType"
        :width="iconWidth"
        :height="iconHeight"
      />

      {{ title }}
    </h3>

    <div class="alert-card__body">
      {{ alert.message }}
    </div>
  </div>
</template>

<script>
import { ALERT_TITLE } from '@/const/alert'

export default {
  name: 'AlertCard',
  props: {
    alert: {
      type: Object,
      required: true
    }
  },
  computed: {
    title () {
      return ALERT_TITLE[this.alert.type]
    },
    iconType () {
      return 'notification-' + this.alert.type
    },
    iconClass () {
      return 'svg-icon--notification-' + this.alert.type
    },
    iconWidth () {
      switch (this.alert.type) {
        case 'warning': return '23'
        default: return '20'
      }
    },
    iconHeight () {
      switch (this.alert.type) {
        case 'warning': return '19'
        default: return '20'
      }
    }
  }
}
</script>
