import { getURL } from '@/utils/files'
import { showAlertError } from '@/utils/store/alert'
import CatalogHomeApi from '@/api/catalog/CatalogHomeApi'

function mapHomeResponse (data) {
  return {
    text: data.text,
    file: data.file ? getMapHomeFile(data.file) : null
  }
}

function getMapHomeFile (file) {
  return {
    ...file,
    url: file?.original_url
  }
}

const state = () => ({
  file: null,
  text: ''
})

const getters = {
  imageSrc: state => state.file ? getURL(state.file) : null
}

const mutations = {
  SET_HOME: (state, { text, file }) => {
    state.text = text
    state.file = file
  }
}

const actions = {
  async fetchAndSetHome ({ commit }) {
    try {
      const { data } = await CatalogHomeApi.get()
      commit('SET_HOME', mapHomeResponse(data))
    } catch (e) {
      showAlertError(e)
    }
  },

  async updateHome ({ commit }, formData) {
    try {
      const { data } = await CatalogHomeApi.update(formData)
      commit('SET_HOME', mapHomeResponse(data))
    } catch (e) {
      showAlertError(e)
    }
  },

  async deleteHomeFile (_, id) {
    await CatalogHomeApi.deleteFile(id)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
