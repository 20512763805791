import { instance } from '@/api'
import { createBaseApi } from '@/api/baseApi'

const USER_URL = 'user/users'

export default {
  ...createBaseApi(USER_URL),
  login: data => instance.post('/user/login', data),
  deactivate: id => instance.patch(`/${USER_URL}/${id}/deactivate`, {}),
  saveUserMeta: data => instance.post('/user/user-meta', data)
}
