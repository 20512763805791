import productionMeta from '@/router/production/productionMeta'
import register from '@/router/production/register'
import template from '@/router/production/template'

export default [
  // Заказы
  {
    path: '/production',
    name: 'Production',
    meta: productionMeta,
    redirect: { name: 'Order' },
    component: () => import('@/views/Production/Production.vue'),
    children: [
      ...register,
      ...template,
      {
        path: 'change-log',
        name: 'ChangeLog',
        meta: productionMeta,
        component: () => import('@/views/Production/ChangeLog.vue')
      }
    ]
  }
]
