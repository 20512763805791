import { vuexHandbook } from '@/store/composition/handbook'
import { MACHINE_URL, MachineApi } from '@/api/handbook/load/Machine/MachineApi'

const state = () => ({
  ...vuexHandbook.createState(),
  api: MachineApi,
  url: MACHINE_URL
})

const getters = {
  ...vuexHandbook.createGetters(),

  getList: state => {
    return state.list.map(item => ({
      ...item,
      data: [
        { name: 'Производительность (шт/мин)', value: item.product_per_minute || 'не указана' },
        { name: 'Пуско-наладка (час)', value: item.start_time || 'не указано' }
      ]
    }))
  }
}

const mutations = {
  ...vuexHandbook.createMutations()
}

const actions = {
  ...vuexHandbook.createActions()
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
