import adminMeta from './adminMeta'

import orderChain from './order-chain'
import directory from './directory'
import users from './users'

export default [
  // Админка
  {
    path: '/admin-panel',
    name: 'AdminPanel',
    meta: adminMeta,
    redirect: { name: 'Users' },
    component: () => import('@/views/AdminPanel/AdminPanel.vue'),
    children: [
      ...directory,
      ...users,
      ...orderChain
    ]
  }
]
