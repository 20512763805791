/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'order-indicator': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<circle pid="0" cx="10" cy="10" r="9.5" _fill="#F74B4B"/><rect pid="1" width="10.071" height="1.126" rx=".563" transform="matrix(-.97998 -.1991 -.1161 .99324 15 12.757)" _fill="#fff"/><rect pid="2" width="10.09" height="1.124" rx=".562" transform="matrix(.97542 -.22035 .1289 .99166 5.005 15.065)" _fill="#fff"/><rect pid="3" width="10.09" height="1.124" rx=".562" transform="matrix(.97542 -.22035 .1289 .99166 5.005 10.669)" _fill="#fff"/><rect pid="4" width="10.075" height="1.125" rx=".563" transform="matrix(-.97897 -.204 -.11904 .99289 14.998 8.361)" _fill="#fff"/><rect pid="5" width="10.09" height="1.124" rx=".562" transform="matrix(.97542 -.22035 .1289 .99166 5.005 6.224)" _fill="#fff"/>'
  }
})
