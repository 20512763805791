<template>
  <VSelect
    label="Решение:"
    :name="name"
    :options="options"
    :multiple="isMultiple"
    :disabled="isDisabled"
    :allow-empty="isAllowEmpty"
  >
    <template v-slot:singleLabel="{ option }">
      <Resolution :resolution-id="option.id" />
    </template>

    <template v-slot:tag="{ option, remove }">
      <div class="multiselect__tag">
        <Resolution :resolution-id="option.id" />

        <i
          class="multiselect__tag-icon"
          aria-hidden="true"
          tabindex="1"
          @click="remove(option)"
        />
      </div>
    </template>

    <template v-slot:option="{ option }">
      <Resolution :class="isMultiple && 'pl-m'" :resolution-id="option.id" />
    </template>

    <slot />
  </VSelect>
</template>

<script>
import VSelect from '@/components/Form/VSelect.vue'
import Resolution from '../Resolution/Resolution.vue'
import { RESOLUTIONS } from '../../model/const/resolutions'

export default {
  components: { Resolution, VSelect },
  props: {
    isDisabled: { type: Boolean, default: false },
    isMultiple: { type: Boolean, default: false },
    isAllowEmpty: { type: Boolean, default: false },
    isSliced: { type: Boolean, default: false },
    name: {
      type: String,
      default: 'resolution'
    }
  },
  computed: {
    options () {
      if (this.isSliced) {
        return RESOLUTIONS.slice(1)
      }

      return RESOLUTIONS
    }
  }
}
</script>
