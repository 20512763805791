import { HeaderNavItem } from '@/classes/nav/HeaderNavItem'
import { reclamationPages } from '@/views/Reclamation'

export const HEADER_NAV = [
  {
    label: 'Производство/реестр заказов',
    routeName: 'Production',
    icon: 'production',
    accessKey: 'isProduction'
  },
  {
    label: 'Каталог',
    routeName: 'Catalog',
    icon: 'catalog',
    accessKey: 'isCatalog'
  },
  {
    label: 'Склад',
    routeName: 'Warehouse',
    icon: 'warehouse',
    accessKey: 'isWarehouse'
  },
  {
    label: 'Календарь нагрузки',
    routeName: 'LoadCalendarPage',
    icon: 'load-calendar',
    accessKey: 'isLoadCalendar'
  },
  {
    label: 'Аналитика',
    routeName: 'Analytics',
    icon: 'analytics',
    accessKey: 'isReport'
  },
  {
    label: 'Рекламации',
    routeName: reclamationPages.index,
    icon: 'reclamation',
    accessKey: 'isReclamation'
  },
  {
    label: 'Медиафайлы',
    routeName: 'MediaFiles',
    icon: 'media-files',
    accessKey: 'isMediaFiles'
  }
].map(navItem => new HeaderNavItem(navItem))
