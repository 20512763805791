export const mixinFilterEmit = {
  methods: {
    request (data) {
      this.$emit('request', data)
    },
    reset () {
      this.$emit('reset', {})
    }
  }
}
