/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/header/warehouse': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<rect pid="0" x="4.5" y="9.5" width="11" height="10" rx=".5" _stroke="currentColor"/><path pid="1" d="M.5 4.677a.5.5 0 01.314-.464l9-3.6a.5.5 0 01.372 0l9 3.6a.5.5 0 01.314.464V19a.5.5 0 01-.5.5H1a.5.5 0 01-.5-.5V4.677zM5 13h11M5 16h11" _stroke="currentColor"/>'
  }
})
