import chamfer from '@/store/modules/handbook/chamfer'
import coating from '@/store/modules/handbook/coating'
import coatingColor from '@/store/modules/handbook/coatingColor'
import consignmentStage from '@/store/modules/handbook/consignmentStage'
import country from '@/store/modules/handbook/country'
import delayReason from '@/store/modules/handbook/delayReason'
import flatness from '@/store/modules/handbook/flatness'
import fold from '@/store/modules/handbook/fold'
import hookOrientation from '@/store/modules/handbook/hookOrientation'
import hookType from '@/store/modules/handbook/hookType'
import machine from '@/store/modules/handbook/machine'
import material from '@/store/modules/handbook/material'
import productType from '@/store/modules/handbook/productType'
import reasonTreatment from '@/store/modules/handbook/reasonTreatment'
import reclamationInnerReason from '@/store/modules/handbook/reclamationInnerReason'
import reclamationOuterReason from '@/store/modules/handbook/reclamationOuterReason'
import referenceTurn from '@/store/modules/handbook/referenceTurn'
import surfaceTreatment from '@/store/modules/handbook/surfaceTreatment'
import techBrand from '@/store/modules/handbook/techBrand'
import techType from '@/store/modules/handbook/techType'
import test from '@/store/modules/handbook/test'
import userDepartment from '@/store/modules/handbook/userDepartment'
import userPost from '@/store/modules/handbook/userPost'
import windingDirection from '@/store/modules/handbook/windingDirection'
import windingMethod from '@/store/modules/handbook/windingMethod'
import windingType from '@/store/modules/handbook/windingType'
import workshop from '@/store/modules/handbook/workshop'
import userRole from '@/store/modules/handbook/userRole'
import gost from '@/store/modules/handbook/gost'
import tabTableTimeAndLoad from '@/store/modules/handbook/tabTableTimeAndLoad'

const state = () => ({})

export default {
  namespaced: true,
  state,
  actions: {
    async loadCatalogHandbook ({ dispatch }) {
      const handbooks = [
        'country',
        'productType',
        'techType',
        'techBrand'
      ]

      await Promise.all(handbooks.map(namespaced => dispatch(`${namespaced}/loadList`)))
    }
  },
  modules: {
    chamfer,
    coating,
    coatingColor,
    consignmentStage,
    country,
    delayReason,
    flatness,
    fold,
    gost,
    hookOrientation,
    hookType,
    machine,
    material,
    productType,
    reclamationInnerReason,
    reclamationOuterReason,
    reasonTreatment,
    referenceTurn,
    surfaceTreatment,
    tabTableTimeAndLoad,
    techBrand,
    techType,
    test,
    userDepartment,
    userPost,
    userRole,
    windingDirection,
    windingMethod,
    windingType,
    workshop
  }
}
