/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/warehouse-reserve': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<rect pid="0" width="9.065" height="1.017" rx=".508" transform="matrix(-.97985 -.19975 -.1157 .99328 15 12.908)" _fill="currentColor"/><rect pid="1" width="9.083" height="1.015" rx=".508" transform="matrix(.97526 -.22107 .12846 .99172 6.005 14.992)" _fill="currentColor"/><rect pid="2" width="9.083" height="1.015" rx=".508" transform="matrix(.97526 -.22107 .12846 .99172 6.005 11.023)" _fill="currentColor"/><rect pid="3" width="9.069" height="1.016" rx=".508" transform="matrix(-.97883 -.20466 -.11864 .99294 14.998 8.938)" _fill="currentColor"/><rect pid="4" width="9.083" height="1.015" rx=".508" transform="matrix(.97526 -.22107 .12846 .99172 6.005 7.008)" _fill="currentColor"/><path pid="5" fill-rule="evenodd" clip-rule="evenodd" d="M1 0h5v1H1v5.5H0V0h1zM0 14.5V20h6v-1H1v-4.5H0zm19 0V19h-5v1h6v-5.5h-1zm1-8V0h-6v1h5v5.5h1z" _fill="currentColor"/>'
  }
})
