export default function (state, addSearch = false) {
  const params = {
    expand: state.expand || null,
    sort: (state.sort?.descending ? '-' : '') + (state.sort?.name || 'id'),
    'per-page': state.pagination?.perPage || null,
    page: state.pagination?.page || null,
    all: state.search || null
  }

  if (addSearch) {
    for (const key in state.filter) {
      if (state.filter[key]) {
        params[`search[${key}]`] = state.filter[key]
      }
    }
  } else {
    for (const key in state.filter) {
      if (state.filter[key]) {
        params[key] = state.filter[key]
      }
    }
  }

  return params
}
