import { Indicator } from '../classes/Indicator'
import { EAppColors } from '@/shared/lib/types'

export const INDICATORS = [
  new Indicator('Не обработано', EAppColors.red),
  new Indicator('Ведется расследование', EAppColors.orange),
  new Indicator('Ожидаем пружины', EAppColors.orange),
  new Indicator('Пружины получены', EAppColors.green),
  new Indicator('Пробные испытания', EAppColors.main),
  new Indicator('Переделать', EAppColors.main),
  new Indicator('Доделать', EAppColors.main),
  new Indicator('Отклонить', EAppColors.green),
  new Indicator('Возврат средств', EAppColors.red)
]
