export function createColOrderLinks () {
  return {
    isLink: true,
    routerData: {
      pageName: 'OrderDetails',
      dataKey: 'account_number',
      textLink: 'заказ'
    }
  }
}

export function createColReclamationLinks () {
  return {
    isLink: true,
    routerData: {
      pageName: 'ReclamationDetailsPage',
      dataKey: 'account_number',
      textLink: 'рекламация'
    }
  }
}

export function createColConsignmentLinks () {
  return {
    isLink: true,
    routerData: {
      pageName: 'DetailedConsignment',
      dataKey: 'consignment_text',
      textLink: 'Партия №',
      paramsKey: 'id_cons',
      paramsValueKey: 'consignment_id'
    }
  }
}
