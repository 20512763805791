/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'notification-info': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<circle pid="0" cx="10" cy="10" r="9.5" _stroke="currentColor"/><path pid="1" d="M10.758 7.208a1.301 1.301 0 01-.886-.32 1.056 1.056 0 01-.27-.346 1.024 1.024 0 010-.856c.067-.136.157-.256.27-.36.113-.104.247-.183.4-.238.155-.059.317-.088.486-.088.17 0 .331.03.486.088.154.055.29.134.408.238.118.104.208.224.27.36a.958.958 0 010 .856.936.936 0 01-.27.347c-.118.1-.254.178-.409.237a1.449 1.449 0 01-.485.082zM9.688 15c-.504 0-.902-.131-1.195-.394-.329-.29-.493-.713-.493-1.27 0-.263.041-.566.123-.91l1.071-4.464h2.273l-1.133 4.728a1.38 1.38 0 00-.061.449c0 .19.049.323.146.4.103.077.267.116.493.116.154 0 .303-.03.447-.088.149-.064.285-.15.408-.259.252-.222.442-.516.57-.883H13c-.283.702-.637 1.262-1.063 1.678-.36.34-.765.585-1.217.734A3.267 3.267 0 019.687 15z" _fill="currentColor"/>'
  }
})
