import { instance } from '@/api'
import { createBaseCRUDApi } from '@/api/baseApi'
import { deleteFiles } from '@/api/baseFileApi'
import { CATALOG_URL } from '@/api/catalog/index'
import { createBaseResponseErrors } from '@/utils/error/responseErrors'
import { throwResponseError } from '@/utils/error/throwResponseError'

const PRODUCT_URL = `${CATALOG_URL}/products`

const errors = {
  ...createBaseResponseErrors('продукт', 'продуктов')
}

export default {
  ...createBaseCRUDApi(PRODUCT_URL, errors),
  deleteFiles,

  create: async (data) => {
    try {
      return await instance.postForm(PRODUCT_URL, data)
    } catch (e) {
      throwResponseError(e, errors.create)
    }
  },

  update: async (id, data) => {
    try {
      return await instance.postForm(`${PRODUCT_URL}/${id}`, data)
    } catch (e) {
      throwResponseError(e, errors.update)
    }
  }
}
