import { MATERIAL_URL } from '@/api/handbook/warehouse/Material/MaterialApi'
import { getPageCount } from '@/utils/store/pagination'
import { getAll } from '@/api/request'
import { setAlertError } from '@/utils/store/alert'
import { RESET_SEARCH, SET_SEARCH } from '@/store/composition/modules/query/search/mutations'
import { SET_PAGE_COUNT, SET_PAGE } from '@/store/composition/modules/query/pagination/mutations'

export default {
  namespaced: true,
  state: {
    url: MATERIAL_URL,
    materials: [],
    search: '',
    params: {
      'per-page': 5,
      page: 1
    },
    pagination: {
      page: 1,
      perPage: 5,
      count: 1
    },
    isLoading: false
  },

  getters: {
    materials: state => state.materials,
    pagination: state => state.pagination,
    search: state => state.search,
    isLoading: state => state.isLoading
  },

  mutations: {
    SET_SEARCH,
    RESET_SEARCH,
    SET_PAGE,
    SET_PAGE_COUNT,

    SET_MATERIALS (state, materials) {
      state.materials = materials
    },
    SET_IS_LOADING (state, loading) {
      console.log('set', loading)
      state.isLoading = loading
    }
  },
  actions: {
    async getMaterials ({ state, commit }) {
      try {
        commit('SET_IS_LOADING', true)
        const params = {
          name: state.search || null,
          page: state.pagination.page,
          'per-page': state.pagination.perPage
        }
        const response = await getAll(state.url, params)
        commit('SET_MATERIALS', response.data)
        commit('SET_PAGE_COUNT', getPageCount(response))
      } catch (e) {
        await setAlertError(e, 'Не удалось загрузить список материалов')
      } finally {
        commit('SET_IS_LOADING', false)
      }
    }
  }
}
