/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/production-calendar': {
    width: 20,
    height: 21,
    viewBox: '0 0 20 21',
    data: '<rect pid="0" x=".5" y="1.5" width="19" height="19" rx=".5" _stroke="#fff"/><rect pid="1" x="3.25" y=".25" width=".5" height="2.5" rx=".25" _fill="#fff" _stroke="#fff" stroke-width=".5"/><rect pid="2" x="7.25" y=".25" width=".5" height="2.5" rx=".25" _fill="#fff" _stroke="#fff" stroke-width=".5"/><rect pid="3" x="11.25" y=".25" width=".5" height="2.5" rx=".25" _fill="#fff" _stroke="#fff" stroke-width=".5"/><rect pid="4" x="15.25" y=".25" width=".5" height="2.5" rx=".25" _fill="#fff" _stroke="#fff" stroke-width=".5"/><path pid="5" d="M1 6h18" _stroke="#fff"/><circle pid="6" cx="5" cy="10" r="2" _fill="#fff"/><circle pid="7" cx="15" cy="16" r="2" _fill="#fff"/>'
  }
})
