/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'nav/sidebar/analytics': {
    width: 20,
    height: 18,
    viewBox: '0 0 20 18',
    data: '<path pid="0" d="M15 17v-3a1 1 0 011-1h3M4 13l4-5.143L11.5 11l3.954-6m-3.272.571L15.454 5m0 0L16 7.857" _stroke="currentColor" stroke-linecap="round"/><path pid="1" d="M.5 1A.5.5 0 011 .5h18a.5.5 0 01.5.5v12.086a.5.5 0 01-.146.353l-3.915 3.915a.5.5 0 01-.353.146H1a.5.5 0 01-.5-.5V1z" _stroke="currentColor"/>'
  }
})
