import { createBaseApi, createBaseCRUDApi } from '@/api/baseApi'
import { createFileApi } from '@/api/baseFileApi'
import { createBaseResponseErrors, createFilesResponseErrors } from '@/utils/error/responseErrors'

const TEMPLATE_POSITION_URL = 'position/position-templates'
const TEMPLATE_POSITION_URL_V2 = 'position/position-templates/by-position'

export const errors = {
  ...createBaseResponseErrors('шаблон позиции', 'шаблонов позиций'),
  ...createFilesResponseErrors()
}

export const TemplatePositionApi = {
  ...createBaseApi(TEMPLATE_POSITION_URL),
  ...createFileApi(TEMPLATE_POSITION_URL)
}

export const TemplatePositionApiV2 = {
  ...createBaseCRUDApi(TEMPLATE_POSITION_URL_V2, errors)
}
