import { createBaseResponseErrors } from '@/utils/error/responseErrors'
import { createBaseCRUDApi } from '@/api/baseApi'
import { throwResponseError } from '@/utils/error/throwResponseError'
import { instance } from '@/api'
import { getAll } from '@/api/request'

const url = 'reclamation/outer'
const RECLAMATION_URL = `${url}/reclamations`

const errors = {
  ...createBaseResponseErrors('рекламацию', 'рекламаций'),

  createOrder: 'Не удалось создать заказ',
  getAmountByStatuses: 'Не удалось получить количество рекламаций по статусам'
}

export const ReclamationApi = {
  ...createBaseCRUDApi(RECLAMATION_URL, errors),

  createOrder: async (id, data) => {
    try {
      return await instance.post(`/${RECLAMATION_URL}/${id}/order`, data)
    } catch (e) {
      throwResponseError(e, errors.createOrder)
    }
  },

  getAmountByStatuses: async (params) => {
    try {
      return await getAll(`${url}/amount`, params)
    } catch (e) {
      throwResponseError(e, errors.getAmountByStatuses)
    }
  }
}
